import * as React from 'react';
import { useActor } from '@xstate/react';
import {
  AspectRatio,
  Box,
  FormLabel,
  HStack,
  IconButton,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Text,
  VStack,
} from 'components/design/next';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { VideoContentActorRef } from './video-content-machine';
import { match } from 'ts-pattern';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { ContentFieldRecentlyMovedIndicator } from '../common/content-field-recently-moved-indicator';
import { parseEmbedServiceValue } from '@process-street/subgrade/util/embed-service-code-parser';
import { VideoWidget, VideoWidgetService } from '@process-street/subgrade/process';
import { EmbedInfo } from 'app/pages/pages/_id/edit/page/components/video-element';
import { UploadVideoElement } from 'app/pages/pages/_id/edit/page/components/upload';
import { useDebouncedCallback } from 'use-debounce';

export type VideoContentProps = {
  isFirst: boolean;
  isLast: boolean;
  actor: VideoContentActorRef;
};

const DEBOUNCE_MS = 500;

export const VideoContent: React.FC<React.PropsWithChildren<VideoContentProps>> = ({ actor, isFirst, isLast }) => {
  const [current, send] = useActor(actor);
  const [videoUrl, setVideoUrl] = React.useState<string>('');
  const ref = React.useRef<HTMLDivElement | null>(null);
  const { widget, recentlyMovedFrom, isVideoUploading, errorMessage, isReadOnly } = current.context;

  const handleChangeText = (event: { target: { value: string } }) => {
    const { value } = event.target;
    setVideoUrl(value);
    debouncedChangeText();
  };

  const debouncedChangeText = useDebouncedCallback(() => {
    const parsed = parseEmbedServiceValue(videoUrl);
    if (parsed) {
      send({ type: 'CHANGE', widget: { ...widget, service: parsed.service, serviceCode: parsed.serviceCode } });
    } else {
      send({
        type: 'INVALID_URL',
        errorMessage: 'Invalid URL. Please enter a valid YouTube, Vimeo, Wistia, or Loom URL.',
      });
    }
  }, DEBOUNCE_MS);

  const hasContent = widget?.service && widget?.serviceCode;

  const content = (
    <UploadVideoElement
      widget={widget}
      onFinish={(widget: VideoWidget) => {
        send({ type: 'CHANGE', widget });
        send({ type: 'VIDEO_UPLOAD_COMPLETE' });
      }}
      onUploadStarted={() => send({ type: 'VIDEO_UPLOADING' })}
      onDrop={() => send({ type: 'FOCUS' })}
      uploadingMessage="Uploading video..."
      acceptMimeTypes="video/*"
      dropZoneProps={{
        w: 'full',
      }}
      isReadOnly={isReadOnly}
    >
      <Box
        w="full"
        minH={{ base: '143px', md: '347px' }}
        position="relative"
        display="flex"
        justifyContent="center"
        onClick={() => send('FOCUS')}
      >
        <Icon alignSelf="center" color="white" variant="fas" icon="play" size="20%" />
        {!hasContent && !isReadOnly && (
          <IconButton colorScheme="brand" aria-label="Add video" position="absolute" top="-2" right="-2">
            <Icon icon="plus" color="white" size="5" />
          </IconButton>
        )}
      </Box>
    </UploadVideoElement>
  );

  // Don't show empty video widget in view mode
  if (isReadOnly && !hasContent) return null;

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer>
        <VStack
          position="relative"
          w="full"
          alignItems="flex-start"
          ref={node => {
            ref.current = node;
            if (node && !current.context.inputNode) {
              send({ type: 'SET_NODE', node });
            }
          }}
        >
          {recentlyMovedFrom && <ContentFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
          <Box w="full">
            {widget?.service && widget?.serviceCode ? (
              <AspectRatio ratio={16 / 9} w="full">
                <Embed embed={{ service: widget.service, serviceCode: widget.serviceCode }} />
              </AspectRatio>
            ) : (
              <VStack>
                {content}
                {!isVideoUploading && !isReadOnly && (
                  <>
                    <HStack px={4} mt={4} w="full">
                      <Text as="span">Or</Text>

                      <InputGroup ml={2}>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<Icon size="4" icon="video" variant="far" color="gray.600" />}
                        />
                        <Input
                          variant="outline"
                          placeholder="Paste YouTube / Vimeo / Wistia / Loom URL or embed here"
                          px={3}
                          py={4}
                          bg="white"
                          value={videoUrl}
                          onFocus={() => send({ type: 'FOCUS' })}
                          onChange={handleChangeText}
                        />
                      </InputGroup>
                    </HStack>
                    {current.matches('input.error') && errorMessage && (
                      <Text px={4} mt={2} color="red.500" fontSize="sm">
                        {errorMessage}
                      </Text>
                    )}
                  </>
                )}
              </VStack>
            )}
          </Box>
          {!current.matches('description.idle') && hasContent && !isReadOnly && (
            <Input
              autoFocus
              value={current.context.widget?.description ?? ''}
              px="0"
              h="6"
              border="none"
              placeholder="Type label here"
              fontStyle="italic"
              color="gray.600"
              fontWeight="400"
              _focus={{ boxShadow: 'none' }}
              onBlur={() => send({ type: 'DESCRIPTION_BLUR' })}
              onChange={e => send({ type: 'DESCRIPTION_CHANGE', value: e.target.value })}
              onKeyDown={e => send({ type: 'DESCRIPTION_KEY_DOWN', event: e })}
            />
          )}

          {isReadOnly && (
            <FormLabel
              m="0"
              color={current.context.widget?.description ? 'gray.600' : 'gray.400'}
              fontWeight="400"
              fontStyle="italic"
            >
              {current.context.widget?.description || 'Add description (optional)'}
            </FormLabel>
          )}

          {current.matches('description.idle') && hasContent && !isReadOnly && (
            <HStack>
              <FormLabel
                m="0"
                color={current.context.widget?.description ? 'gray.600' : 'gray.400'}
                fontWeight="400"
                fontStyle="italic"
              >
                {current.context.widget?.description || 'Add description (optional)'}
              </FormLabel>

              {current.matches('mutation.updatingWidget') ? (
                <Spinner />
              ) : (
                <>
                  <IconButton
                    minW="unset"
                    h="6"
                    opacity="0"
                    _groupHover={{ opacity: '1' }}
                    variant="ghost"
                    aria-label="Edit"
                    onClick={() => send({ type: 'TOGGLE_DESCRIPTION_EDIT' })}
                  >
                    <Icon icon="edit" size="4" color="gray.500" />
                  </IconButton>
                </>
              )}
            </HStack>
          )}

          {!isReadOnly && (
            <>
              <WidgetListItemDragIcon />
              <FormsWidgetMenu>
                <FormsWidgetMenuItems.Duplicate />
                {widget && <FormsWidgetMenuItems.MoveToStep widget={widget} />}
                <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
                <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
                <FormsWidgetMenuItems.Delete />
              </FormsWidgetMenu>
            </>
          )}
        </VStack>
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};

const Embed: React.FC<React.PropsWithChildren<{ embed: EmbedInfo }>> = ({ embed }) =>
  match(embed)
    .with({ service: VideoWidgetService.YouTube }, ({ serviceCode }) => (
      <Iframe src={`https://www.youtube.com/embed/${serviceCode}?modestbranding=1&showinfo=0`} />
    ))
    .with({ service: VideoWidgetService.Vimeo }, ({ serviceCode }) => (
      <Iframe src={`https://player.vimeo.com/video/${serviceCode}`} />
    ))
    .with({ service: VideoWidgetService.Loom }, ({ serviceCode }) => (
      <Iframe src={`https://www.loom.com/embed/${serviceCode}`} />
    ))
    .with({ service: VideoWidgetService.Wistia }, ({ serviceCode }) => <WistiaEmbed serviceCode={serviceCode} />)
    .otherwise(({ service }) => <Box>Unknown service: {service}</Box>);

const Iframe: React.FC<React.PropsWithChildren<{ src: string }>> = ({ src }) => (
  <iframe
    title="video embed"
    width="100%"
    height="100%"
    src={src}
    frameBorder="0"
    allowFullScreen={true}
    sandbox="allow-scripts allow-forms allow-popups allow-same-origin allow-presentation"
  />
);

const WistiaEmbed: React.FC<React.PropsWithChildren<{ serviceCode: string }>> = ({ serviceCode }) => (
  <Box title="video embed" className={`wistia_embed wistia_async_${serviceCode}`} />
);
