import React from 'react';
import { Icon, IconButton } from 'components/design/next';
import { useUIActorRef } from 'pages/forms/_id/shared';

export const TaskListDrawerButton = () => {
  const { uiActorRef } = useUIActorRef();
  return uiActorRef.getSnapshot()?.context.isMobile ? (
    <IconButton
      variant="tertiary"
      borderRightRadius={0}
      icon={<Icon icon="table-list" variant="far" size="4" />}
      aria-label="open task list menu"
      position="fixed"
      mt={1}
      left={-1}
      onClick={() => uiActorRef.send({ type: 'OPEN_DISCLOSURE', name: 'taskList' })}
      zIndex={1}
    />
  ) : null;
};
