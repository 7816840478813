import {
  faAlignLeft,
  faAngleDown,
  faArchive,
  faArrowDown,
  faArrowDownToLine,
  faArrowFromBottom,
  faArrowFromLeft,
  faArrowFromRight,
  faArrowFromTop,
  faArrowLeft,
  faArrowRight,
  faArrowsRotate,
  faArrowsUpDown,
  faArrowToBottom,
  faArrowUp,
  faArrowUpRightFromSquare,
  faArrowUpToLine,
  faBallot,
  faBars,
  faBell,
  faBlog,
  faBold,
  faBolt,
  faBoltLightning,
  faBookOpen,
  faBooks,
  faBracketsCurly,
  faBriefcase,
  faBrowser,
  faBuilding,
  faCalculator,
  faCalendar,
  faCalendarDays,
  faCamera,
  faCaretDown,
  faCaretUp,
  faChartBar,
  faChartLine,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDoubleDown,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDoubleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboard,
  faClipboardCheck,
  faClipboardListCheck,
  faClock,
  faClone,
  faCloudArrowUp,
  faCode,
  faCodeMerge,
  faCog,
  faColumns,
  faComment,
  faCommentAlt,
  faCompress,
  faCompressAlt,
  faCopy,
  faDatabase,
  faDivide,
  faDownLeftAndUpRightToCenter,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExchange,
  faExclamationCircle,
  faExpand,
  faExpandAlt,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFaceSmile,
  faFastForward,
  faFile,
  faFileAlt,
  faFileDashedLine,
  faFilePlus,
  faFileUpload,
  faFilter,
  faFolder,
  faFolderOpen,
  faFolderPlus,
  faFolderTree,
  faFont,
  faForward,
  faGlobe,
  faGripVertical,
  faHandPaper,
  faHashtag,
  faHeading,
  faHighlighter,
  faHistory,
  faHome,
  faIdBadge,
  faImage,
  faImageLandscape,
  faInbox,
  faInfoCircle,
  faItalic,
  faLanguage,
  faLayerGroup,
  faLevelDown,
  faLightbulb,
  faLink,
  faList,
  faListOl,
  faListUl,
  faLock,
  faLockKeyhole,
  faMagnet,
  faMinus,
  faPalette,
  faPaperclip,
  faPaperPlane,
  faParagraph,
  faPencil,
  faPlay,
  faPlug,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faPrint,
  faRectangle,
  faRectangleWide,
  faReply,
  faRobot,
  faRotateLeft,
  faRotateRight,
  faSearch,
  faShapes,
  faShare,
  faShareAlt,
  faShuffle,
  faSignOut,
  faSlash,
  faSlidersV,
  faSmile,
  faSort,
  faSortDown,
  faSortUp,
  faSpinnerThird,
  faSquare,
  faSquareChevronDown,
  faStar,
  faStrikethrough,
  faSyncAlt,
  faTable,
  faTag,
  faTally,
  faTasks,
  faText,
  faThList,
  faThumbsUp,
  faThumbTack,
  faTimes,
  faTimesCircle,
  faTintSlash,
  faTools,
  faTrash,
  faTrashAlt,
  faTriangleExclamation,
  faUnderline,
  faUndo,
  faUnlockAlt,
  faUpload,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUserLock,
  faUserMinus,
  faUserPen,
  faUserPlus,
  faUsers,
  faUserSlash,
  faUserTimes,
  faUserXmark,
  faVideo,
  faWandMagicSparkles,
  faWarning,
  faXmark,
  faSnooze,
  faTextWidth,
  faSparkle,
  faSparkles,
  faAlignRight,
  faAlignCenter,
  faAlignJustify,
  faHorizontalRule,
  faPaintbrush,
} from '@fortawesome/pro-regular-svg-icons';

export const farLibrary = {
  faAlignLeft,
  faAngleDown,
  faArchive,
  faArrowDown,
  faArrowDownToLine,
  faArrowFromBottom,
  faArrowFromLeft,
  faArrowFromRight,
  faArrowFromTop,
  faArrowLeft,
  faArrowRight,
  faArrowToBottom,
  faArrowUp,
  faArrowUpRightFromSquare,
  faArrowUpToLine,
  faArrowsRotate,
  faArrowsUpDown,
  faBallot,
  faBars,
  faBell,
  faBlog,
  faBold,
  faBolt,
  faBoltLightning,
  faBookOpen,
  faBooks,
  faBracketsCurly,
  faBriefcase,
  faBrowser,
  faBuilding,
  faCalculator,
  faCalendar,
  faCalendarDays,
  faCamera,
  faCodeMerge,
  faChartBar,
  faChartLine,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDoubleDown,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDoubleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboard,
  faClipboardCheck,
  faClipboardListCheck,
  faClock,
  faClone,
  faCloudArrowUp,
  faCode,
  faCog,
  faColumns,
  faComment,
  faCommentAlt,
  faCompress,
  faCompressAlt,
  faCopy,
  faDatabase,
  faDivide,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExchange,
  faExclamationCircle,
  faExpand,
  faExpandAlt,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFaceSmile,
  faFastForward,
  faFile,
  faFileAlt,
  faFileDashedLine,
  faFilePlus,
  faFileUpload,
  faFilter,
  faFolder,
  faFolderOpen,
  faFolderPlus,
  faFolderTree,
  faFont,
  faForward,
  faGlobe,
  faGripVertical,
  faHandPaper,
  faHashtag,
  faHeading,
  faHighlighter,
  faHistory,
  faHome,
  faIdBadge,
  faImage,
  faImageLandscape,
  faInbox,
  faInfoCircle,
  faItalic,
  faLanguage,
  faLayerGroup,
  faLevelDown,
  faLightbulb,
  faLink,
  faList,
  faListOl,
  faListUl,
  faLock,
  faLockKeyhole,
  faMagnet,
  faMinus,
  faPalette,
  faPaperPlane,
  faPaperclip,
  faParagraph,
  faPencil,
  faPlay,
  faPlug,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faPrint,
  faRectangle,
  faRectangleWide,
  faReply,
  faRobot,
  faRotateLeft,
  faRotateRight,
  faSearch,
  faShapes,
  faShare,
  faShareAlt,
  faShuffle,
  faSignOut,
  faSlash,
  faSlidersV,
  faSmile,
  faSort,
  faSortDown,
  faSortUp,
  faSpinnerThird,
  faSquare,
  faSquareChevronDown,
  faStar,
  faStrikethrough,
  faSyncAlt,
  faTable,
  faTag,
  faTally,
  faTasks,
  faText,
  faTextWidth,
  faThList,
  faThumbTack,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTintSlash,
  faTools,
  faTrash,
  faTrashAlt,
  faTriangleExclamation,
  faUnderline,
  faUndo,
  faUnlockAlt,
  faUpload,
  faUser,
  faUserLock,
  faUserMinus,
  faUserPen,
  faUserPlus,
  faUserSlash,
  faUserTimes,
  faUserXmark,
  faUsers,
  faVideo,
  faWandMagicSparkles,
  faWarning,
  faXmark,
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
  faCaretUp,
  faCaretDown,
  faSnooze,
  faSparkle,
  faSparkles,
  faAlignRight,
  faAlignCenter,
  faAlignJustify,
  faHorizontalRule,
  faPaintbrush,
};
