import * as React from 'react';
import { Box, Text, VStack } from 'components/design/next';
import { AddIconButton } from './components/add-icon-button';
import { AddBackgroundButton } from './components/add-background-button';
import { AddCoverButton } from './components/add-cover-button';

export type DesignSectionProps = {};

export const DesignSection: React.FC<DesignSectionProps> = () => {
  return (
    <Box gap={2} w="full">
      <Text color="gray.500">Design</Text>
      <VStack>
        <AddIconButton />
        <AddBackgroundButton />
        <AddCoverButton />
      </VStack>
    </Box>
  );
};
