import * as React from 'react';
import { Template } from '@process-street/subgrade/process';
import { HStack, Spinner, IconButton, Input, Box, Text, useBoolean } from '@chakra-ui/react';
import { Icon, useToast } from 'components/design/next';
import { GetTemplateQuery, useUpdateTemplateMutation } from 'features/template/query-builder';
import { useQueryClient } from 'react-query';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { useMatch } from '@process-street/adapters/navigation';

export type TemplateNameEditorProps = {
  template: Template;
};

export const TemplateNameEditor = ({ template }: TemplateNameEditorProps) => {
  const [templateName, setTemplateName] = React.useState(template.name);
  const [isEditing, setIsEditing] = useBoolean();
  const queryClient = useQueryClient();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const toast = useToast();
  const isEditorV2 = useMatch('templateV2');

  const templatePermissions = useGetConsolidatedTemplatePermissionsQuery(template.id, {
    enabled: Boolean(template.id),
  });

  const updateTemplateMutation = useUpdateTemplateMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(GetTemplateQuery.getKey({ templateId: template.id }));
    },
    onError: () => {
      // revert local state on error
      setTemplateName(template.name);
      toast({
        status: 'error',
        title: "We're having problems updating the workflow name",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const canEdit = templatePermissions.data?.permissionMap.templateUpdate ?? false;

  const updateTemplate = () => {
    // Don't trigger the mutation if the name has not changed
    if (templateName === template.name) return;

    updateTemplateMutation.mutate({ templateId: template.id, name: templateName });
  };

  const handleEdit = () => {
    inputRef.current?.focus();
    setIsEditing.on();
  };

  const handleBlur = () => {
    setIsEditing.off();
    updateTemplate();
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsEditing.off();
    updateTemplate();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTemplateName(e.target.value);
  };

  React.useEffect(
    () => {
      if (template.name !== templateName) {
        setTemplateName(template.name);
      }
    },
    // We just want to listen to template changes, otherwise we can create an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [template.name],
  );

  return (
    <HStack role="group" w="full" spacing="0">
      <Box maxW="45vw" as="form" onSubmit={handleSubmit} position="relative">
        <Text
          fontWeight="medium"
          h="6"
          pr="2"
          lineHeight={isEditorV2 ? '24px' : undefined}
          noOfLines={1}
          visibility={isEditing ? 'hidden' : undefined}
          onClick={handleEdit}
        >
          {templateName}
        </Text>

        {isEditing && (
          <Input
            position="absolute"
            left="0"
            top="0"
            ref={inputRef}
            value={templateName}
            aria-label="Template name"
            name="templateName"
            onChange={handleChange}
            onBlur={handleBlur}
            w="full"
            p="0"
            fontWeight="medium"
            h="6"
            autoFocus
            border="0"
            _focus={{ border: 0, boxShadow: 'unset' }}
            maxW="100%"
          />
        )}
      </Box>

      {!isEditing && !updateTemplateMutation.isLoading && canEdit && (
        <IconButton
          variant="ghost"
          size="xs"
          onClick={handleEdit}
          aria-label="Edit template name"
          icon={<Icon icon="edit" size="3" />}
          opacity="0"
          _groupHover={{ opacity: 1, background: 'gray.100' }}
          colorScheme="gray"
        />
      )}

      {updateTemplateMutation.isLoading && (
        <Box pl="2">
          <Spinner size="sm" colorScheme="brand" />
        </Box>
      )}
    </HStack>
  );
};
