import React from 'react';
import { RowNode } from '@ag-grid-community/core';
import { Box, Divider, HStack, Icon, IconButton, Text, useBreakpointValue, VStack } from 'components/design/next';
import { TasksTableUtils } from 'pages/tasks/components/tasks-table/tasks-table-utils';
import TasksTableDueDateGroup = TasksTableUtils.TasksTableDueDateGroup;
import { useFeatureFlag } from 'features/feature-flags';

const dueDateGroupTitleByKey = {
  [TasksTableDueDateGroup.Overdue]: 'Overdue',
  [TasksTableDueDateGroup.Today]: 'Due today',
  [TasksTableDueDateGroup.ThisWeek]: 'Due this week',
  [TasksTableDueDateGroup.ThisMonth]: 'Due this month',
  [TasksTableDueDateGroup.Later]: 'Due later',
  [TasksTableDueDateGroup.NoDueDate]: 'No due date',
};

type DueDateGroupRowRendererProps = {
  value: TasksTableDueDateGroup;
  node: RowNode<TasksTableUtils.TasksTableItem>;
};

export const DueDateGroupRowRenderer = (props: DueDateGroupRowRendererProps) => {
  const { node } = props;
  const isMyWorkGAEnabled = useFeatureFlag('myWorkGA');
  const onClick = React.useCallback(() => node.setExpanded(!node.expanded), [node]);
  const isMobile = useBreakpointValue({ base: true, md: false });
  return isMyWorkGAEnabled ? (
    <VStack spacing="0" w="full" h="full">
      <HStack
        w="full"
        h="full"
        py={2}
        ml={{ base: 0, lg: 9 }}
        pl={{ base: 3, lg: 1.5 }}
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        <IconButton
          aria-label="Toggle group"
          variant="ghost"
          color="gray.600"
          size="xs"
          onClick={onClick}
          mr={1}
          icon={<Icon icon={node.expanded ? 'caret-down' : 'caret-right'} size="4" variant="fas" />}
        />
        <Text ml="var(--ps-space-0) !important">
          <Text as="span" fontSize="lg" fontWeight="bold">
            {dueDateGroupTitleByKey[props.value]}{' '}
          </Text>
          ({node.allChildrenCount && node.allChildrenCount >= 50 ? '50+' : node.allChildrenCount})
        </Text>
      </HStack>
      <Box pt="3px" pl={isMobile ? '0' : `${TasksTableUtils.CHECKBOX_COL_WIDTH_PX}px`} w="full">
        <Divider />
      </Box>
    </VStack>
  ) : (
    <HStack
      as="button"
      w="full"
      h="full"
      onClick={onClick}
      fontSize="md"
      color="gray.600"
      backgroundColor="transparent"
      _hover={{
        backgroundColor: 'transparent',
        color: 'gray.400',
        cursor: 'pointer',
      }}
      _active={{ backgroundColor: 'transparent' }}
      justifyContent="flex-start"
      alignItems="flex-end"
      fontWeight="normal"
      py={2}
      ml={{ base: 0, lg: 10 }}
      pl={{ base: 3, lg: 1.5 }}
      borderBottomColor="gray.200"
      borderBottomWidth="1px"
      borderBottomStyle="solid"
    >
      <HStack>
        <Icon size="4" variant="far" icon={node.expanded ? 'chevron-up' : 'chevron-down'} />
        <Text>
          <Text as="span" fontSize="lg" fontWeight="medium">
            {dueDateGroupTitleByKey[props.value]}{' '}
          </Text>
          ({node.allChildrenCount})
        </Text>
      </HStack>
    </HStack>
  );
};
