import * as React from 'react';
import { UIActorProvider, useSharedContext } from 'app/pages/forms/_id/shared';
import {
  FormEditorPageActorRefProvider,
  FormEditorPageActorSelectors,
  makeFormEditorPageMachine,
} from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { useInterpret, useSelector } from '@xstate/react';
import { TemplateSettingsModalProvider } from 'app/pages/templates/_id/components/template-settings-modal/template-settings-modal-context';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { AutomationsModalWrapper } from 'app/pages/templates/_id/automation';
import { TaskTemplate, TemplateType } from '@process-street/subgrade/process';
import { Dictionary } from 'lodash';
import _isEqual from 'lodash/isEqual';
import { HighlightedTasksContextProvider } from '../edit-v2/context';
import { WorkflowViewPage } from './view.component';

export const WorkflowViewWrapper = () => {
  const sharedContext = useSharedContext();
  const machine = React.useRef(makeFormEditorPageMachine({ sharedContext, isReadOnly: true })).current;
  const actor = useInterpret(machine);
  const uiActorRef = useSelector(actor, FormEditorPageActorSelectors.getUIActorRef);
  const [highLightedTasksMap, setHighlightedTasksMap] = React.useState<Dictionary<TaskTemplate>>();

  const template = useSelector(actor, FormEditorPageActorSelectors.getTemplate);

  const publishedTemplateRevision = useSelector(actor, FormEditorPageActorSelectors.getPublishedTemplateRevision);

  if (!template || !publishedTemplateRevision) return null;

  return (
    <DndProvider backend={HTML5Backend}>
      <FormEditorPageActorRefProvider actor={actor}>
        <TemplateSettingsModalProvider templateId={template.id} templateRevisionId={publishedTemplateRevision.id}>
          <ThemeProvider2024>
            <UIActorProvider uiActorRef={uiActorRef}>
              <AutomationsModalWrapper
                templateRevision={publishedTemplateRevision}
                templateType={TemplateType.Playbook}
              >
                <HighlightedTasksContextProvider
                  highLightedTasksMap={highLightedTasksMap}
                  setHighlightedTasksMap={setHighlightedTasksMap}
                >
                  <WorkflowViewPage template={template} templateRevision={publishedTemplateRevision} />
                </HighlightedTasksContextProvider>
              </AutomationsModalWrapper>
            </UIActorProvider>
          </ThemeProvider2024>
        </TemplateSettingsModalProvider>
      </FormEditorPageActorRefProvider>
    </DndProvider>
  );
};
