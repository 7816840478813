import * as React from 'react';
import {
  AiGenerationType,
  useAiGenerationSlice,
} from 'app/pages/templates/_id/components/ai-generated-workflow-settings-modal/store';
import {
  FormEditorPageActorSelectors,
  useFormEditorPageActorRef,
} from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { useSelector } from '@xstate/react';
import { AiWorkflowGenerationActorSelector } from 'app/pages/forms/_id/edit/ai-workflow-generation-machine';
import { useGetAiGenerationWorkflowSummaryStats } from 'app/pages/templates/_id/components/ai-generated-workflow-settings-modal/use-get-ai-generation-workflow-summary-stats';
import { LocalStorageService } from 'app/features/storage/local-storage-service';
import { PublishDraftMutation, useTemplateRevisionQuery } from 'app/features/template-revisions/query-builder';
import { AiGenerationWorkflowSummary } from 'app/pages/templates/_id/components/ai-generated-workflow-settings-modal/ai-generation-workflow-summary';
import { usePublishAndRun } from 'app/pages/templates/_id/components/ai-generated-workflow-settings-modal/use-publish-and-run';
import { useDisclosure, useToast } from 'app/components/design/next';
import { GetNewestTemplateRevisionsByTemplateIdQuery, useDeleteTemplate } from 'app/features/template/query-builder';
import { AiLocalStorage } from 'app/pages/templates/_id/components/ai-generated-workflow-settings-modal/ai-local-storage';
import { useNavigate } from '@process-street/adapters/navigation';
import { queryString } from '@process-street/subgrade/util';
import { TaskTemplate, Template, TemplateRevision } from '@process-street/subgrade/process';
import { RunChecklist } from 'app/components/run-checklist/components/RunChecklist/RunChecklist';
import { useQueryClient } from 'react-query';
import { AiGeneratingWorkflowSuccessToast } from 'app/pages/templates/_id/components/ai-generated-workflow-settings-modal/ai-generation-workflow-success';

export type AiGenerationSummaryProps = {
  template: Template;
  templateRevision: TemplateRevision;
  currentTaskTemplate?: TaskTemplate;
};

export const AiGenerationSummary = ({ template, templateRevision, currentTaskTemplate }: AiGenerationSummaryProps) => {
  const actor = useFormEditorPageActorRef();
  const aiGenerationActor = useSelector(actor, FormEditorPageActorSelectors.getAiWorkflowGeneratorActorRef);
  const isGenerationDone = useSelector(aiGenerationActor, AiWorkflowGenerationActorSelector.isDone);

  const aiGenerationSlice = useAiGenerationSlice();
  const initialConfig = AiLocalStorage.getInitialConfigFromLocalStorage();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const isTaskTemplateAnimationDoneSelector = React.useMemo(() => {
    if (!currentTaskTemplate?.group.id) return () => false;

    return AiWorkflowGenerationActorSelector.isTaskTemplateAnimationDone(currentTaskTemplate?.group.id);
  }, [currentTaskTemplate?.group.id]);
  const isTaskGenerationDone = useSelector(aiGenerationActor, isTaskTemplateAnimationDoneSelector);

  const runChecklistDisclosure = useDisclosure();
  const retryCount = initialConfig?.retryCount ?? 0;
  const toast = useToast();

  const newestTemplateRevisionQuery = useTemplateRevisionQuery({ templateRevisionId: templateRevision.id });
  const getSummaryStats = useGetAiGenerationWorkflowSummaryStats({ templateRevisionId: templateRevision.id });

  const publishDraftMutation = PublishDraftMutation.useMutation();
  const publishAndRunMutation = usePublishAndRun(
    {
      templateRevisionId: templateRevision.id,
      templateId: newestTemplateRevisionQuery.data?.template.id ?? '',
      templateName: newestTemplateRevisionQuery.data?.template.name ?? '',
    },
    {
      onSuccess: () => {
        toast.closeAll();
      },
    },
  );
  const deleteTemplateMutation = useDeleteTemplate();

  const handlePublishAndRun = async () => {
    if (!newestTemplateRevisionQuery.data?.defaultChecklistName) {
      await publishDraftMutation.mutateAsync({
        tmplRevId: templateRevision.id,
      });
      runChecklistDisclosure.onOpen();
    } else {
      return publishAndRunMutation.mutateAsync({});
    }
  };

  const handleRegenerate = async ({ name, description }: { name: string; description?: string }) => {
    if (!newestTemplateRevisionQuery.data) return;

    deleteTemplateMutation.mutate(
      { templateId: newestTemplateRevisionQuery.data.template.id },
      {
        onSettled: () => {
          navigate({
            pathname: 'templateAiGenerate',
            search: queryString.stringify({
              name,
              description,
              retryCount: retryCount + 1,
            }),
          });
        },
      },
    );
  };

  React.useEffect(
    function handleWorkflowGenerationComplete() {
      if (!aiGenerationSlice.isGenerating || aiGenerationSlice.generationType === AiGenerationType.SingleTask) return;

      const tryShowingToast = async () => {
        if (!isGenerationDone) return;

        // Close the modal and remove the backdrop once everything is loaded
        const { generationType } = aiGenerationSlice;
        aiGenerationSlice.stopGeneration();
        const stats = await getSummaryStats();
        const newestTemplateRevision = await newestTemplateRevisionQuery.refetch();
        const workflowName = newestTemplateRevision.data?.template.name;
        const templateId = newestTemplateRevision.data?.template.id ?? '';

        await queryClient.invalidateQueries(
          GetNewestTemplateRevisionsByTemplateIdQuery.getKey({ templateId: newestTemplateRevision.data?.template.id }),
        );

        LocalStorageService.setItem(`ai-generated-workflow-${templateId}`, 'true');

        const toastProps = {
          onPublishAndRun: handlePublishAndRun,
          workflowName,
          stats,
        };

        if (generationType === AiGenerationType.ImportWorkflowTasks) {
          toast({
            duration: null,
            render: ({ onClose }) => (
              <AiGenerationWorkflowSummary
                {...toastProps}
                onClose={onClose}
                subject="workflow"
                title="Your workflow has been imported!"
              />
            ),
          });
        } else {
          toast({
            duration: null,
            render: ({ onClose }) => (
              <AiGenerationWorkflowSummary
                {...toastProps}
                subject="workflow"
                onClose={onClose}
                onRegenerate={() =>
                  handleRegenerate({
                    name: workflowName ?? '',
                    description: newestTemplateRevision.data?.template.description,
                  })
                }
              />
            ),
          });
        }
      };

      void tryShowingToast();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- show toast in these circumstances
    [isGenerationDone],
  );

  React.useEffect(
    function handleTaskContentGenerationDone() {
      if (!aiGenerationSlice.isGenerating || aiGenerationSlice.generationType !== AiGenerationType.SingleTask) return;

      const tryShowingToast = async () => {
        if (!isTaskGenerationDone) return;

        aiGenerationSlice.stopGeneration();

        toast({
          containerStyle: { marginTop: '60px' },
          render: () => <AiGeneratingWorkflowSuccessToast subject="task" />,
        });
      };

      void tryShowingToast();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- trigger this effect only when isTaskGenerationDone changes
    [isTaskGenerationDone],
  );

  return (
    <>
      {runChecklistDisclosure.isOpen && (
        <RunChecklist
          {...runChecklistDisclosure}
          onCancel={runChecklistDisclosure.onClose}
          onChecklistCreate={runChecklistDisclosure.onClose}
          templateId={template.id}
        />
      )}
    </>
  );
};
