import {
  Button,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  useOutsideClick,
  VStack,
} from 'components/design/next';
import { DataSetColumnDef, SavedViewFilterOperator } from '@process-street/subgrade/process';
import * as React from 'react';
import _keyBy from 'lodash/keyBy';
import { match } from 'ts-pattern';
import { DataSetFiltersSlice, useDataSetFilters } from '../../store';
import { ColumnOptionType, ColumnsFilterItem } from './columns-filter-item';
import { useSelectedSavedView } from 'pages/reports/data-sets/components/data-set-page/selected-saved-view-id-store';
import { useFeatureFlag } from 'features/feature-flags';
import { useDebounce } from 'use-debounce';
import { ButtonGroup } from '@chakra-ui/react';

export type DataSetColumnsFilterProps = {
  isDisabled: boolean;
};

export const DataSetColumnsFilter: React.FC<React.PropsWithChildren<DataSetColumnsFilterProps>> = ({ isDisabled }) => {
  const isDataSetsV2Enabled = useFeatureFlag('dataSetsV2');
  const { selectedDataSet, selectedSavedView } = useSelectedSavedView();
  const disclosure = useDisclosure();
  const popoverContentRef = React.useRef<HTMLDivElement>(null);

  const dataSetFilters = useDataSetFilters();
  const getSerializedFilters = (filtersSlice: DataSetFiltersSlice) =>
    JSON.stringify(filtersSlice.filters) + JSON.stringify(filtersSlice.operator);

  const [initialState, setInitialState] = React.useState(getSerializedFilters(dataSetFilters));
  const hasFilters = dataSetFilters.filters.length > 0;
  const hasChanges = getSerializedFilters(dataSetFilters) !== initialState;

  const [debouncedIsOpen] = useDebounce(disclosure.isOpen, 1000);
  useOutsideClick({
    ref: popoverContentRef,
    handler: () => {
      if (debouncedIsOpen && popoverContentRef.current) {
        if (hasChanges) {
          applyFilters();
        }
        disclosure.onClose();
      }
    },
    // Add a debounced value to prevent a glitch where the menu closes as soon as the user clicks on the button
    enabled: debouncedIsOpen,
  });

  const columnsMap: Record<string, DataSetColumnDef> = React.useMemo(() => {
    if (!selectedDataSet) return {};

    return _keyBy(selectedDataSet.columnDefs, 'id');
  }, [selectedDataSet]);

  const columnOptions: ColumnOptionType[] = React.useMemo(() => {
    if (!selectedDataSet) return [];

    if (selectedSavedView) {
      const columns = selectedSavedView.columns;

      return columns.map(col => ({
        value: col.id,
        label: columnsMap[col.id]?.name ?? col.id,
      }));
    }

    return selectedDataSet.columnDefs.map(col => ({
      value: col.id,
      label: col.name,
    }));
  }, [selectedDataSet, selectedSavedView, columnsMap]);

  const handleAddFilter = () => {
    dataSetFilters.addFilter();
  };

  const shouldHighlightButton = disclosure.isOpen || hasFilters;

  const applyFilters = () => {
    dataSetFilters.applyFilters();
    setInitialState(getSerializedFilters(dataSetFilters));
  };

  if (!selectedDataSet) return null;
  return (
    <Popover {...disclosure} placement="bottom-start">
      <PopoverTrigger>
        <Button
          isDisabled={isDisabled}
          onClick={disclosure.onOpen}
          variant="ghost"
          color={shouldHighlightButton ? 'brand.500' : 'gray.600'}
          fontSize={isDataSetsV2Enabled ? undefined : 'sm'}
          fontWeight={shouldHighlightButton ? 500 : 400}
          leftIcon={<Icon icon="filter" size="4" color="inherit" />}
          rightIcon={
            <Icon
              transform={disclosure.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
              transition="200ms ease transform"
              icon="chevron-down"
              size="3"
              color="inherit"
            />
          }
          borderWidth="1px"
          borderColor={shouldHighlightButton ? 'brand.500' : 'transparent'}
          borderStyle="solid"
          borderRadius="full"
          bgColor={shouldHighlightButton ? 'brand.100' : 'transparent'}
          px="2"
          _hover={{
            borderColor: 'brand.500',
            bgColor: 'brand.100',
          }}
        >
          {match(dataSetFilters.filters.length)
            .with(0, () => `Filters`)
            .with(1, () => '1 Filter')
            .otherwise(l => `${l} Filters`)}
        </Button>
      </PopoverTrigger>

      <PopoverContent ref={popoverContentRef} w="700px">
        <PopoverBody p="4">
          <VStack alignItems="flex-start" spacing="4">
            {dataSetFilters.filters.length === 0 && <Text color="gray.500">No filters are currently applied</Text>}
            {dataSetFilters.filters.map((filter, index) => (
              <ColumnsFilterItem
                key={filter.id}
                filter={filter}
                columnOptions={columnOptions}
                columnsMap={columnsMap}
                index={index}
                operator={index === 0 ? undefined : dataSetFilters.operator ?? SavedViewFilterOperator.And}
                onOperatorChange={dataSetFilters.setOperator}
              />
            ))}

            <ButtonGroup width="full" justifyContent="space-between">
              <Button
                onClick={handleAddFilter}
                variant="tertiary"
                leftIcon={<Icon color="inherit" icon="plus" size="4" />}
                color="gray.600"
                colorScheme="brand"
              >
                Add Filter
              </Button>
              <Button onClick={applyFilters} colorScheme="brand" isDisabled={!hasChanges}>
                Apply
              </Button>
            </ButtonGroup>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
