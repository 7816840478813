import { TaskTemplate } from '@process-street/subgrade/process';
import { HStack, Icon, Text, IconButton, Input } from 'components/design/next';
import {
  FormEditorPageActorSelectors,
  FormEditorPageMachine,
  useFormEditorPageActorRef,
} from 'app/pages/forms/_id/edit/form-editor-page-machine';
import * as React from 'react';
import { useStateParam } from 'app/hooks/use-state-param';
import { StateFrom } from 'xstate';
import { Option } from 'space-monad';
import { useSelector } from '@xstate/react';

export const TaskNameInput = () => {
  const actor = useFormEditorPageActorRef();
  const taskTemplateGroupId = useStateParam({ key: 'groupId' });
  const currentTaskTemplateSelector = React.useCallback(
    (state: StateFrom<FormEditorPageMachine>) => {
      return Option(taskTemplateGroupId)
        .map(groupId => FormEditorPageActorSelectors.getTaskTemplateByGroupId(groupId)(state))
        .get();
    },
    [taskTemplateGroupId],
  );

  const currentTaskTemplate = useSelector(actor, currentTaskTemplateSelector);

  const [name, setName] = React.useState(currentTaskTemplate?.name);
  const [isEditing, setIsEditing] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onChange = (taskTemplate: TaskTemplate) => {
    actor.send({ type: 'UPDATE_TASK_TEMPLATE', taskTemplate, taskTemplateId: taskTemplate.id });
  };

  const handleOnBlur = (_: React.FocusEvent<HTMLInputElement>) => {
    setIsEditing(false);
    if (currentTaskTemplate && currentTaskTemplate.name !== name) {
      onChange({
        ...currentTaskTemplate,
        name,
      });
    }
  };

  const handleOnPressKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && currentTaskTemplate && currentTaskTemplate.name !== name) {
      setIsEditing(false);
      onChange({
        ...currentTaskTemplate,
        name,
      });
    }
  };

  React.useEffect(
    function syncInternalName() {
      const isFocused = inputRef.current === document.activeElement;
      if (currentTaskTemplate && currentTaskTemplate.name !== name && !isFocused) {
        setName(currentTaskTemplate.name);
      }
    },
    // eslint-disable-next-line
    [currentTaskTemplate?.name],
  );

  const shouldShowEditInput = isEditing;

  return (
    <HStack
      _hover={{
        '& .chakra-button': {
          opacity: '1',
        },
        'cursor': name === '' ? 'text' : 'inherit',
      }}
      w="full"
      h="30px"
      onClick={() => setIsEditing(true)}
    >
      {shouldShowEditInput ? (
        <Input
          autoFocus
          ref={inputRef}
          fontWeight="medium"
          variant="unstyled"
          color="gray.700"
          fontSize="18px"
          minH="6"
          w="full"
          h="30px"
          flex="1"
          value={name}
          onChange={e => setName(e.target.value)}
          onKeyPress={handleOnPressKey}
          onBlur={handleOnBlur}
        />
      ) : (
        <Text
          fontWeight="medium"
          fontSize="18px"
          color="gray.700"
          minH="6"
          noOfLines={1}
          _hover={{
            cursor: 'text',
          }}
          onClick={() => setIsEditing(true)}
        >
          {name}
        </Text>
      )}
      <IconButton
        visibility={shouldShowEditInput ? 'hidden' : 'visible'}
        minW="unset"
        p="0"
        bgColor="transparent"
        variant="ghost"
        w="5"
        h="5"
        aria-label="Edit task name"
        opacity="0"
        _hover={{ bgColor: 'transparent' }}
        onClick={() => setIsEditing(true)}
      >
        <Icon icon="edit" size="3.5" color="gray.500" />
      </IconButton>
    </HStack>
  );
};
