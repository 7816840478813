import * as React from 'react';
import {
  Text,
  VStack,
  Icon,
  HStack,
  Box,
  FormHelperText,
  IconButton,
  Textarea,
  InputGroup,
  InputRightElement,
} from 'components/design/next';
import { MultiSelectFieldValue, TemplateRevision } from '@process-street/subgrade/process';
import { FormikErrors, FormikTouched } from 'formik';
import { useDropOnSubtaskListItem, UseDropOnSubtaskListItemValue } from './use-drop-on-subtask-list-item';
import { ReorderUtils } from 'pages/forms/_id/edit/components/widgets-list/reorder-utils';
import { SubtaskEditor } from './subtasks-form-field';
import { MergeTagsMenu, MergeTagsMenuButton } from 'app/features/merge-tags/components/merge-tags-menu';
import { MergeTagsConstants } from '@process-street/subgrade/form';
import { useMergeTaggableInput } from 'app/hooks/use-merge-taggable-input';
import { noop } from 'lodash';
import { Muid } from '@process-street/subgrade/core';

type Value = NonNullable<MultiSelectFieldValue>;

type SubtaskProps = {
  index: number;
  subtask: Value;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onDelete: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDrop: (v: UseDropOnSubtaskListItemValue) => void;
  touched?: FormikTouched<{ name: boolean }>;
  error?: FormikErrors<{ name: string }>;
  editorType?: SubtaskEditor;
  onMergeTagChange?: (value: string) => void;
  templateRevisionId?: TemplateRevision['id'];
  groupId?: Muid;
  isReadOnly?: boolean;
};

export const Subtask = ({
  index,
  subtask,
  onChange,
  onBlur,
  onKeyDown,
  onDelete,
  onDrop,
  touched,
  error,
  editorType = SubtaskEditor.OneOffTask,
  onMergeTagChange = noop,
  templateRevisionId,
  groupId,
  isReadOnly = false,
}: SubtaskProps) => {
  const hasError = Boolean(error?.name);
  const hasBeenTouched = touched?.name ?? false;

  const {
    dropResult: { isOver },
    hoverLocation,
    ref,
    isDragging,
  } = useDropOnSubtaskListItem({ itemValue: subtask, onDrop });
  const handleChange = (value: React.ChangeEvent<HTMLTextAreaElement> | string) => onMergeTagChange(value as string);
  const { ref: tagRef, insertMergeTag: insertVariable } = useMergeTaggableInput<HTMLTextAreaElement>({
    get: () => subtask.name ?? '',
    set: handleChange,
  });
  return (
    <Box
      position="relative"
      ref={ref}
      w="full"
      onPointerDownCapture={e => {
        if (isReadOnly) {
          e.stopPropagation();
          return;
        }
        const target = e.target as HTMLElement;
        const isDragHandle = ReorderUtils.isTargetDragHandle(target);
        // allow reordering from verified handles (e.g., select dropdown choices)

        if (isDragHandle) return;
        e.stopPropagation();
      }}
      _hover={{
        '[data-is-drag-handle]': {
          display: 'block',
        },
        '.taskNumber': {
          display: isReadOnly ? undefined : 'none',
        },
      }}
    >
      <HStack
        alignItems="flex-start"
        w="full"
        key={`subtask-${index}`}
        spacing={2}
        _hover={{
          '.chakra-button': {
            opacity: 1,
          },
        }}
        opacity={isDragging ? 0 : 1}
      >
        <HStack spacing={2} pt={1.5}>
          <Box minW={3} minH={4.5}>
            {!isReadOnly && (
              <Icon
                icon="grip-vertical"
                {...ReorderUtils.HANDLE_PROPS}
                variant="fas"
                w="full"
                size="3"
                color="gray.400"
                display="none"
                cursor="grab"
              />
            )}
            <Text className="taskNumber" fontSize="sm" color="gray.400">
              {index + 1}
            </Text>
          </Box>
          <Box minW={5} minH={5} borderRadius="4px" borderStyle="solid" borderWidth="thin" borderColor="gray.300" />
        </HStack>

        <HStack w="full">
          <VStack w="full" alignItems="flex-start">
            <InputGroup>
              <Textarea
                name={`subtasks.${index}${groupId ? '-' + groupId : ''}`}
                value={subtask.name ?? ''}
                onChange={onChange}
                onKeyDown={onKeyDown}
                border="none"
                placeholder="Type here or press enter to add another subtask"
                fontSize="sm"
                px={1}
                isInvalid={hasError && hasBeenTouched}
                isDisabled={isReadOnly}
                onBlur={onBlur}
                size="sm"
                rows={1}
                resize="none"
                wrap="off"
                overflowX="hidden"
                ref={tagRef}
              />

              {error?.name && hasBeenTouched && !subtask.name && (
                <FormHelperText color="red.500">{error.name}</FormHelperText>
              )}
              {!isReadOnly && editorType === SubtaskEditor.WFEditor && (
                <InputRightElement _focusWithin={{ zIndex: 3 }} height="full">
                  <MergeTagsMenu
                    {...{
                      templateRevisionId,
                      onSelect: (key, _fieldId, fallback) => insertVariable(key, fallback),
                      mergeTagTarget: MergeTagsConstants.Target.GENERAL,
                      menuButton: <MergeTagsMenuButton size="xs" bg="white" />,
                    }}
                  />
                </InputRightElement>
              )}
            </InputGroup>
          </VStack>
          {!isReadOnly && (
            <IconButton
              onClick={onDelete}
              aria-label={`Delete "${subtask.name}" subtask`}
              icon={<Icon icon="trash-can" size="4" color="gray.500" />}
              size="sm"
              variant="ghost"
              colorScheme="gray"
              opacity={{ base: 1, lg: 0 }}
            />
          )}
        </HStack>
      </HStack>

      {isOver && hoverLocation ? (
        <Box
          h={1}
          w="full"
          position="absolute"
          bg="brand.200"
          left="0"
          {...(hoverLocation === 'top'
            ? { top: 0, transform: 'translateY(-50%)' }
            : { bottom: 0, transform: 'translateY(50%)' })}
        />
      ) : null}
    </Box>
  );
};
