import { StringUtils } from '../util/string-utils';

export enum FeatureFlagKey {
  // START Permanent flags
  SignupSegmenentationConsultationThreshold = 'rr-287-signup-segmentation-consultation-threshold',
  SkipSubdomainRedirection = 'ps-11139-skip-subdomain-redirection',
  ShowLegacyEmailWidget = 'BR-1-show-legacy-email-widget',
  ShareLinksEntitlement = 'entitlement-share-links-spam-protection-override',
  // END Permanent flags
  FeedbackSubmission = 'mobile-feedback-submission',
  CompletedTasksVisibility = 'cd-51-show-hide-completed-tasks-on-wf-run',
  HideSkipConsultation = 'rr-287-hide-skip-consultation',
  EmailWidgetImprovements = 'rr-312-email-widget-improvements',
  AutoHideByDefault = 'st-157-auto-hide-by-default',
  OnboardingSlideshow = 'web-526-onboarding-slideshow',
  PermitFreeMembersInGroups = 'vty-182-permit-free-members-in-groups',
  PublishAndRun = 'br-420-publish-and-run-button',
  // typo in the key can't be changed
  ReactifiedTaskButtonStack = 'cd-292-reactified-template-button-stack',
  FreeMemberPreAssignments = 'vty-130-pre-assignments-for-guests-internal',
  DynamicDueDatesWfr = 'vty-153-default-dynamic-due-date-on-wfr',
  PublishToPublicTemplateLibrary = 'vty-199-publish-to-public-template-library',
  TuneReactQueryCaching = 'cd-326-tune-react-query-caching',
  SkipTaskCopy = 'cd-290-skip-task-copy',
  ReactifiedTaskTemplateContextMenu = 'cc-145-reactified-task-template-context-menu',
  NewTemplateLibraryRowAffordances = 'cc-298-new-affordances-to-template-library-row',
  VariablesInSubtasks = 'cc-300-variables-in-subtasks',
  EnhancedFileSecurity = 'cd-324-enhanced-file-security',
  DeleteArchiveMultipleWorkflowRunsFromReports = 'cc-337-delete-archive-multiple-workflow-runs-from-reports',
  DeleteArchiveMultipleTemplatesFromLibrary = 'cc-484-delete-archive-multiple-templates-from-library',
  DragAndDropLibrary = 'cc-488-drag-and-drop-library',
  WfrMigrationUpdates = 'cc-476-migrate-wfr-task-assignees',
  AutoSendRichEmailWidget = 'cc-504-auto-send-rich-email-widget',
  OrganizationTimeZone = 'cc-164-organization-date-format-and-time-zone',
  OrganizationDateFormat = 'cc-164-organization-date-format',
  LongTextFieldMarkdown = 'cc-311-long-text-field-markdown',
  ConditionalLogicMembersField = 'ps-11319-conditional-logic-members-field',
  DeactivatingUsers = 'br-502-deactivating-users',
  CustomNotifications = 'rr-715-custom-notifications',
  WorkdayIntegration = 'br-508-workday-integration',
  ReactifyWorkflowDropdownFormField = 'ps-11652-reactify-workflow-dropdown-form-field',
  TaskCondition = 'vty-255-add-task-condition-support',
  MergeTagImprovements = 'rr-799-merge-tag-improvements',
  WorkflowDashboard = 'rr-816-workflow-dashboard',
  InboxRevamp = 'rr-856-inbox-revamp',
  OneOffTasks = 'br-622-create-new-one-off-task',
  PdfEmbellishment = 'br-650-pdf-embellishment',
  CustomDateValidationWithDynamicParameters = 'cc-663-custom-date-validation-with-dynamic-parameters',
  BranchAndMergeWorkflows = 'vty-325-branch-and-merge-workflows',
  ExportConditionalLogic = 'cc-673-export-conditional-logic',
  ReportsFormFieldColumnSorting = 'cc-652-reports-form-field-column-sorting',
  CustomLabelsForWorkflowRuns = 'cc-667-custom-labels-for-workflow-runs',
  ReportsSavedViewFolders = 'cc-669-reports-saved-view-folders',
  TasksGA = 'br-699-tasks-ga',
  TableFormField = 'vty-363-table-form-field',
  MyWorkGA = 'br-716-my-work-ga',
  TimeBasedCL = 'rr-902-time-based-cl',
  SandboxMode = 'rr-908-sandbox-mode',
  WorkflowReview = 'rr-917-workflow-review',
  Gpt4oDocumentImport = 'cd-472-gpt-4-o-document-import',
  ApprovalNotes = 'cc-714-approval-notes',
  CompletedTasksInMyWork = 'cd-478-completed-tasks-in-my-work',
  ReactWorkflowEditor = 'br-799-react-workflow-editor',
  DataSetsV2 = 'rr-966-data-sets-v-2',
  GenerateDatasetWithAI = 'rr-1044-generate-dataset-with-ai',
  DataSetDropdownAutomations = 'rr-1059-data-set-dropdown-automations',
}

export type FeatureFlagStateKey = Uncapitalize<keyof typeof FeatureFlagKey>;

export type FeatureFlags = Record<FeatureFlagStateKey, boolean> & { isInitialized: boolean };

export const initialFeatureFlagState: FeatureFlags = Object.keys(FeatureFlagKey).reduce(
  (acc, key) => {
    acc[StringUtils.uncapitalize(key) as keyof FeatureFlags] = false;
    return acc;
  },
  { isInitialized: false } as FeatureFlags,
);
