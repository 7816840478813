import { RowNode } from '@ag-grid-community/core';
import { Link } from '@process-street/adapters/navigation';
import { Checklist, Template } from '@process-street/subgrade/process';
import { Box, Divider, HStack, Icon, IconButton, Text, useBreakpointValue, VStack } from 'components/design/next';
import React from 'react';
import { UNATTACHED_TASKS_GROUP } from '../tasks-table/column-defs';
import { TasksTableUtils } from '../tasks-table/tasks-table-utils';
import qs from 'qs';
import { abbreviateForTitle } from '@process-street/subgrade/util';
import { match, P } from 'ts-pattern';

type WorkflowRunGroupRowRendererProps = {
  value: string;
  node: RowNode<TasksTableUtils.TasksTableItem>;
  checklist?: Checklist | undefined;
  template?: Template | undefined;
};

export const WorkflowRunGroupRowRenderer = (props: WorkflowRunGroupRowRendererProps) => {
  const { node, checklist, template } = props;
  const [isExpanded, setIsExpanded] = React.useState(node.expanded);

  const onClick = React.useCallback(() => {
    // Doing node.setExpanded does not refresh the view because does not re-renders
    setIsExpanded(!node.expanded);
    node.setExpanded(!node.expanded);
  }, [node, setIsExpanded]);

  const groupName = checklist?.name ?? UNATTACHED_TASKS_GROUP;
  const isUnattachedTasksGroup = groupName === UNATTACHED_TASKS_GROUP;

  const allChildrenCount = node.allChildrenCount ? node.allChildrenCount - 1 : '';
  match(node.allChildrenCount)
    .with(
      P.when(count => (count as number) >= 50),
      () => '50+',
    )
    .with(
      P.when(count => (count as number) < 50),
      () => node.allChildrenCount,
    )
    .otherwise(() => null);
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <VStack spacing="0" w="full" h="full">
      <HStack
        w="full"
        h="full"
        py="2"
        ml={{ base: 0, lg: 9 }}
        pl={{ base: 2, lg: 6 }}
        justifyContent="flex-start"
        alignItems="center"
      >
        <IconButton
          aria-label="Toggle group"
          variant="ghost"
          color="gray.600"
          size="xs"
          onClick={onClick}
          icon={<Icon icon={isExpanded ? 'caret-down' : 'caret-right'} size="4" variant="fas" color="gray.600" />}
        />
        {isUnattachedTasksGroup ? (
          <Text>
            <Text as="span" fontSize="lg" fontWeight="bold">
              {groupName}
            </Text>
            {allChildrenCount}
          </Text>
        ) : (
          <VStack spacing="0" alignItems="flex-start">
            <HStack>
              <Link
                to={{
                  pathname: 'checklist',
                  search: qs.stringify({
                    id: checklist?.id,
                    title: `${abbreviateForTitle(checklist?.name)}-`,
                  }),
                }}
                target="_blank"
                w="full"
                _hover={{ textDecoration: 'unset' }}
              >
                <Text fontSize="lg" fontWeight="bold" color="gray.600" _hover={{ color: 'gray.500' }}>
                  {groupName}{' '}
                </Text>
              </Link>
              <Text>({allChildrenCount})</Text>
            </HStack>
            <Link
              to={{
                pathname: 'templateDashboard',
                search: qs.stringify({
                  id: template?.id,
                  title: `${abbreviateForTitle(template?.name)}-`,
                }),
              }}
              target="_blank"
              w="full"
              _hover={{ textDecoration: 'unset' }}
            >
              <Text fontSize="sm" fontWeight="medium" color="gray.500" _hover={{ color: 'brand.500' }}>
                {template?.name}
              </Text>
            </Link>
          </VStack>
        )}
      </HStack>
      <Box pt="2px" pl={isMobile ? '0' : `${TasksTableUtils.CHECKBOX_COL_WIDTH_PX}px`} w="full" h="1">
        <Divider />
      </Box>
    </VStack>
  );
};
