import { Box, Button, HStack, Icon, Text, Tooltip, VStack } from 'components/design/next';
import { ChecklistRuleDefinition } from '@process-street/subgrade/conditional-logic';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { Node, NodeType } from 'directives/rules/template/task-templates-selector/selector-helper';
import { ConditionalLogicUtils, NodeStatus } from 'features/conditional-logic/utils/conditional-logic-utils';
import * as React from 'react';
import { SideMenuSubItem, SideMenuSubItemProps } from '../side-menu-sub-item';
import { ConditionalLogicSideMenuHelpers } from '../sidemenu/helpers';
import { match, P } from 'ts-pattern';
import { useTemplateTypeContext } from 'utils/template/template-type-context';
import { useConditionalLogicModalStore } from '../modal/store';

export type SideMenuItemProps = {
  node: Node;
  isLast: boolean;
  widgetsMap: Record<string, Widget>;
  index: number;
  onSelect?: (taskTemplate: TaskTemplate) => void;
  showOnlyTrigger?: boolean;
  showArrow?: boolean;
  widgetsNodes?: Node[];
  ruleAffectingWidget?: ChecklistRuleDefinition;
  color?: SideMenuSubItemProps['color'];
  isSelected?: boolean;
  isExpanded?: boolean;
  onToggle?: () => void;
};

export const SideMenuItem: React.FC<React.PropsWithChildren<SideMenuItemProps>> = ({
  node,
  isLast,
  widgetsMap,
  showOnlyTrigger,
  showArrow,
  widgetsNodes,
  ruleAffectingWidget,
  color = 'brand',
  onSelect,
  isSelected,
  isExpanded,
  onToggle,
}) => {
  const { nodesStatus, selectedRule, selectedWidget } = useConditionalLogicModalStore();

  const nodeStatus = nodesStatus[ConditionalLogicUtils.getNodeGroupId(node)];

  const iconsState = ConditionalLogicSideMenuHelpers.getVisibilityIconsState({
    selectedRule,
    ruleAffectingWidget: null,
    nodeStatus,
  });

  const hasAChildThatParticipatesInRule = node.widgets?.some(widgetNode => {
    const nodeStatus: NodeStatus | undefined = nodesStatus[ConditionalLogicUtils.getNodeGroupId(widgetNode)];

    return Boolean(nodeStatus?.isTriggerAt.size || nodeStatus?.isShownBy.size || nodeStatus?.isHiddenBy.size);
  });
  const taskNodeStatus: NodeStatus | undefined = nodesStatus[ConditionalLogicUtils.getNodeGroupId(node)];
  const taskIsParticipatingInRule =
    taskNodeStatus?.isTriggerAt.size || taskNodeStatus?.isHiddenBy.size || taskNodeStatus?.isShownBy.size;

  const isParticipatingInRule = taskIsParticipatingInRule || hasAChildThatParticipatesInRule;

  const isAccordionEnabled = Boolean(node.widgets?.length);
  // enables selecting the task to show connected rules
  const isTaskSelectionEnabled = isAccordionEnabled || taskIsParticipatingInRule;

  const canRenderWidgetNode = (node: Node) => {
    return match({ selectedRule, showOnlyTrigger, selectedWidget })
      .with({ selectedRule: P.nullish, selectedWidget: P.nullish }, () => {
        return true;
      })
      .with({ showOnlyTrigger: true, selectedRule: P.not(P.nullish) }, ({ selectedRule }) => {
        return ConditionalLogicUtils.isWidgetNodeTriggeringRule(node, selectedRule, nodesStatus);
      })
      .with({ showOnlyTrigger: true, selectedWidget: P.not(P.nullish) }, ({ selectedWidget }) => {
        return node.id === selectedWidget.header.id;
      })
      .with({ selectedRule: P.not(P.nullish) }, ({ selectedRule }) => {
        const isAffectedByRule = ConditionalLogicUtils.isWidgetNodeAffectedByRule(node, selectedRule, nodesStatus);
        const isTheRuleTrigger = ConditionalLogicUtils.isWidgetNodeTriggeringRule(node, selectedRule, nodesStatus);

        return isAffectedByRule && !isTheRuleTrigger;
      })
      .otherwise(() => false);
  };

  const labelColor = match({ isDisabled: !isTaskSelectionEnabled, node, isSelected })
    .with({ isSelected: true }, () => 'white')
    .with({ node: { type: NodeType.Heading } }, () => 'gray.600')
    .with({ isDisabled: true }, () => 'gray.400')
    .otherwise(() => 'gray.600');

  const showAccordionItem = !selectedRule && !selectedWidget && isAccordionEnabled;

  const renderSubItem = (widgetNode: Node) => (
    <SideMenuSubItem
      key={widgetNode.id}
      nodeStatus={nodesStatus[ConditionalLogicUtils.getNodeGroupId(widgetNode)]}
      node={widgetNode}
      widgetsMap={widgetsMap}
      parentNodeStatus={nodeStatus}
      ruleAffectingWidget={ruleAffectingWidget}
      color={color}
    />
  );

  const { isForm } = useTemplateTypeContext();

  return (
    <Box id={ConditionalLogicUtils.getNodeGroupId(node)}>
      <HStack
        spacing="0"
        borderWidth="1px"
        aria-label={node.name}
        aria-selected={isSelected}
        borderBottomWidth={isLast || isExpanded ? '1px' : '0'}
        borderColor="gray.200"
        borderStyle="solid"
        color={isSelected ? 'white' : 'gray.600'}
        fontSize="sm"
        fontWeight={node.type === NodeType.Heading || isSelected ? 'semibold' : 'normal'}
        bgColor={isSelected ? 'brand.500' : 'white'}
        position="relative"
        h="9"
        cursor={selectedRule || selectedWidget ? 'default' : 'pointer'}
        _hover={match({ isDisabled: !isAccordionEnabled, selectedRule, selectedWidget, isSelected })
          .with(P.union({ isDisabled: true }, { isSelected: true }), () => ({}))
          .with(P.union({ selectedRule: P.not(P.nullish) }, { selectedWidget: P.not(P.nullish) }), () => ({
            borderColor: 'gray.200',
            bgColor: 'white',
          }))
          .otherwise(() => ({
            borderColor: 'gray.300',
            bgColor: 'gray.100',
          }))}
        _disabled={{ opacity: 1 }}
      >
        {!isForm && (
          <Text
            maxW="100%"
            position="absolute"
            left="0"
            fontSize="xs"
            fontWeight={isParticipatingInRule ? 'bold' : 'normal'}
            color={isParticipatingInRule ? 'purple.500' : 'gray.400'}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            textAlign="right"
            width="8"
            ml="-10"
          >
            {node.position}
          </Text>
        )}

        <Button
          onClick={isTaskSelectionEnabled ? () => onSelect?.(node.ref as TaskTemplate) : undefined}
          aria-label={node.name}
          variant="unstyled"
          w="full"
          maxW="calc(100% - 52px)"
          bgColor="transparent"
          pl="4"
          py="2"
          borderRadius="0"
        >
          <Tooltip openDelay={500} label={node.name}>
            <Text flex="1" textAlign="left" fontSize="sm" noOfLines={1} color={labelColor}>
              {node.name}
            </Text>
          </Tooltip>
        </Button>

        {showAccordionItem && (
          <Button
            variant="ghost"
            _hover={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
            aria-label={`${isExpanded ? 'Collapse' : 'Expand'} ${node.name}`}
            aria-expanded={isExpanded}
            maxW="13"
            mr="-5px"
            isDisabled={!isAccordionEnabled}
            display={showAccordionItem ? 'block' : 'none'}
            onClick={onToggle}
          >
            <Icon
              icon={isExpanded ? 'chevron-up' : 'chevron-down'}
              size="3"
              color={isSelected ? 'white' : 'gray.500'}
            />
          </Button>
        )}

        {(iconsState.showShownIcon || iconsState.showHiddenIcon || iconsState.showHiddenByDefaultIcon) && (
          <HStack data-testid="task-icons-state-container" spacing="2" _notLast={{ pr: '4' }}>
            {match(iconsState)
              .with({ showHiddenIcon: true }, () => (
                <Tooltip label="Task hidden by rule">
                  <Box>
                    <Icon icon="eye-slash" size="3.5" color="gray.500" />
                  </Box>
                </Tooltip>
              ))
              .with({ showShownIcon: true }, () => (
                <Tooltip label="Task shown by rule">
                  <Box>
                    <Icon icon="eye" size="3.5" color="gray.500" />
                  </Box>
                </Tooltip>
              ))
              .with({ showHiddenByDefaultIcon: true }, () => (
                <Tooltip label="Task hidden by default">
                  <Box>
                    <Icon aria-label="Task hidden by default" icon="eye-slash" size="3.5" color="gray.500" />
                  </Box>
                </Tooltip>
              ))
              .otherwise(() => null)}
          </HStack>
        )}
      </HStack>

      {isExpanded && (
        <Box pr="0" pt="0">
          <VStack spacing="0">
            {widgetsNodes
              ? widgetsNodes.map(renderSubItem)
              : node.widgets?.filter(canRenderWidgetNode).map(renderSubItem)}
            )
          </VStack>

          {selectedRule && showArrow && (
            <VStack mt="5">
              <Icon icon="chevron-down" color="brand.500" size="4" />
            </VStack>
          )}
        </Box>
      )}
    </Box>
  );
};
