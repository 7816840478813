import { SearchContext } from 'features/global-search/components/search-context';
import * as React from 'react';
import { SearchBox } from './components/search-box';
import { createUsableContext } from '@process-street/subgrade/util';
import { Muid } from '@process-street/subgrade/core';
import { CrossLinkCard, CrossLinkBody } from '../display/components';
import { Box } from 'components/design/next';
import { Hits } from 'features/global-search/components/hits';
import { Hit } from './components/hit';
import { useMachine } from '@xstate/react';
import { crosslinkSearchMachine } from './machine';
import type { Send } from './machine';
import { SearchClient } from 'algoliasearch';
import { useTemplateTypeContext } from 'utils/template/template-type-context';
import { noop } from 'lodash';
import { GlobalSearchHits } from 'features/global-search/components/model';
import isTemplateHit = GlobalSearchHits.isTemplateHit;

export type Props = {
  onSelect: (templateId: Muid) => void;
  searchClient?: SearchClient;
  onFocus?: () => void;
  isReadOnly?: boolean;
};
type Context = Props & {
  send: Send;
};

export const [useCrossLinkSearchContext, CrossLinkSearchContext] = createUsableContext<Context>({
  hookName: 'useCrossLinkSearchContext',
  providerName: 'CrossLinkSearchProvider',
});

export const CrossLinkCardSearch: React.FC<React.PropsWithChildren<Props>> = ({
  onSelect,
  searchClient,
  isReadOnly = false,
  onFocus = noop,
}) => {
  const [searchState, send] = useMachine(crosslinkSearchMachine);
  const onSelectOverride = React.useCallback(
    (...params: Parameters<Props['onSelect']>) => {
      send('SELECT');
      onSelect(...params);
    },
    [onSelect, send],
  );

  const context = React.useMemo<Context>(
    () => ({ onSelect: onSelectOverride, send, onFocus }),
    [onSelectOverride, send, onFocus],
  );
  const isOpen = searchState.matches('focused') || searchState.matches('blurring');
  const { templateType } = useTemplateTypeContext();

  return (
    <SearchContext {...{ isOpen, searchClient, templateType }}>
      <CrossLinkSearchContext.Provider value={context}>
        <Box position="relative">
          <CrossLinkCard {...{ p: 0, border: 0 }}>
            <CrossLinkBody>
              <SearchBox
                inputProps={{
                  borderWidth: '1px!important',
                  borderStyle: 'solid',
                  borderLeft: 'inherit',
                  borderTop: 'inherit',
                  borderRight: 'inherit',
                  isDisabled: isReadOnly,
                }}
              />
            </CrossLinkBody>
          </CrossLinkCard>
          {isOpen && (
            <Box
              bg="white"
              zIndex="dropdown"
              position="absolute"
              w="full"
              top="calc(100% + var(--ps-sizes-2))"
              borderRadius="base"
              borderColor="gray.200"
              borderWidth="px"
              borderStyle="solid"
            >
              <Hits>
                {hit =>
                  isTemplateHit(hit) && (
                    <Hit
                      hit={hit}
                      // since the hit doesn't know the list option is checking for mouse enter, we'll skip default hover styles
                      _hover={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
                    />
                  )
                }
              </Hits>
            </Box>
          )}
        </Box>
      </CrossLinkSearchContext.Provider>
    </SearchContext>
  );
};
