import * as React from 'react';
import { RichTextEditor } from 'features/rich-text';
import { TextContentActor } from './text-content-machine';
import { useActor } from '@xstate/react';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { ContentFieldRecentlyMovedIndicator } from '../common/content-field-recently-moved-indicator';
import { TextWidgetToolbar } from './components/toolbar';
import { FormatBlockType } from 'app/directives/text-widget-toolbar/toolbar-blocks';
import { useMatch } from '@process-street/adapters/navigation';

export interface TextContentProps {
  actor: TextContentActor;
  isFirst?: boolean;
  isLast?: boolean;
}

export const TextContent: React.FC<React.PropsWithChildren<TextContentProps>> = ({
  actor,
  isFirst = false,
  isLast = false,
}) => {
  const [state, send] = useActor(actor);
  const { widget, recentlyMovedFrom, isReadOnly } = state.context;
  const isEditorV2 = Boolean(useMatch('templateV2'));

  const initialValue = React.useRef(state.context.widget.content).current;

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer
        sx={{
          '.style-success': {
            bgColor: 'green.200',
            borderWidth: 'thin',
            borderStyle: 'solid',
            borderColor: 'green.300',
            borderRadius: '4px',
            marginTop: '1',
            p: 1.5,
            w: 'full',
          },
          '.style-info': {
            bgColor: 'blue.200',
            borderWidth: 'thin',
            borderStyle: 'solid',
            borderColor: 'blue.300',
            borderRadius: '4px',
            marginTop: '1',
            p: 1.5,
            w: 'full',
          },
          '.style-warning': {
            bgColor: 'yellow.200',
            borderWidth: 'thin',
            borderStyle: 'solid',
            borderColor: 'yellow.300',
            borderRadius: '4px',
            marginTop: '1',
            p: 1.5,
            w: 'full',
          },
          '.style-danger': {
            bgColor: 'red.200',
            borderWidth: 'thin',
            borderStyle: 'solid',
            borderColor: 'red.300',
            borderRadius: '4px',
            marginTop: '1',
            p: 1.5,
            w: 'full',
          },
          '.style-blockquote': {
            borderLeftStyle: 'solid',
            borderLeftWidth: '3px',
            borderLeftColor: 'gray.200',
            padding: 1.5,
            fontSize: 'lg',
          },
        }}
        alignItems="flex-start"
        fontSize="md"
      >
        {!isReadOnly && isEditorV2 && <TextWidgetToolbar editor={state.context.editor} widget={widget} />}

        {recentlyMovedFrom && <ContentFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}

        <RichTextEditor
          editorProps={{
            initialValue,
            onInit: (_evt, editor) => {
              send({ type: 'SET_EDITOR', editor });

              if (isReadOnly) {
                setTimeout(() => {
                  editor.mode.set('readonly');
                }, 0);
              }

              editor.formatter.register('bq', {
                block: 'p',
                // Use attributes instead so that it replaces the old classes instead of merging them
                attributes: {
                  class: FormatBlockType.Blockquote,
                },
              });

              editor.formatter.register('success', {
                block: 'p',
                attributes: {
                  class: FormatBlockType.Success,
                },
              });
              editor.formatter.register('info', {
                block: 'p',
                attributes: {
                  class: FormatBlockType.Info,
                },
              });
              editor.formatter.register('reminder', {
                block: 'p',
                attributes: {
                  class: FormatBlockType.Warning,
                },
              });
              editor.formatter.register('critical', {
                block: 'p',
                attributes: {
                  class: FormatBlockType.Danger,
                },
              });
            },
            onEditorChange: content => send({ type: 'CHANGE', content }),
            onBlur: (_event, _editor) => send({ type: 'BLUR' }),
            onFocus: (_event, _editor) => send({ type: 'FOCUS' }),
            ...(isEditorV2 ? { toolbar: false } : {}),
          }}
          editorWrapperProps={{
            'aria-label': 'text content editor container',
            'flex': '1',
            'userSelect': isReadOnly ? 'text' : undefined,
          }}
        />

        {!isReadOnly && (
          <>
            <WidgetListItemDragIcon />
            <FormsWidgetMenu>
              <FormsWidgetMenuItems.Duplicate />
              <FormsWidgetMenuItems.MoveToStep widget={widget} />
              <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
              <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
              <FormsWidgetMenuItems.Delete />
            </FormsWidgetMenu>
          </>
        )}
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};
