import * as React from 'react';
import { useActor } from '@xstate/react';
import { TextareaFormFieldActor } from './textarea-form-field-machine';
import { InputGroup, Textarea, VStack } from '@chakra-ui/react';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { FormFieldLabel } from '../common/form-field-label';
import { WidgetActorProvider } from '../../../../shared/widget-context';
import { SettingsModalHeader } from 'pages/forms/_id/edit/components/form-fields/common/settings/settings-modal-content';
import { SettingsModalFields } from 'pages/forms/_id/edit/components/form-fields/common/settings/fields';
import { textareaSettingsSchema } from './textarea-form-field-schema';
import { StringUtils } from '@process-street/subgrade/util';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { FormFieldRecentlyMovedIndicator } from '../common/form-field-recently-moved-indicator';
import { TemplateType } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';
import { useFeatureFlag } from 'features/feature-flags';

export type TextareaFormFieldWidgetProps = {
  isFirst: boolean;
  isLast: boolean;
  actor: TextareaFormFieldActor;
};

export const TextareaFormFieldWidget: React.FC<React.PropsWithChildren<TextareaFormFieldWidgetProps>> = ({
  actor,
  isFirst,
  isLast,
}) => {
  const [state, send] = useActor(actor);
  const { widget, labelActor, recentlyMovedFrom, template, isReadOnly } = state.context;
  const ref = React.useRef<HTMLDivElement | null>(null);
  const longTextMarkdownFeatureEnabled = useFeatureFlag('longTextFieldMarkdown');

  const placeholder = match(widget)
    .with({ config: { defaultValue: P.when(Boolean) } }, ({ config: { defaultValue } }) => defaultValue)
    .with({ config: { placeholder: P.when(Boolean) } }, ({ config: { placeholder } }) => placeholder)
    .otherwise(() => 'Long text will be typed here');

  const isWorkflow = template.templateType === TemplateType.Playbook;

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer>
        <VStack maxW="175" alignItems="flex-start" flex="1">
          {recentlyMovedFrom && <FormFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
          {labelActor && <FormFieldLabel isReadOnly={isReadOnly} actor={labelActor} />}

          <InputGroup
            ref={node => {
              ref.current = node;
              if (node && !state.context.inputNode) {
                send({ type: 'SET_NODE', node });
              }
            }}
          >
            <Textarea
              id={`form-field-widget-${widget.id}`}
              w="full"
              h="20"
              placeholder={placeholder}
              isDisabled
              borderColor="gray.200"
              backgroundColor="gray.50"
              _disabled={{ opacity: 1, borderColor: 'gray.100' }}
              _placeholder={{ color: 'gray.400' }}
              resize="none"
            />

            {!isReadOnly && (
              <>
                <WidgetListItemDragIcon />
                <FormsWidgetMenu>
                  <FormsWidgetMenuItems.Required widget={widget} />
                  <FormsWidgetMenuItems.Settings widget={widget} schema={textareaSettingsSchema}>
                    <SettingsModalHeader>
                      {StringUtils.getNonEmpty(widget.label, 'Untitled long text field')}
                    </SettingsModalHeader>
                    <SettingsModalFields.MinMax<typeof textareaSettingsSchema>
                      minFieldName="constraints.min"
                      maxFieldName="constraints.max"
                      unit="character limit"
                    >
                      <SettingsModalFields.MinMaxTo />
                    </SettingsModalFields.MinMax>
                    {longTextMarkdownFeatureEnabled && (
                      <SettingsModalFields.TextFormatSelector fieldName="config.format" />
                    )}
                    <SettingsModalFields.Placeholder label="Long text placeholder" />
                    {isWorkflow && (
                      <SettingsModalFields.DefaultValue
                        as="textarea"
                        minHeight={20}
                        templateRevisionId={widget.templateRevision.id}
                      />
                    )}
                  </FormsWidgetMenuItems.Settings>
                  <FormsWidgetMenuItems.ConditionalLogic widget={widget} />

                  <FormsWidgetMenuItems.Divider />

                  <FormsWidgetMenuItems.Duplicate />
                  <FormsWidgetMenuItems.MoveToStep widget={widget} />
                  <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
                  <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
                  <FormsWidgetMenuItems.Delete />
                </FormsWidgetMenu>
              </>
            )}
          </InputGroup>
        </VStack>
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};
