import * as React from 'react';
import { useDrop } from 'react-dnd';
import { DraggableType, NewWidgetItem } from '../../types';
import { Button, Icon, useToast, Text, VStack } from 'components/design/next';
import { useAiGenerationWidgetsMutation } from 'app/features/ai-generation/query-builder';
import { useAiGenerationSlice } from 'app/pages/templates/_id/components/ai-generated-workflow-settings-modal/store';
import { HttpStatus } from '@process-street/subgrade/util';
import { isHeading, TaskTemplate } from '@process-street/subgrade/process';
import { AiGenerationTooltip } from 'app/features/widgets/components/ai/ai-generation-tooltip';
import {
  FormEditorPageActorSelectors,
  useFormEditorPageActorRef,
} from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { useSelector } from '@xstate/react';
import { useTaskTemplateListActorRef } from 'app/pages/workflows/_id/edit-v2/hooks/use-task-templates-list-actor';
import { TaskTemplateListActorSelectors } from 'app/pages/workflows/_id/edit-v2/components/task-list/task-template-list-machine';

export type WorkflowEmptyStateProps = {
  taskTemplate?: TaskTemplate;
};

export const WorkflowEmptyState = ({ taskTemplate }: WorkflowEmptyStateProps) => {
  const actor = useFormEditorPageActorRef();
  const taskListActor = useTaskTemplateListActorRef();
  const [selectedTaskTemplate] = useSelector(taskListActor, TaskTemplateListActorSelectors.getSelectedTaskTemplates);
  const ref = React.useRef<HTMLDivElement>(null);
  const aiGenerationSlice = useAiGenerationSlice();
  const toast = useToast();
  const aiGenerationActor = useSelector(actor, FormEditorPageActorSelectors.getAiWorkflowGeneratorActorRef);
  const currentTaskTemplate = selectedTaskTemplate ?? taskTemplate;

  const [_dropResult, drop] = useDrop(() => ({
    accept: [DraggableType.FormField, DraggableType.Content],
    drop: (item: NewWidgetItem) => {
      actor.send({ type: 'CREATE_WIDGET', payload: item });
    },
    collect: monitor => ({ isOver: monitor.isOver() }),
  }));

  React.useEffect(() => {
    drop(ref);
  }, [drop]);

  const generateWidgetsMutation = useAiGenerationWidgetsMutation({
    onSuccess: _widgets => {
      aiGenerationActor.send({ type: 'ANIMATE_TASK_TEMPLATE_WIDGETS', taskTemplate: currentTaskTemplate });
    },
    onError: e => {
      aiGenerationSlice.stopGeneration();

      if (e.response?.status === HttpStatus.BAD_REQUEST) {
        toast({
          title: 'ChatGPT moderators rejected that Task name, try another one',
          status: 'error',
        });

        return;
      }

      toast({
        title: 'ChatGPT is experiencing an issue, please try again',
        status: 'error',
      });
    },
  });

  const handleGenerate = () => {
    // prevent quick double clicks
    if (generateWidgetsMutation.isLoading) return;

    aiGenerationSlice.startTaskGeneration(currentTaskTemplate.id);

    generateWidgetsMutation.mutate({ taskTemplateId: currentTaskTemplate.id });
  };

  const [hasTaskError, setHasTaskError] = React.useState(false);
  const headingError = (
    <Text key="heading-task-error" fontSize="xs">
      Cannot generate content for heading tasks.
    </Text>
  );

  const isHeadingTaskTemplate = isHeading(currentTaskTemplate);
  const generateButton = (
    <Button
      onClick={handleGenerate}
      leftIcon={<Icon icon="sparkles" variant="far" size="5" />}
      colorScheme="brand"
      fontSize="sm"
      iconSpacing="2"
      isDisabled={hasTaskError || isHeadingTaskTemplate || generateWidgetsMutation.isLoading}
    >
      Generate your task with AI
    </Button>
  );

  return (
    <VStack py="8" spacing="6" ref={ref}>
      <Text variant="1" color="gray.400">
        Drop content & forms here
      </Text>

      <Text variant="1" color="gray.400">
        or
      </Text>

      <AiGenerationTooltip
        taskTemplate={currentTaskTemplate}
        errorHeader={
          <Text fontSize="xs" fontWeight="semibold" color="white">
            Can&apos;t generate your task with AI.
          </Text>
        }
        extraErrorItems={isHeadingTaskTemplate ? [headingError] : undefined}
        setHasError={setHasTaskError}
      >
        {generateButton}
      </AiGenerationTooltip>
    </VStack>
  );
};
