import { Muid, MuidUtils, orderTreeService, User } from '@process-street/subgrade/core';
import {
  DueDateRuleDefinition,
  FieldType,
  FormFieldWidget,
  isFormFieldWidget,
  MembersFormFieldConfig,
  OrderTree,
  SelectFormFieldConfig,
  SendRichEmailFormFieldConfig,
  TaskConstants,
  TaskTemplate,
  TaskTemplateTaskType,
  Template,
  TemplateRevision,
  TemplateRevisionStatus,
  TemplateType,
  Widget,
  WidgetHeader,
  WidgetHeaderLike,
  WidgetType,
} from '@process-street/subgrade/process';
import {
  CreateTaskTemplateMutation,
  makeGetTaskTemplatesByTemplateRevisionIdQueryObserver,
  makeOptimisticTaskTemplate,
  makeOptimisticTaskTemplates,
  OptimisticTaskTemplate,
  TaskTemplatesByTemplateRevisionIdQuery,
  TaskTemplatesByTemplateRevisionIdQueryResponse,
  UpdateTaskTemplateMutation,
  UpdateTaskTemplateOrderTreesMutation,
} from 'features/task-templates/query-builder';
import {
  GetNewestTemplateRevisionsByTemplateIdQuery,
  GetNewestTemplateRevisionsByTemplateIdQueryResponse,
  GetTemplateQuery,
  GetTemplateQueryResponse,
  makeGetNewestTemplateRevisionsByTemplateIdQueryObserver,
  makeGetTemplateQueryObserver,
} from 'features/template/query-builder';
import {
  CopyWidgetByHeaderIdMutation,
  CreateWidgetMutation,
  makeGetWidgetsByTemplateRevisionIdObserver,
  UpdateWidgetOrderTreesMutation,
  WidgetsByTemplateRevisionIdQuery,
  WidgetsByTemplateRevisionIdQueryResponse,
} from 'features/widgets/query-builder';
import { CopyWidgetOptions } from 'pages/pages/_id/edit/page/utils/widget.api';
import { createOrderTreeBulkUpdater } from 'services/order-tree-bulk-updater.impl';
import { ActionObject, ActorRefFrom, assign, createMachine, forwardTo, send, sendTo, spawn, StateFrom } from 'xstate';
import { FormFieldHelpers, toOrderTrees } from '../helpers/form-field';
import { CreateTemplateRevisionMutation } from 'features/template-revisions/query-builder/create-template-revision-mutation';
import { match, P } from 'ts-pattern';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { FormFieldActor, FormFieldMachineFactories } from '../components/form-fields';
import { ContentActor, ContentMachineFactories } from '../components/content';
import {
  makeQueryMachine,
  QueryActor,
  QueryActorSelectors,
  SystemUpdateEvent,
} from 'utils/query-builder/query-machine';
import { RefetchOnMountOnly } from 'utils/query-builder';
import { makeUIMachine, SharedContext, UIActorRef } from '../../shared';
import { makeTopBarMachine, TopBarActorRef, TopBarParentEvent } from '../../shared/form-editor-top-bar/top-bar-machine';
import { ToastServiceImpl } from 'services/toast-service.impl';
import _groupBy from 'lodash/groupBy';
import _keyBy from 'lodash/keyBy';
import { FormFieldDefaultKey } from 'services/form-field-service-constants';
import { NewWidgetItem } from '../types';
import { getOrderableComparer } from 'services/util-pure';
import { DeleteTaskTemplateMachineParentEvent, makeDeleteTaskTemplateMachine } from './delete-task-template-machine';
import React from 'react';
import { NewFormNameModalContent } from '../new-form-name-modal-content';
import { UpdateTemplateMutation } from 'features/template/mutation-builder';
import { TemplateConstants } from 'services/template-constants';
import { DuplicateTaskTemplateMutation } from 'features/task-templates/query-builder/duplicate-task-template-mutation';
import { createDuplicateTaskTemplatePlaceholder } from 'directives/task-template-list/create-duplicate-task-template-placeholder';
import { MoveWidgetMutation } from 'features/widgets/query-builder/move-widget-mutation';
import { LocationService } from '@process-street/subgrade/util';
import { TableFormFieldConfig } from '@process-street/subgrade/process/configs/table-form-field-config';
import {
  GetTemplateTaskAssignmentsByTemplateRevisionIdResult,
  makeGetAllTaskTemplatesAssignmentsByTemplateRevisionIdQuery,
} from 'app/features/task-template-assignment/query-builder';
import {
  makeGetAllOrganizationMembershipsQueryObserver,
  OrganizationMembershipsQueryResult,
} from 'app/features/organization-memberships/query-builder';
import { GetTaskAssignmentRulesByTemplateRevisionIdQuery } from 'app/features/task-assignment-rules/query-builder';
import { GetAllGroupsResponse, makeGetAllGroupsQueryObserver } from 'app/features/group/query-builder/get-all-groups';
import { ALL_MEMBERS_GROUP_USERNAME } from '@process-street/subgrade/util/membership-utils';
import { GetDueDateRulesByTemplateRevisionIdQuery } from 'app/features/dynamic-due-dates/query-builder';
import { MergeTagReferenceUpdateService } from 'app/features/widgets/merge-tag-reference-update-service';
import { GetAllRulesByTemplateRevisionIdQuery } from 'app/features/conditional-logic/query-builder';
import { ChecklistRuleDefinition } from '@process-street/subgrade/conditional-logic';
import { GetApprovalRulesByTaskTemplateIdQuery } from 'app/features/approval-rules/query-builder';
import { ApprovalRuleSubject } from '@process-street/subgrade/approval-rule';
import {
  AiWorkflowGenerationActorSelector,
  AiWorkflowGenerationMachineActorRef,
  makeAiWorkflowGenerationMachine,
} from '../ai-workflow-generation-machine';
import {
  makeTaskTemplateListMachine,
  TaskTemplateListActorRef,
} from 'app/pages/workflows/_id/edit-v2/components/task-list/task-template-list-machine';
import { GetAllNativeAutomationsQuery } from 'app/features/native-automations/query-builder';
import { toSuccess } from 'app/reducers/util';
import { WIDGET_GET_ALL_BY_TEMPLATE_REVISION_ID } from 'app/components/widgets/store/widget.actions';
import { TEMPLATE_GET_BY_ID } from 'app/reducers/template/template.actions';
import { TASK_TEMPLATE_GET_ALL_BY_TEMPLATE_REVISION_ID } from 'app/reducers/task-template/task-template.actions';
import { DYNAMIC_DUE_DATE_GET_ALL_BY_TEMPLATE_REVISION } from 'app/components/dynamic-due-dates/store/dynamic-due-dates.actions';
import pLimit from 'p-limit';

export type WidgetActor = FormFieldActor[keyof FormFieldActor] | ContentActor[keyof ContentActor];

export type WidgetActorMap = Record<Muid, WidgetActor>;

export type WidgetsLookupMap = {
  byId: Record<Widget['id'], Widget>;
  byGroupId: Record<WidgetHeader['group']['id'], Widget['id']>;
  byTaskTemplateId: Record<TaskTemplate['id'], Array<Widget['id']>>;
  all: Array<Widget>;
};

const emptyWidgetsLookup: WidgetsLookupMap = {
  byId: {},
  byGroupId: {},
  byTaskTemplateId: {},
  all: [],
};

export type TaskTemplatesLookupMap = {
  byId: Record<TaskTemplate['id'], TaskTemplate>;
  byGroupId: Record<TaskTemplate['id'], TaskTemplate['id']>;
  all: Array<TaskTemplate>;
};

const emptyTaskTemplatesLookupMap: TaskTemplatesLookupMap = {
  byId: {},
  byGroupId: {},
  all: [],
};

type Context = {
  publishedWidgets?: Widget[];
  publishedTaskTemplates?: TaskTemplate[];
  widgetsAtDragStart?: Widget[];
  taskTemplatesAtDragStart?: TaskTemplate[];
  isReadOnly: boolean;

  // queries
  templateQuery?: QueryActor<GetTemplateQueryResponse>;
  templateRevisionsQuery?: QueryActor<GetNewestTemplateRevisionsByTemplateIdQueryResponse>;
  widgetsByTemplateRevisionIdQuery?: QueryActor<WidgetsLookupMap>;
  taskTemplatesByTemplateRevisionIdQuery?: QueryActor<TaskTemplatesLookupMap>;
  generatedTaskTemplatesByTemplateRevisionIdQuery?: QueryActor<TaskTemplatesByTemplateRevisionIdQueryResponse>;
  infoQuery?: QueryActor<TaskTemplatesByTemplateRevisionIdQueryResponse>;
  templateTaskAssignmentsQuery?: QueryActor<GetTemplateTaskAssignmentsByTemplateRevisionIdResult>;
  organizationMembershipsQuery?: QueryActor<OrganizationMembershipsQueryResult>;
  taskAssignmentRulesQuery?: QueryActor<GetTaskAssignmentRulesByTemplateRevisionIdQuery.Response>;
  groupsQuery?: QueryActor<GetAllGroupsResponse>;
  dueDateRulesQuery?: QueryActor<GetDueDateRulesByTemplateRevisionIdQuery.Response>;
  rulesQuery?: QueryActor<GetAllRulesByTemplateRevisionIdQuery.Response>;
  approvalRulesQuery?: QueryActor<GetApprovalRulesByTaskTemplateIdQuery.Response>;

  // actors
  widgetActorMap: WidgetActorMap;
  uiActorRef: UIActorRef;
  topBarActorRef: TopBarActorRef;
  taskTemplateListActorRef: TaskTemplateListActorRef;
  aiWorkflowGenerationActorRef: AiWorkflowGenerationMachineActorRef;
};

export type EditorEvent =
  | {
      type: 'BULK_CREATE_TASK_FROM_PASTE';
      currentTaskTemplate: Partial<TaskTemplate>;
      at: number;
      templateNameList: string[];
    }
  | { type: 'CONTINUE'; formName: string }
  | { type: 'CREATE_TASK_TEMPLATE'; at?: number; taskTemplate?: Partial<TaskTemplate> }
  | ({ type: 'CREATE_WIDGET'; payload: NewWidgetItem } & ({ before: Widget } | { after: Widget } | {}))
  | { type: 'DELETE_TASK_TEMPLATE'; taskTemplateId: Muid }
  | { type: 'DUPLICATE_TASK_TEMPLATE'; taskTemplateId: Muid }
  | { type: 'DUPLICATE_WIDGET'; widget: Widget }
  | { type: 'MOUSE_UP' }
  | { type: 'MOVE_TASK_TEMPLATE'; taskTemplate: TaskTemplate; direction: 'before' | 'after'; toIndex: number }
  | { type: 'MOVE_WIDGET'; widget: Widget; direction: 'up' | 'down' }
  | { type: 'MOVE_WIDGET_TO_STEP'; widget: Widget; from: TaskTemplate; to: TaskTemplate }
  | { type: 'REORDER_WIDGETS'; widgets: Widget[] }
  | { type: 'REORDER_WIDGETS_DRAG_START' }
  | { type: 'REORDER_TASK_TEMPLATES'; taskTemplates: TaskTemplate[] }
  | { type: 'REORDER_TASK_TEMPLATES_DRAG_START' }
  | { type: 'UPDATE_GENERATED_TASK_TEMPLATE'; taskTemplate: TaskTemplate }
  | { type: 'UPDATE_GENERATED_WIDGET'; widget: Widget }
  | { type: 'UPDATE_MERGE_TAGS_REFERENCES'; widget: Widget; oldKey?: string }
  | { type: 'UPDATE_TASK_TEMPLATE'; taskTemplateId: Muid; taskTemplate: Partial<TaskTemplate> }
  | TopBarParentEvent
  | DeleteTaskTemplateMachineParentEvent
  // Queries
  | SystemUpdateEvent<
      | { id: 'template-query'; data: GetTemplateQueryResponse }
      | { id: 'template-revisions-query'; data: GetNewestTemplateRevisionsByTemplateIdQueryResponse }
      | { id: 'task-templates-query'; data: TaskTemplatesLookupMap }
      | { id: 'widgets-query'; data: WidgetsLookupMap }
      | {
          id: 'template-revision-task-templates-assignments-query';
          data: GetTemplateTaskAssignmentsByTemplateRevisionIdResult;
        }
      | {
          id: 'task-assignment-rules-by-template-revision-id-query';
          data: GetTaskAssignmentRulesByTemplateRevisionIdQuery.Response;
        }
      | { id: 'groups-query'; data: GetAllGroupsResponse }
      | { id: 'due-date-rules-query'; data: GetDueDateRulesByTemplateRevisionIdQuery.Response }
      | { id: 'rules-query'; data: GetAllRulesByTemplateRevisionIdQuery.Response }
      | { id: 'approval-rules-query'; data: GetApprovalRulesByTaskTemplateIdQuery.Response }
    >
  // Internal type
  | { type: 'done.invoke.createDraftRevision'; data: TemplateRevision }
  | { type: 'done.invoke.createTaskTemplate'; data: TaskTemplate }
  | { type: 'done.invoke.createWidget'; data: Widget }
  | { type: 'done.invoke.publishTemplate'; data: TemplateRevision };

export type CreateWidgetEvent = Extract<EditorEvent, { type: 'CREATE_WIDGET' }>;

const widgetOrderTreeUpdater = createOrderTreeBulkUpdater<Widget>({
  getOrderTree: widget => widget.header.orderTree!,
  // @ts-expect-error -- TODO
  setOrderTree: (widget, orderTree) => {
    return {
      ...widget,
      header: {
        ...widget.header,
        orderTree,
      },
    };
  },
});

export const makeFormEditorPageMachine = ({
  sharedContext,
  isReadOnly = false,
}: {
  sharedContext: SharedContext;
  isReadOnly?: boolean;
}) => {
  const { queryClient, templateId, dispatch: reduxDispatch } = sharedContext;

  const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
    queryClient,
    templateId,
  });

  return createMachine(
    {
      id: 'formEditorPage',
      initial: 'loading',
      predictableActionArguments: true,
      schema: {
        events: {} as EditorEvent,
        context: {} as Context,
      },
      tsTypes: {} as import('./form-editor-page-machine.typegen').Typegen0,
      context: () =>
        ({
          uiActorRef: spawn(makeUIMachine({ sharedContext }), { name: 'ui-actor' }),
          topBarActorRef: spawn(makeTopBarMachine({ sharedContext }), { name: 'top-bar-actor' }),
          aiWorkflowGenerationActorRef: spawn(makeAiWorkflowGenerationMachine({ sharedContext })),
          taskTemplateListActorRef: spawn(
            makeTaskTemplateListMachine({ sharedContext, taskTemplates: [], isReadOnly }),
          ),
          publishedTaskTemplates: undefined,
          publishedWidgets: undefined,
          widgetsAtDragStart: undefined,
          widgetActorMap: {},
          taskTemplatesAtDragStart: undefined,
          widgetsLookupMap: {
            byId: {},
            byGroupId: {},
            byTaskTemplateId: {},
          },
          taskTemplatesMap: {},
          isReadOnly,
        } as Context),

      on: {
        'xstate.update': [
          {
            cond: 'isTaskTemplatesUpdate',
            actions: ['sendTaskTemplatesToTaskTemplateListActor'],
          },
          { actions: ['syncReduxState'] },
        ],
      },

      states: {
        loading: {
          initial: 'fetching',
          entry: ['assignTemplateQuery', 'assignTemplateRevisionsQuery', 'assignGroupsQuery'],
          on: {
            'xstate.update': [
              { cond: 'draftDoesNotExist', target: '.creatingDraft' },
              {
                cond: 'draftTemplateRevisionIdReady',
                actions: [
                  'assignTasksQuery',
                  'assignGeneratedTasksQuery',
                  'assignWidgetsQuery',
                  'assignTemplateTaskAssignmentsQuery',
                  'assignOrganizationMembershipsQuery',
                  'assignTaskAssignmentRulesQuery',
                  'assignDueDateRulesQuery',
                  'assignRulesQuery',
                  'assignApprovalRulesQuery',
                ],
              },
              {
                cond: 'allDataReadyAndNotGenerating',
                actions: ['assignWidgetActors'],
                target: isReadOnly ? '#viewing' : '#editing',
              },
            ],
          },
          states: {
            fetching: {},
            creatingDraft: {
              invoke: [
                {
                  id: 'createDraftRevision',
                  src: 'createDraftRevisionMutation',
                  onDone: { target: 'fetching' },
                },
              ],
            },
          },
        },
        viewing: {
          id: 'viewing',
        },
        editing: {
          id: 'editing',
          initial: 'determiningIfNew',
          on: {
            'xstate.update': [{ cond: 'isWidgetSuccessAndNotGenerating', actions: ['assignWidgetActors'] }],
            'PUBLISH': 'publishing',
          },
          states: {
            determiningIfNew: {
              always: [{ cond: 'isBrandNewForm', target: 'new' }, { target: 'idle' }],
            },
            new: {
              initial: 'idle',
              states: {
                idle: {
                  entry: ['sendOpenModalToUiActor'],
                  on: { CONTINUE: 'updatingName' },
                  invoke: {
                    id: 'createFirstWidgetMutation',
                    src: 'createWidgetMutation',
                  },
                },
                updatingName: {
                  invoke: {
                    id: 'updateTemplateMutation',
                    src: 'updateTemplateMutation',
                    onDone: { target: '#idle', actions: ['sendCloseModalToUiActor', 'sendAutoFocusToFirstWidget'] },
                  },
                },
              },
            },
            idle: {
              id: 'idle',
              on: {
                BULK_CREATE_TASK_FROM_PASTE: 'bulkCreateTaskFromPaste',
                CREATE_TASK_TEMPLATE: 'creatingTaskTemplate',
                CREATE_WIDGET: 'addingWidget',
                DELETE_TASK_TEMPLATE: [
                  { cond: 'canDeleteTaskTemplate', target: 'deletingTaskTemplate' },
                  { target: 'idle' },
                ],
                DUPLICATE_TASK_TEMPLATE: 'duplicatingTaskTemplate',
                DUPLICATE_WIDGET: 'duplicatingWidget',
                MOVE_TASK_TEMPLATE: 'movingTaskTemplate',
                MOVE_WIDGET: 'movingWidget',
                MOVE_WIDGET_TO_STEP: 'movingWidgetToStep',
                REORDER_TASK_TEMPLATES_DRAG_START: {
                  target: 'reorderingTaskTemplates',
                  actions: ['assignTaskTemplatesAtDragStart'],
                },
                REORDER_WIDGETS_DRAG_START: {
                  target: 'reorderingWidgets',
                  actions: ['assignWidgetsAtDragStart'],
                },
                UPDATE_GENERATED_TASK_TEMPLATE: {
                  actions: 'updateGeneratedTaskTemplates',
                },
                UPDATE_GENERATED_WIDGET: {
                  actions: [
                    'assignGeneratedWidgetActor',
                    'updateGeneratedWidgetLabel',
                    'updateGeneratedWidgetConfig',
                    'sendScrollIntoView',
                  ],
                },
                UPDATE_MERGE_TAGS_REFERENCES: 'updatingMergeTags',
                UPDATE_TASK_TEMPLATE: 'updatingTaskTemplate',
              },
            },
            addingWidget: {
              invoke: [
                {
                  id: 'createWidget',
                  src: 'createWidgetMutation',
                  onDone: {
                    target: 'idle',
                    actions: ['sendWidgetAddedToUIActor', 'sendAutoFocus', 'sendScrollIntoView'],
                  },
                  onError: {
                    target: 'idle',
                  },
                },
              ],
            },
            bulkCreateTaskFromPaste: {
              invoke: [
                {
                  id: 'bulkCreateTask',
                  src: 'bulkCreateTaskFromPasteMutation',
                  onDone: { target: 'idle' },
                  onError: { target: 'idle' },
                },
              ],
            },
            movingWidget: {
              invoke: [
                {
                  id: 'moveWidget',
                  src: 'moveWidgetMutation',
                  onDone: {
                    target: 'idle',
                  },
                  onError: {
                    target: 'idle',
                  },
                },
              ],
            },
            duplicatingWidget: {
              invoke: [
                {
                  id: 'duplicateWidget',
                  src: 'duplicateWidgetMutation',
                  onDone: {
                    target: 'idle',
                  },
                  onError: {
                    target: 'idle',
                  },
                },
              ],
            },
            reorderingWidgets: {
              initial: 'init',
              states: {
                init: {
                  on: {
                    REORDER_WIDGETS: { target: 'reordered', actions: ['optimisticallyReorderWidgets'] },
                    MOUSE_UP: 'done',
                  },
                },
                reordered: {
                  on: {
                    REORDER_WIDGETS: { actions: ['optimisticallyReorderWidgets'] },
                    MOUSE_UP: 'persisting',
                  },
                },
                persisting: {
                  invoke: {
                    id: 'reorderWidgets',
                    src: 'reorderWidgetsMutation',
                    onDone: 'done',
                    onError: { target: 'done', actions: [console.error, 'openReorderErrorToast'] },
                  },
                },
                done: { type: 'final', entry: ['unassignWidgetsAtDragStart'] },
              },
              onDone: 'idle',
            },
            reorderingTaskTemplates: {
              initial: 'init',
              states: {
                init: {
                  on: {
                    REORDER_TASK_TEMPLATES: { target: 'reordered', actions: ['optimisticallyReorderTaskTemplates'] },
                    MOUSE_UP: 'done',
                  },
                },
                reordered: {
                  on: {
                    REORDER_TASK_TEMPLATES: { actions: ['optimisticallyReorderTaskTemplates'] },
                    MOUSE_UP: 'persisting',
                  },
                },
                persisting: {
                  invoke: {
                    id: 'reorderTaskTemplates',
                    src: 'reorderTaskTemplatesMutation',
                    onDone: 'done',
                    onError: { target: 'done', actions: [console.error, 'openReorderErrorToast'] },
                  },
                },
                done: { type: 'final', entry: ['unassignTaskTemplatesAtDragStart'] },
              },
              onDone: 'idle',
            },
            movingTaskTemplate: {
              invoke: {
                id: 'moveTaskTemplate',
                src: 'moveTaskTemplateMutation',
                onDone: 'idle',
                onError: { target: 'idle' },
              },
            },
            creatingTaskTemplate: {
              invoke: [
                {
                  id: 'createTaskTemplate',
                  src: 'createTaskTemplateMutation',
                  onDone: { target: 'idle' },
                  onError: { target: 'idle' },
                },
              ],
            },
            deletingTaskTemplate: {
              invoke: {
                id: 'deleteTaskTemplateMachine',
                src: 'deleteTaskTemplateMachine',
                onDone: 'idle',
              },
              initial: 'init',
              on: {
                CANCEL_DELETE_TASK_TEMPLATE: { actions: 'forwardToDeleteTaskTemplate' },
                CONFIRM_DELETE_TASK_TEMPLATE: { actions: 'forwardToDeleteTaskTemplate' },
              },
              states: {
                init: {
                  on: { PROMPT_TASK_TEMPLATE_DELETE: 'prompt', DELETING_TASK_TEMPLATE: 'deleting' },
                },
                prompt: {
                  on: { DELETING_TASK_TEMPLATE: 'deleting' },
                },
                deleting: {},
              },
            },
            updatingTaskTemplate: {
              invoke: [
                {
                  id: 'updateTaskTemplate',
                  src: 'updateTaskTemplateMutation',
                  onDone: { target: 'idle' },
                  onError: { target: 'idle' },
                },
              ],
            },
            duplicatingTaskTemplate: {
              invoke: [
                {
                  id: 'duplicateTaskTemplate',
                  src: 'duplicateTaskTemplateMutation',
                  onDone: { target: 'idle' },
                  onError: { target: 'idle' },
                },
              ],
            },
            movingWidgetToStep: {
              invoke: [
                {
                  id: 'moveWidgetToStep',
                  src: 'moveWidgetToStepMutation',
                  onDone: {
                    target: 'idle',
                  },
                  onError: {
                    target: 'idle',
                  },
                },
              ],
            },
            updatingMergeTags: {
              invoke: [
                {
                  id: 'updateMergeTags',
                  src: 'updateMergeTagsMutation',
                  onDone: {
                    target: 'idle',
                  },
                  onError: {
                    target: 'idle',
                  },
                },
              ],
            },
            updatingGeneratedWidget: {
              on: {
                UPDATE_GENERATED_WIDGET: [
                  {
                    target: 'idle',
                    actions: [
                      'assignGeneratedWidgetActor',
                      'updateGeneratedWidgetLabel',
                      'updateGeneratedWidgetConfig',
                    ],
                  },
                ],
              },
            },
          },
        },
        publishing: {
          initial: 'init',
          states: {
            init: {
              on: {
                PUBLISH_SUCCESS: { actions: ['assignPublishedWidgetsAndTaskTemplates', 'assignBlankQueries'] },
                NEW_DRAFT_CREATED: {
                  target: 'refreshing',
                  actions: [
                    'assignWidgetsQuery',
                    'assignTasksQuery',
                    'assignGeneratedTasksQuery',
                    'assignTemplateTaskAssignmentsQuery',
                    'assignOrganizationMembershipsQuery',
                    'assignTaskAssignmentRulesQuery',
                    'assignDueDateRulesQuery',
                    'assignRulesQuery',
                    'assignApprovalRulesQuery',
                  ],
                },
              },
            },
            refreshing: {
              on: {
                'xstate.update': {
                  cond: 'allDataReadyAndNotGenerating',
                  actions: ['assignWidgetActors'],
                  target: 'done',
                },
              },
            },
            done: { type: 'final' },
          },
          onDone: { target: 'editing', actions: ['sendOpenShareMenuToUiActor'] },
        },
      },
    },
    {
      actions: {
        assignGeneratedWidgetActor: assign({
          widgetActorMap: (ctx, evt) => {
            const { widget } = evt;
            const existingActor = ctx.widgetActorMap[widget.header.id];
            if (existingActor) {
              return ctx.widgetActorMap;
            }
            const template = QueryActorSelectors.getQueryData(ctx.templateQuery);
            if (!template) {
              throw new Error('missing template');
            }
            const actor = spawnWidgetMachine(widget, template, sharedContext, ctx.isReadOnly);

            if (actor) {
              queryClient.setQueryData<Widget[]>(
                WidgetsByTemplateRevisionIdQuery.getKey(getTemplateRevision(ctx)?.id),
                current => {
                  if (!current) return [{ ...widget, label: '' }];
                  return [...current, { ...widget, label: '' }];
                },
              );
              return {
                ...ctx.widgetActorMap,
                [widget.header.id]: actor,
              };
            }

            return ctx.widgetActorMap;
          },
        }),

        updateGeneratedWidgetLabel: send(
          (_ctx, evt) => ({ type: 'SET_WIDGET_LABEL', label: isFormFieldWidget(evt.widget) ? evt.widget.label : '' }),
          {
            to: (ctx, evt) => ctx.widgetActorMap[evt.widget.header.id],
          },
        ),
        updateGeneratedWidgetConfig: send(
          (ctx, evt) => {
            if (isFormFieldWidget(evt.widget) && evt.widget.fieldType === FieldType.MultiSelect) {
              queryClient.setQueryData<Widget[]>(
                WidgetsByTemplateRevisionIdQuery.getKey(getTemplateRevision(ctx)?.id),
                current => {
                  if (!current) {
                    return [{ ...evt.widget }];
                  }
                  return current.map(w => (w.header.id === evt.widget.header.id ? evt.widget : w));
                },
              );
            }
            return {
              type: 'SET_WIDGET_CONFIG',
              config: isFormFieldWidget(evt.widget) ? evt.widget.config : {},
            };
          },
          {
            to: (ctx, evt) => ctx.widgetActorMap[evt.widget.header.id],
          },
        ),
        sendScrollIntoView: sendTo(
          (ctx, evt) => {
            const { widget } = match(evt)
              .with({ type: 'UPDATE_GENERATED_WIDGET' }, e => ({ widget: e.widget }))
              .with({ type: 'done.invoke.createWidget' }, e => ({ widget: e.data }))
              .otherwise(() => ({ widget: undefined }));

            return widget ? ctx.widgetActorMap[widget.header.id] : ctx.widgetActorMap[0];
          },
          { type: 'SCROLL_INTO_VIEW' },
          { delay: 200 },
        ),
        updateGeneratedTaskTemplates: (ctx, evt) => {
          const templateRevisionId = getTemplateRevision(ctx)?.id;
          if (!templateRevisionId) throw new Error('draft template revision not found');

          queryClient.setQueryData<TaskTemplate[]>(
            TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
            current => {
              if (!current || current.length === 0) return [{ ...evt.taskTemplate }];
              const exists = current.some(t => t.id === evt.taskTemplate.id);
              return exists
                ? current.map(t => (t.id === evt.taskTemplate.id ? evt.taskTemplate : t))
                : current.concat(evt.taskTemplate);
            },
          );
        },
        assignTemplateQuery: assign({
          templateQuery: (_ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: makeGetTemplateQueryObserver({ templateId, queryClient, options: RefetchOnMountOnly }),
              }),
              { sync: true, name: 'template-query' },
            );
          },
        }),
        assignTemplateRevisionsQuery: assign({
          templateRevisionsQuery: (_ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: makeGetNewestTemplateRevisionsByTemplateIdQueryObserver({
                  templateId,
                  queryClient,
                  options: RefetchOnMountOnly,
                }),
              }),
              { sync: true, name: 'template-revisions-query' },
            );
          },
        }),
        assignWidgetsQuery: assign({
          widgetsByTemplateRevisionIdQuery: (ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: makeGetWidgetsByTemplateRevisionIdObserver({
                  queryClient,
                  templateRevisionId: getTemplateRevision(ctx)?.id,
                  options: { ...RefetchOnMountOnly, keepPreviousData: true, select: createWidgetsLookup },
                }),
              }),
              { sync: true, name: 'widgets-query' },
            );
          },
        }),
        assignGroupsQuery: assign({
          groupsQuery: (_ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: makeGetAllGroupsQueryObserver({
                  queryClient,
                  options: { ...RefetchOnMountOnly, keepPreviousData: true },
                }),
              }),
              { sync: true, name: 'groups-query' },
            );
          },
        }),
        assignTasksQuery: assign({
          taskTemplatesByTemplateRevisionIdQuery: (ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: makeGetTaskTemplatesByTemplateRevisionIdQueryObserver({
                  queryClient,
                  templateRevisionId: getTemplateRevision(ctx)?.id,
                  options: { ...RefetchOnMountOnly, select: createTaskTemplatesLookup },
                }),
              }),
              { sync: true, name: 'task-templates-query' },
            );
          },
        }),
        assignGeneratedTasksQuery: assign({
          generatedTaskTemplatesByTemplateRevisionIdQuery: (ctx, _evt) => {
            const templateRevisionId = getTemplateRevision(ctx)?.id;
            if (!templateRevisionId) throw new Error('template revision not found');
            return spawn(
              makeQueryMachine({
                observer: makeGetTaskTemplatesByTemplateRevisionIdQueryObserver({
                  queryClient,
                  templateRevisionId,
                  options: {
                    ...RefetchOnMountOnly,
                    queryKey: ['generated', TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId })],
                  },
                }),
              }),
            );
          },
        }),
        assignTemplateTaskAssignmentsQuery: assign({
          templateTaskAssignmentsQuery: (ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: makeGetAllTaskTemplatesAssignmentsByTemplateRevisionIdQuery({
                  queryClient,
                  templateRevisionId: getTemplateRevision(ctx)?.id,
                  options: RefetchOnMountOnly,
                }),
              }),
              { sync: true, name: 'template-revision-task-templates-assignments-query' },
            );
          },
        }),
        assignOrganizationMembershipsQuery: assign({
          organizationMembershipsQuery: (ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: makeGetAllOrganizationMembershipsQueryObserver({
                  queryClient,
                  organizationId: QueryActorSelectors.getQueryData(ctx.templateQuery)?.organization.id,
                  options: RefetchOnMountOnly,
                }),
              }),
              { sync: true, name: 'organization-memberships-query' },
            );
          },
        }),
        assignTaskAssignmentRulesQuery: assign({
          taskAssignmentRulesQuery: (ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: GetTaskAssignmentRulesByTemplateRevisionIdQuery.makeQueryObserver({
                  queryClient,
                  templateRevisionId: getTemplateRevision(ctx)?.id,
                  options: RefetchOnMountOnly,
                }),
              }),
              { sync: true, name: 'task-assignment-rules-by-template-revision-id-query' },
            );
          },
        }),
        assignDueDateRulesQuery: assign({
          dueDateRulesQuery: (ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: GetDueDateRulesByTemplateRevisionIdQuery.makeQueryObserver({
                  queryClient,
                  options: RefetchOnMountOnly,
                  templateRevisionId: getTemplateRevision(ctx)?.id,
                }),
              }),
              { sync: true, name: 'due-date-rules-query' },
            );
          },
        }),
        assignRulesQuery: assign({
          rulesQuery: (ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: GetAllRulesByTemplateRevisionIdQuery.makeQueryObserver({
                  queryClient,
                  options: RefetchOnMountOnly,
                  templateRevisionId: getTemplateRevision(ctx)?.id,
                }),
              }),
              { sync: true, name: 'rules-query' },
            );
          },
        }),
        assignApprovalRulesQuery: assign({
          approvalRulesQuery: (ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: GetApprovalRulesByTaskTemplateIdQuery.makeQueryObserver({
                  queryClient,
                  options: RefetchOnMountOnly,
                  templateRevisionId: getTemplateRevision(ctx)?.id,
                }),
              }),
              { sync: true, name: 'approval-rules-query' },
            );
          },
        }),
        assignWidgetActors: assign({
          widgetActorMap: (context, _evt) => {
            const data = QueryActorSelectors.getQueryData(context.widgetsByTemplateRevisionIdQuery)?.all ?? [];
            const template = QueryActorSelectors.getQueryData(context.templateQuery);
            if (!template) {
              throw new Error('missing template');
            }
            return data.reduce<WidgetActorMap>((acc, widget) => {
              const existingActor = context.widgetActorMap?.[widget.header.id];
              if (existingActor) {
                acc[widget.header.id] = existingActor;
                return acc;
              }

              const actor = spawnWidgetMachine(widget, template, sharedContext, context.isReadOnly);

              if (actor) {
                acc[widget.header.id] = actor;
              }

              return acc;
            }, {});
          },
        }),

        sendAutoFocus: send(
          { type: 'AUTO_FOCUS' },
          {
            to: (context, event) => {
              return context.widgetActorMap?.[event.data.header.id]!;
            },
          },
        ),

        sendAutoFocusToFirstWidget: sendTo(
          (context, _event) => {
            return Object.values(context.widgetActorMap!)[0]!;
          },
          { type: 'AUTO_FOCUS' },
          // there is a race condition with the modal closing causing a call stack overflow
          { delay: 100 },
        ),

        assignBlankQueries: assign({
          taskTemplatesByTemplateRevisionIdQuery: (_ctx, _evt) => undefined,
          widgetsByTemplateRevisionIdQuery: (_ctx, _evt) => undefined,
        }),

        assignPublishedWidgetsAndTaskTemplates: assign({
          publishedWidgets: (context, _evt) => {
            return QueryActorSelectors.getQueryData(context.widgetsByTemplateRevisionIdQuery)?.all;
          },
          publishedTaskTemplates: (context, _evt) => {
            return QueryActorSelectors.getQueryData(context.taskTemplatesByTemplateRevisionIdQuery)?.all;
          },
        }),

        sendWidgetAddedToUIActor: sendTo(ctx => ctx.uiActorRef, { type: 'WIDGET_ADDED' }),

        sendOpenModalToUiActor: sendTo(ctx => ctx.uiActorRef, {
          type: 'OPEN_MODAL',
          modal: React.createElement(NewFormNameModalContent),
          modalProps: { isCentered: true, closeOnEsc: false, closeOnOverlayClick: false },
          overlayProps: { backdropFilter: 'blur(8px)' },
        }),

        sendCloseModalToUiActor: sendTo(ctx => ctx.uiActorRef, { type: 'CLOSE_MODAL' }),

        optimisticallyReorderWidgets: (ctx, { widgets }) => {
          queryClient.setQueryData(
            WidgetsByTemplateRevisionIdQuery.getKey(getTemplateRevision(ctx)?.id),
            () => widgets,
          );
        },

        optimisticallyReorderTaskTemplates: (ctx, { taskTemplates }) => {
          queryClient.setQueryData(
            TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId: getTemplateRevision(ctx)?.id }),
            () => taskTemplates,
          );
        },

        assignWidgetsAtDragStart: assign({
          widgetsAtDragStart: (ctx, _evt) => {
            const taskTemplateGroupId = sharedContext.$state.params.groupId;
            const taskTemplate = QueryActorSelectors.getQueryData(
              ctx.taskTemplatesByTemplateRevisionIdQuery,
            )?.all?.find(taskTemplate => taskTemplate.group.id === taskTemplateGroupId);
            const widgets =
              QueryActorSelectors.getQueryData(ctx.widgetsByTemplateRevisionIdQuery)?.all?.filter(
                w => w.header.taskTemplate.id === taskTemplate?.id,
              ) ?? [];
            return widgets;
          },
        }),

        unassignWidgetsAtDragStart: assign({
          widgetsAtDragStart: (_ctx, _evt) => undefined,
        }),

        assignTaskTemplatesAtDragStart: assign({
          taskTemplatesAtDragStart: (ctx, _evt) =>
            QueryActorSelectors.getQueryData(ctx.taskTemplatesByTemplateRevisionIdQuery)?.all ?? [],
        }),

        unassignTaskTemplatesAtDragStart: assign({
          taskTemplatesAtDragStart: () => undefined,
        }),

        openReorderErrorToast: () => {
          ToastServiceImpl.openToast({
            status: 'error',
            title: "We're having problems reordering",
          });
        },

        forwardToDeleteTaskTemplate: forwardTo('deleteTaskTemplateMachine') as ActionObject<Context, EditorEvent>,

        sendOpenShareMenuToUiActor: sendTo(ctx => ctx.uiActorRef, { type: 'OPEN_DISCLOSURE', name: 'shareMenu' }),

        sendTaskTemplatesToTaskTemplateListActor: sendTo(
          ctx => ctx.taskTemplateListActorRef,
          ctx => {
            return {
              type: 'SET_TASK_TEMPLATES',
              taskTemplates: QueryActorSelectors.getQueryData(ctx.taskTemplatesByTemplateRevisionIdQuery)?.all ?? [],
            };
          },
        ),

        syncReduxState: (ctx, evt) => {
          const templateRevisionId = getTemplateRevision(ctx)?.id;
          if (evt.type !== 'xstate.update' || !templateRevisionId) return;

          match(evt)
            .with({ id: 'widgets-query' }, ({ state }) => {
              const widgets = state.context.data?.all ?? [];

              reduxDispatch({
                type: toSuccess(WIDGET_GET_ALL_BY_TEMPLATE_REVISION_ID),
                payload: widgets,
                meta: { templateRevisionId, flushCache: true },
              });
              reduxDispatch({
                type: WIDGET_GET_ALL_BY_TEMPLATE_REVISION_ID,
                payload: widgets,
                meta: { templateRevisionId, flushCache: true },
              });
            })
            .with({ id: 'template-query' }, ({ state }) => {
              const template = state.context.data;

              if (template) {
                reduxDispatch({
                  type: toSuccess(TEMPLATE_GET_BY_ID),
                  payload: template,
                  meta: { templateId: template.id },
                });
              }
            })
            .with({ id: 'task-templates-query' }, ({ state }) => {
              const taskTemplates = state.context.data?.all ?? [];

              reduxDispatch({
                type: TASK_TEMPLATE_GET_ALL_BY_TEMPLATE_REVISION_ID,
                payload: taskTemplates,
                meta: { templateRevisionId, flushCache: false },
              });
            })
            .with({ id: 'due-date-rules-query' }, ({ state }) => {
              const dueDateRuleDefinitions = state.context.data ?? [];

              reduxDispatch({
                type: DYNAMIC_DUE_DATE_GET_ALL_BY_TEMPLATE_REVISION,
                payload: dueDateRuleDefinitions,
                meta: { templateRevisionId },
              });
            })
            .otherwise(() => {});
        },
      },
      services: {
        createDraftRevisionMutation: async context => {
          const draftRevision = QueryActorSelectors.getQueryData(context.templateRevisionsQuery)?.find(
            revision => revision.status === TemplateRevisionStatus.Draft,
          );

          if (draftRevision) return draftRevision;
          return makeMutation<TemplateRevision>(queryClient, {
            mutationKey: CreateTemplateRevisionMutation.key,
            mutationFn: () => CreateTemplateRevisionMutation.mutationFn({ templateId }),
            onSuccess: data => {
              queryClient.setQueryData<GetNewestTemplateRevisionsByTemplateIdQueryResponse>(
                GetNewestTemplateRevisionsByTemplateIdQuery.getKey({ templateId }),
                current => [current![0], data],
              );
            },
          }).execute();
        },
        createWidgetMutation: async (context, event) => {
          const taskTemplates =
            QueryActorSelectors.getQueryData(context.taskTemplatesByTemplateRevisionIdQuery)?.all ?? [];
          const taskTemplateGroupId = sharedContext.$state.params.groupId;
          const taskTemplate = taskTemplates.find(tt => tt.group.id === taskTemplateGroupId) ?? taskTemplates[0];
          const allWidgets = QueryActorSelectors.getQueryData(context.widgetsByTemplateRevisionIdQuery)?.all ?? [];
          const defaultGroupId =
            QueryActorSelectors.getQueryData(context.groupsQuery)?.find(
              g => (g.user as User).username === ALL_MEMBERS_GROUP_USERNAME,
            )?.id ?? MuidUtils.randomMuid();
          const safeEvent: CreateWidgetEvent =
            event.type === ''
              ? {
                  type: 'CREATE_WIDGET',
                  payload: { fieldType: FieldType.Text, label: 'Your question here' },
                }
              : event;

          if (!taskTemplate) {
            throw new Error('task template is missing');
          }

          const widgetRequest = makeWidgetRequest({
            event: safeEvent,
            taskTemplateId: taskTemplate.id,
            allWidgets,
            defaultGroupId,
          });

          return makeMutation(queryClient, {
            mutationFn: () => CreateWidgetMutation.mutationFn(widgetRequest),
            mutationKey: CreateWidgetMutation.getKey(),
            onSuccess: newWidget => {
              queryClient.setQueryData<Widget[]>(
                WidgetsByTemplateRevisionIdQuery.getKey(getTemplateRevision(context)?.id),
                current => {
                  if (!current) return [newWidget];
                  return [...current, newWidget].sort(widgetComparer);
                },
              );
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
          }).execute();
        },

        bulkCreateTaskFromPasteMutation: async (context, event) => {
          const template = QueryActorSelectors.getQueryData(context.templateQuery);
          const taskList = event.templateNameList;
          const { $state } = sharedContext;

          const templateRevisionId = getTemplateRevision(context)?.id!;
          const taskTemplatesLookup =
            QueryActorSelectors.getQueryData(context.taskTemplatesByTemplateRevisionIdQuery) ??
            emptyTaskTemplatesLookupMap;

          const optimisticTaskTemplates = makeOptimisticTaskTemplates({
            taskType: TaskTemplateTaskType.Standard,
            afterOrderTree: event.currentTaskTemplate.orderTree,
            templateRevisionId,
            taskTemplates: taskTemplatesLookup.all,
            taskNames: taskList,
          });
          const limit = pLimit(2);
          const templateMutations = optimisticTaskTemplates.map((newTaskTemplate, index) => {
            return limit(() =>
              makeMutation(queryClient, {
                mutationFn: () => CreateTaskTemplateMutation.mutationFn(newTaskTemplate),
                mutationKey: CreateTaskTemplateMutation.key,
                onSuccess: () => {
                  queryClient.invalidateQueries(TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }));
                  templateRevisionCacheSetter.updateDraftLastUpdatedDate();
                  if (index === taskList.length - 1) {
                    const targetRoute =
                      template?.templateType === TemplateType.Playbook ? 'templateV2.task' : 'form.step';
                    $state.go(targetRoute, { groupId: newTaskTemplate.group.id }, { inherit: true });
                  }
                },
                onError: () => {
                  ToastServiceImpl.openToast({
                    status: 'error',
                    title: "We're having problems creating that step",
                  });
                },
              }).execute(),
            );
          });

          return Promise.all(templateMutations);
        },

        updateTemplateMutation: async (context, event) => {
          const templateId = QueryActorSelectors.getQueryData(context.templateQuery)?.id;
          if (!templateId) {
            throw new Error('missing template');
          }

          return makeMutation(queryClient, {
            mutationFn: () => UpdateTemplateMutation.mutationFn(templateId, { name: event.formName }),
            mutationKey: UpdateTemplateMutation.getKey(templateId),
            onSuccess: updatedTemplate => {
              queryClient.setQueryData<Template>(GetTemplateQuery.getKey({ templateId }), () => updatedTemplate);
            },
          }).execute();
        },

        moveWidgetMutation: async (context, { widget, direction }) => {
          const widgets = QueryActorSelectors.getQueryData(context.widgetsByTemplateRevisionIdQuery)?.all ?? [];
          const widgetIndex = widgets.findIndex(w => w.header.id === widget.header.id);
          const move = widgetOrderTreeUpdater[direction === 'down' ? 'moveDown' : 'moveUp'];

          const sortedWidgets = move(widgetIndex, widgets);
          const affectedWidgets = [
            sortedWidgets[widgetIndex],
            sortedWidgets[direction === 'down' ? widgetIndex + 1 : widgetIndex - 1],
          ];
          const orderModels = affectedWidgets.map(w => ({
            widgetHeaderId: w.header.id,
            orderTree: w.header.orderTree!,
          }));

          queryClient.setQueryData<Widget[]>(
            WidgetsByTemplateRevisionIdQuery.getKey(getTemplateRevision(context)?.id),
            () => sortedWidgets,
          );

          return makeMutation(queryClient, {
            mutationFn: () => UpdateWidgetOrderTreesMutation.mutationFn({ orderModels }),
            mutationKey: UpdateWidgetOrderTreesMutation.getKey(),
            onSuccess: () => {
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
          }).execute();
        },
        duplicateWidgetMutation: async (context, { widget }) => {
          const widgets = QueryActorSelectors.getQueryData(context.widgetsByTemplateRevisionIdQuery)?.all ?? [];
          const taskWidgets = widgets.filter(w => w.header.taskTemplate.id === widget.header.taskTemplate.id);
          const orderTrees = taskWidgets.filter(w => Boolean(w.header.orderTree)).map(w => w.header.orderTree!);
          const [nextOrderTree] = orderTreeService.after(orderTrees, widget.header.orderTree!);
          const srcHeader = widget.header;

          const body = match<Widget, CopyWidgetOptions>(widget)
            .with({ header: { type: WidgetType.FormField } }, () => {
              const dstHeader = {
                id: MuidUtils.randomMuid(),
                taskTemplate: widget.header.taskTemplate,
                type: widget.header.type,
                orderTree: nextOrderTree,
              } as WidgetHeader;
              const keys = FormFieldHelpers.toKeys(widgets);
              const key = FormFieldHelpers.generateKeyForCopy(keys, (widget as FormFieldWidget).key);
              const labels = widgets.map(w => (w as FormFieldWidget).label ?? '');
              const label = FormFieldHelpers.generateNameLabelForCopy(labels, (widget as FormFieldWidget).label ?? '');
              return {
                srcHeader,
                dstHeader,
                key,
                label,
              };
            })
            .otherwise(() => {
              return {
                srcHeader,
                dstHeader: {
                  id: MuidUtils.randomMuid(),
                  taskTemplate: widget.header.taskTemplate,
                  type: widget.header.type,
                  orderTree: nextOrderTree,
                },
              };
            });

          return makeMutation(queryClient, {
            mutationFn: () => CopyWidgetByHeaderIdMutation.mutationFn(body),
            mutationKey: CopyWidgetByHeaderIdMutation.getKey(),
            onSuccess: () => {
              queryClient.refetchQueries(WidgetsByTemplateRevisionIdQuery.getKey(getTemplateRevision(context)?.id));
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
          }).execute();
        },

        reorderWidgetsMutation: async (context, _evt) => {
          const { widgetsAtDragStart } = context;
          if (!widgetsAtDragStart) return Promise.resolve();

          const reorderedWidgets =
            QueryActorSelectors.getQueryData(context.widgetsByTemplateRevisionIdQuery)?.all ?? [];

          const summary = orderTreeService.summarizeReorderedArray({
            old: widgetsAtDragStart,
            new: reorderedWidgets,
            getId: w => w?.id,
          });

          if (!summary) return Promise.resolve();
          const { originalIndex, newIndex, displacedItem: displacedWidget, movedItem: originalWidget } = summary;

          const orderTrees = widgetsAtDragStart.filter(w => Boolean(w.header.orderTree)).map(w => w.header.orderTree!);
          const newOrderTree = orderTreeService[newIndex < originalIndex ? 'before' : 'after'](
            orderTrees,
            displacedWidget.header.orderTree!,
          );

          return makeMutation(queryClient, {
            mutationFn: () =>
              UpdateWidgetOrderTreesMutation.mutationFn({
                orderModels: [{ orderTree: newOrderTree[0], widgetHeaderId: originalWidget.header.id }],
              }),
            mutationKey: UpdateWidgetOrderTreesMutation.getKey(),
            onSuccess: () => {
              queryClient.refetchQueries(WidgetsByTemplateRevisionIdQuery.getKey(getTemplateRevision(context)?.id));
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
            onError: () => {
              queryClient.setQueryData(
                WidgetsByTemplateRevisionIdQuery.getKey(getTemplateRevision(context)?.id),
                () => widgetsAtDragStart,
              );
            },
          }).execute();
        },

        reorderTaskTemplatesMutation: async (context, _evt) => {
          const { taskTemplatesAtDragStart } = context;

          if (!taskTemplatesAtDragStart) return Promise.resolve();

          const reorderedTaskTemplates =
            QueryActorSelectors.getQueryData(context.taskTemplatesByTemplateRevisionIdQuery)?.all ?? [];

          const summary = orderTreeService.summarizeReorderedArray({
            old: taskTemplatesAtDragStart,
            new: reorderedTaskTemplates,
            getId: tt => tt?.id,
          });

          if (!summary) return Promise.resolve();

          const {
            originalIndex,
            newIndex,
            displacedItem: displacedTaskTemplate,
            movedItem: originalTaskTemplate,
          } = summary;

          const orderTrees = taskTemplatesAtDragStart
            .filter(taskTemplate => Boolean(taskTemplate.orderTree))
            .map(taskTemplate => taskTemplate.orderTree!);
          const newOrderTree = orderTreeService[newIndex < originalIndex ? 'before' : 'after'](
            orderTrees,
            displacedTaskTemplate.orderTree!,
          );

          return makeMutation(queryClient, {
            variables: {
              orderModels: [
                {
                  taskTemplateId: originalTaskTemplate.id,
                  taskTemplateGroupId: originalTaskTemplate.group.id,
                  orderTree: newOrderTree[0],
                },
              ],
            },
            mutationFn: UpdateTaskTemplateOrderTreesMutation.mutationFn,
            mutationKey: UpdateTaskTemplateOrderTreesMutation.key,
            onSuccess: () => {
              queryClient.invalidateQueries(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({
                  templateRevisionId: originalTaskTemplate.templateRevision.id,
                }),
              );
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
            onError: () => {
              queryClient.setQueryData(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({
                  templateRevisionId: originalTaskTemplate.templateRevision.id,
                }),
                () => taskTemplatesAtDragStart,
              );
            },
          }).execute();
        },

        moveTaskTemplateMutation: async (context, evt) => {
          const { direction, taskTemplate, toIndex } = evt;

          const taskTemplatesLookup =
            QueryActorSelectors.getQueryData(context.taskTemplatesByTemplateRevisionIdQuery) ??
            emptyTaskTemplatesLookupMap;
          const orderTrees = toOrderTrees(taskTemplatesLookup.all);
          const referenceTree = taskTemplatesLookup.all[toIndex].orderTree;

          const [newOrderTree] = orderTreeService[direction](orderTrees, referenceTree);

          return makeMutation(queryClient, {
            variables: {
              orderModels: [
                {
                  orderTree: newOrderTree,
                  taskTemplateId: taskTemplate.id,
                  taskTemplateGroupId: taskTemplate.group.id,
                },
              ],
            },
            mutationFn: UpdateTaskTemplateOrderTreesMutation.mutationFn,
            mutationKey: UpdateTaskTemplateOrderTreesMutation.key,
            onSuccess: () => {
              queryClient.refetchQueries(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId: taskTemplate.templateRevision.id }),
              );
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
            onError: () => {
              queryClient.setQueryData(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({
                  templateRevisionId: taskTemplate.templateRevision.id,
                }),
                () => taskTemplatesLookup.all,
              );
            },
          }).execute();
        },
        createTaskTemplateMutation: (context, evt) => {
          const template = QueryActorSelectors.getQueryData(context.templateQuery);
          const taskTemplatesLookup =
            QueryActorSelectors.getQueryData(context.taskTemplatesByTemplateRevisionIdQuery) ??
            emptyTaskTemplatesLookupMap;
          const templateRevisionId = getTemplateRevision(context)?.id!;
          const newTaskTemplate = makeOptimisticTaskTemplate({
            taskType: TaskTemplateTaskType.Standard,
            atIndex: evt.at ?? taskTemplatesLookup.all.length - 1,
            templateRevisionId,
            taskTemplates: taskTemplatesLookup.all,
            ...evt.taskTemplate,
          });

          const { $state } = sharedContext;
          return makeMutation(queryClient, {
            mutationFn: () => CreateTaskTemplateMutation.mutationFn(newTaskTemplate),
            mutationKey: CreateTaskTemplateMutation.key,
            onMutate: () => {
              queryClient.setQueryData<Array<TaskTemplate | OptimisticTaskTemplate>>(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
                current => {
                  if (!current) return [newTaskTemplate];

                  if (!evt.at) return [...current, newTaskTemplate];

                  return current.flatMap((taskTemplate, index) => {
                    if (evt.at === index) return [taskTemplate, newTaskTemplate];

                    return taskTemplate;
                  });
                },
              );

              const targetRoute = template?.templateType === TemplateType.Playbook ? 'templateV2.task' : 'form.step';
              $state.go(targetRoute, { groupId: newTaskTemplate.group.id }, { inherit: true });
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
            onSuccess: newTaskTemplate => {
              queryClient.setQueryData<TaskTemplate[]>(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
                current => {
                  return (current || []).map(taskTemplate => {
                    // Replace optimistic task template with the one returned by the server
                    if (taskTemplate.id === newTaskTemplate.id) return newTaskTemplate;

                    return taskTemplate;
                  });
                },
              );
              if (newTaskTemplate.taskType === TaskTemplateTaskType.AI) {
                queryClient.invalidateQueries(GetAllNativeAutomationsQuery.getKey({ templateRevisionId }));
              }
            },
            onError: (_err, _vars) => {
              ToastServiceImpl.openToast({
                status: 'error',
                title: "We're having problems creating that step",
              });
            },
          }).execute();
        },

        updateTaskTemplateMutation: (ctx, evt) => {
          const { taskTemplateId, taskTemplate } = evt;
          const template = QueryActorSelectors.getQueryData(ctx.templateQuery);
          const templateRevisionId = getTemplateRevision(ctx)?.id!;

          return makeMutation(queryClient, {
            variables: { taskTemplateId, taskTemplate },
            mutationFn: UpdateTaskTemplateMutation.mutationFn,
            mutationKey: UpdateTaskTemplateMutation.key,
            onMutate: () => {
              const previousTaskTemplates =
                QueryActorSelectors.getQueryData(ctx.taskTemplatesByTemplateRevisionIdQuery)?.all ?? [];

              queryClient.setQueryData<TaskTemplatesByTemplateRevisionIdQueryResponse>(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
                (current = []) => current.map(t => (t.id === taskTemplateId ? { ...t, ...taskTemplate } : t)),
              );

              return previousTaskTemplates;
            },
            onSuccess: newTaskTemplate => {
              queryClient.invalidateQueries(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({
                  templateRevisionId: newTaskTemplate.taskTemplate.templateRevision.id,
                }),
              );
              queryClient.invalidateQueries(
                GetNewestTemplateRevisionsByTemplateIdQuery.getKey({
                  templateId: template?.id,
                }),
              );
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
            onError: (_err, _vars, previousTaskTemplates) => {
              ToastServiceImpl.openToast({
                status: 'error',
                title: "We're having problems updating that step",
              });

              queryClient.setQueryData<TaskTemplatesByTemplateRevisionIdQueryResponse>(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
                previousTaskTemplates ?? [],
              );
            },
          }).execute();
        },

        duplicateTaskTemplateMutation: (context, evt) => {
          const { taskTemplateId } = evt;
          const taskTemplatesLookup =
            QueryActorSelectors.getQueryData(context.taskTemplatesByTemplateRevisionIdQuery) ??
            emptyTaskTemplatesLookupMap;
          const index = taskTemplatesLookup.all.findIndex(tt => tt.id === taskTemplateId);

          const placeholderTaskTemplate = createDuplicateTaskTemplatePlaceholder({
            sourceTaskTemplate: taskTemplatesLookup.all[index],
            taskTemplates: taskTemplatesLookup.all,
            index,
          });

          const { $state } = sharedContext;
          return makeMutation(queryClient, {
            variables: {
              taskTemplateId,
              groupId: placeholderTaskTemplate.group.id,
              duplicateId: placeholderTaskTemplate.id,
              orderTree: placeholderTaskTemplate.orderTree,
            },
            mutationFn: DuplicateTaskTemplateMutation.mutationFn,
            mutationKey: DuplicateTaskTemplateMutation.key,
            onSuccess: async newTaskTemplate => {
              await queryClient.invalidateQueries(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({
                  templateRevisionId: getTemplateRevision(context)?.id!,
                }),
              );
              await queryClient.invalidateQueries(
                WidgetsByTemplateRevisionIdQuery.getKey(getTemplateRevision(context)?.id),
              );
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
              $state.go('form.step', { groupId: newTaskTemplate.group.id }, { inherit: true });
            },
            onError: (_err, _vars) => {
              ToastServiceImpl.openToast({
                status: 'error',
                title: "We're having problems duplicating that step",
              });
            },
          }).execute();
        },

        deleteTaskTemplateMachine: (_ctx, evt) => {
          const templateRevisionId = getTemplateRevision(_ctx)?.id;

          if (!templateRevisionId) throw new Error('`templateRevisionId` is missing');

          const widgetsLookup =
            QueryActorSelectors.getQueryData(_ctx.widgetsByTemplateRevisionIdQuery) ?? emptyWidgetsLookup;

          const widgetIdsForTaskTemplate = widgetsLookup.byTaskTemplateId[evt.taskTemplateId] ?? [];
          const widgetsForTaskTemplate = widgetIdsForTaskTemplate.map(widgetId => widgetsLookup.byId[widgetId]);

          return makeDeleteTaskTemplateMachine({
            sharedContext,
            taskTemplateId: evt.taskTemplateId,
            templateRevisionId,
            widgets: widgetsForTaskTemplate ?? [],
          });
        },

        moveWidgetToStepMutation: (_ctx, evt) => {
          const { widget, from, to } = evt;

          const widgetsLookup =
            QueryActorSelectors.getQueryData(_ctx.widgetsByTemplateRevisionIdQuery) ?? emptyWidgetsLookup;
          const targetTaskWidgets = widgetsLookup.all.filter(w => w.header.taskTemplate.id === to.id);

          const newOrderTree = match(targetTaskWidgets.length)
            .with(0, () => '1')
            .otherwise(() => {
              const targetTaskOrderTrees = targetTaskWidgets
                .filter(w => Boolean(w.header.orderTree))
                .map(w => w.header.orderTree!);
              const [newOrderTree] = orderTreeService.before(
                targetTaskOrderTrees,
                targetTaskWidgets[0].header.orderTree!,
              );
              return newOrderTree;
            });

          return makeMutation(queryClient, {
            variables: {
              headerId: widget.header.id,
              templateRevisionId: to.templateRevision.id,
              targetTaskTemplateId: to.id,
              targetWidgetOrderTree: newOrderTree,
            },
            mutationFn: MoveWidgetMutation.mutationFn,
            mutationKey: MoveWidgetMutation.key,
            onSuccess: header => {
              queryClient.invalidateQueries(WidgetsByTemplateRevisionIdQuery.getKey(getTemplateRevision(_ctx)?.id));
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();

              // Update widget header in widget actor context to reflect new task template
              const widgetActor = _ctx.widgetActorMap?.[widget.header.id];
              const headerLike = { ...(header as WidgetHeaderLike) };
              // TODO: Expression produces a union type that is too complex to represent.
              // @ts-expect-error -- TODO
              widgetActor?.send({ type: 'UPDATE_WIDGET_HEADER', header: headerLike });
              widgetActor?.send({ type: 'MOVED_FROM_STEP', from });

              const template = QueryActorSelectors.getQueryData(_ctx.templateQuery);
              const href = LocationService.editStepHref(template?.id ?? '', to.group.id, template?.name ?? '');
              ToastServiceImpl.openToast({
                status: 'success',
                title: `Widget successfully moved to <a href='${href}' >${
                  to.name ?? TaskConstants.DefaultStepName
                }</a>`,
              });
            },
          }).execute();
        },

        updateMergeTagsMutation: async (ctx, evt) => {
          const templateRevision = getTemplateRevision(ctx);

          const { widget, oldKey } = match(evt)
            .with({ type: 'UPDATE_MERGE_TAGS_REFERENCES' }, ({ widget, oldKey }) => ({ widget, oldKey }))
            .otherwise(() => ({ widget: undefined, oldKey: undefined }));

          if (
            !templateRevision ||
            !widget ||
            !oldKey ||
            !isFormFieldWidget(widget) ||
            (widget && isFormFieldWidget(widget) && widget.key === oldKey)
          ) {
            return Promise.resolve();
          } else {
            const widgets =
              queryClient.getQueryData<WidgetsByTemplateRevisionIdQueryResponse>(
                WidgetsByTemplateRevisionIdQuery.getKey(templateRevision.id),
              ) ?? [];

            await MergeTagReferenceUpdateService.updateReferences({
              widgets,
              oldKey,
              newKey: widget.key,
              templateRevision,
              queryClient,
              onWidgetUpdated: w => {
                const widgetActor = ctx.widgetActorMap?.[w.header.id];
                widgetActor?.send({ type: 'UPDATE_WIDGET', widget: w });
              },
            });
          }
        },
      },
      guards: {
        draftDoesNotExist: (ctx, evt) => {
          if (ctx.isReadOnly) return false;

          return (
            QueryActorSelectors.isUpdateEventSuccess(evt, 'template-revisions-query') &&
            evt.state.context.data?.length === 1
          );
        },

        draftTemplateRevisionIdReady: (ctx, evt) => {
          if (ctx.isReadOnly) {
            return (
              QueryActorSelectors.isUpdateEventSuccess(evt, 'template-revisions-query') &&
              Boolean(evt.state.context.data?.[0].id)
            );
          }

          return (
            QueryActorSelectors.isUpdateEventSuccess(evt, 'template-revisions-query') &&
            Boolean(evt.state.context.data?.[1].id)
          );
        },

        // Ideally we would have 2 guards allDataReady and isGenerating and combine them, but that's in another
        // version of xstate. https://stately.ai/docs/guards#higher-level-guards. Same for isWidgetSuccess and isGenerating.
        allDataReadyAndNotGenerating: ctx => {
          const isDataReady = [
            ctx.templateQuery,
            ctx.templateRevisionsQuery,
            ctx.taskTemplatesByTemplateRevisionIdQuery,
            ctx.widgetsByTemplateRevisionIdQuery,
            ctx.groupsQuery,
          ].every(query => QueryActorSelectors.getQueryData(query) !== undefined);
          const isGenerating =
            ctx.aiWorkflowGenerationActorRef &&
            AiWorkflowGenerationActorSelector.isGenerating(ctx.aiWorkflowGenerationActorRef.state);
          return isDataReady && !isGenerating;
        },

        isWidgetSuccessAndNotGenerating: (ctx, evt) => {
          const isWidgetSuccess = QueryActorSelectors.isUpdateEventSuccess(evt, 'widgets-query');
          const isGenerating =
            ctx.aiWorkflowGenerationActorRef &&
            AiWorkflowGenerationActorSelector.isGenerating(ctx.aiWorkflowGenerationActorRef.state);
          return isWidgetSuccess && !isGenerating;
        },

        isBrandNewForm: (ctx, _evt) => {
          const template = QueryActorSelectors.getQueryData(ctx.templateQuery);
          const createdTime = template?.audit.createdDate ?? 0;
          const now = Date.now();
          const templateRevision = getTemplateRevision(ctx);
          const isFirstDraft = (templateRevision?.revision ?? 0) === 1;
          const wasCreatedRecently = now - createdTime < TemplateConstants.RECENTLY_CREATED_THRESHHOLD;
          const isBrandNewForm = wasCreatedRecently && isFirstDraft;
          const isDefaultName = template?.name === TemplateConstants.BLANK_FORM_NAME;
          return isDefaultName && isBrandNewForm;
        },
        isTaskTemplatesUpdate: (_ctx, evt) => {
          return QueryActorSelectors.isUpdateEventSuccess(evt, 'task-templates-query');
        },
        canDeleteTaskTemplate: (ctx, _evt) => {
          const template = QueryActorSelectors.getQueryData(ctx.templateQuery);
          if (!template) throw new Error('Template is missing');
          if (template.templateType !== TemplateType.Playbook) return true;

          const taskTemplates = QueryActorSelectors.getQueryData(ctx.taskTemplatesByTemplateRevisionIdQuery)?.all ?? [];
          return taskTemplates.length > 1;
        },
      },
    },
  );
};

function spawnWidgetMachine(
  widget: Widget,
  template: Template,
  sharedContext: SharedContext,
  isReadOnly: boolean = false,
) {
  return match(widget)
    .with({ fieldType: FieldType.Text }, widget =>
      spawn(FormFieldMachineFactories.Text({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.Email }, widget =>
      spawn(FormFieldMachineFactories.Email({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.Textarea }, widget =>
      spawn(FormFieldMachineFactories.Textarea({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.Url }, widget =>
      spawn(FormFieldMachineFactories.Url({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.Select }, widget =>
      spawn(FormFieldMachineFactories.Select({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.MultiChoice }, widget =>
      spawn(FormFieldMachineFactories.Select({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.File }, widget =>
      spawn(FormFieldMachineFactories.File({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.Date }, widget =>
      spawn(FormFieldMachineFactories.Date({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.Number }, widget =>
      spawn(FormFieldMachineFactories.Number({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.Table }, widget =>
      spawn(FormFieldMachineFactories.Table({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.Hidden }, widget =>
      spawn(FormFieldMachineFactories.Hidden({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.Snippet }, widget =>
      spawn(FormFieldMachineFactories.Snippet({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.Members }, widget =>
      spawn(FormFieldMachineFactories.Members({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.SendRichEmail }, widget =>
      spawn(ContentMachineFactories.Email({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.MultiSelect }, widget =>
      spawn(ContentMachineFactories.Subtasks({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ header: { type: WidgetType.Text, taskTemplate: { templateRevision: { id: P.string } } } }, widget =>
      spawn(ContentMachineFactories.Text({ widget, sharedContext, isReadOnly })),
    )
    .with({ header: { type: WidgetType.Image, taskTemplate: { templateRevision: { id: P.string } } } }, widget =>
      spawn(ContentMachineFactories.Image({ widget, sharedContext, isReadOnly })),
    )
    .with({ header: { type: WidgetType.File, taskTemplate: { templateRevision: { id: P.string } } } }, widget =>
      spawn(ContentMachineFactories.File({ widget, sharedContext, isReadOnly })),
    )
    .with({ header: { type: WidgetType.Video, taskTemplate: { templateRevision: { id: P.string } } } }, widget =>
      spawn(ContentMachineFactories.Video({ widget, sharedContext, isReadOnly })),
    )
    .with({ header: { type: WidgetType.CrossLink, taskTemplate: { templateRevision: { id: P.string } } } }, widget =>
      spawn(ContentMachineFactories.Page({ widget, sharedContext, isReadOnly })),
    )
    .with({ header: { type: WidgetType.Embed, taskTemplate: { templateRevision: { id: P.string } } } }, widget =>
      spawn(ContentMachineFactories.Embed({ widget, sharedContext, isReadOnly })),
    )
    .otherwise(() => undefined);
}

export type FormEditorPageMachine = ReturnType<typeof makeFormEditorPageMachine>;
export type FormEditorPageActorRef = ActorRefFrom<FormEditorPageMachine>;

function getTemplateRevisions(context: Context) {
  return QueryActorSelectors.getQueryData(context.templateRevisionsQuery);
}

function getDraftTemplateRevision(context: Context) {
  return getTemplateRevisions(context)?.[1];
}

function getPublishedTemplateRevision(context: Context) {
  return getTemplateRevisions(context)?.[0];
}

function getTemplateRevision(context: Context) {
  return context.isReadOnly ? getPublishedTemplateRevision(context) : getDraftTemplateRevision(context);
}

export const FormEditorPageActorSelectors = {
  getTemplate(state: StateFrom<FormEditorPageMachine>) {
    return QueryActorSelectors.getQueryData(state.context.templateQuery);
  },
  getTemplateRevision(state: StateFrom<FormEditorPageMachine>) {
    return getDraftTemplateRevision(state.context) ?? getPublishedTemplateRevision(state.context);
  },
  getPublishedTemplateRevision(state: StateFrom<FormEditorPageMachine>) {
    return getPublishedTemplateRevision(state.context);
  },
  getWidgetsForTaskTemplateGroupId(taskTemplateGroupId?: Muid) {
    return (state: StateFrom<FormEditorPageMachine>) => {
      if (!taskTemplateGroupId) return [];

      const taskTemplatesLookup: TaskTemplatesLookupMap =
        QueryActorSelectors.getQueryData(state.context.taskTemplatesByTemplateRevisionIdQuery) ??
        emptyTaskTemplatesLookupMap;

      const taskTemplateIdFromLookup = taskTemplatesLookup.byGroupId[taskTemplateGroupId];
      return FormEditorPageActorSelectors.getWidgetsForTaskTemplateId(taskTemplateIdFromLookup)(state);
    };
  },
  getWidgetsForTaskTemplateId(taskTemplateId: TaskTemplate['id']) {
    return (state: StateFrom<FormEditorPageMachine>) => {
      const widgetsLookup =
        QueryActorSelectors.getQueryData(state.context.widgetsByTemplateRevisionIdQuery) ?? emptyWidgetsLookup;
      const widgetsIds = widgetsLookup.byTaskTemplateId[taskTemplateId] ?? [];

      return widgetsIds.map(widgetId => widgetsLookup.byId[widgetId]);
    };
  },
  getWidgetById(widgetId: Widget['id']) {
    return (state: StateFrom<FormEditorPageMachine>) => {
      const widgetsLookup =
        QueryActorSelectors.getQueryData(state.context.widgetsByTemplateRevisionIdQuery) ?? emptyWidgetsLookup;

      return widgetsLookup.byId[widgetId];
    };
  },
  getWidgetByGroupId(widgetId: WidgetHeader['group']['id']) {
    return (state: StateFrom<FormEditorPageMachine>) => {
      const widgetsLookup =
        QueryActorSelectors.getQueryData(state.context.widgetsByTemplateRevisionIdQuery) ?? emptyWidgetsLookup;
      const widgetIdFromLookup = widgetsLookup.byGroupId[widgetId];

      return widgetsLookup.byId[widgetIdFromLookup];
    };
  },
  getTaskTemplateById(taskTemplateId: TaskTemplate['id']) {
    return (state: StateFrom<FormEditorPageMachine>) => {
      const taskTemplatesLookup: TaskTemplatesLookupMap =
        QueryActorSelectors.getQueryData(state.context.taskTemplatesByTemplateRevisionIdQuery) ??
        emptyTaskTemplatesLookupMap;

      return taskTemplatesLookup.byId[taskTemplateId];
    };
  },
  getTaskTemplateByGroupId(taskTemplateGroupId: TaskTemplate['group']['id']) {
    return (state: StateFrom<FormEditorPageMachine>): TaskTemplate | undefined => {
      const taskTemplatesLookup: TaskTemplatesLookupMap =
        QueryActorSelectors.getQueryData(state.context.taskTemplatesByTemplateRevisionIdQuery) ??
        emptyTaskTemplatesLookupMap;
      const taskTemplateId = taskTemplatesLookup.byGroupId[taskTemplateGroupId];

      return taskTemplatesLookup.byId[taskTemplateId];
    };
  },
  getPublishedWidgetsForTaskTemplateGroupId(taskTemplateGroupId?: Muid) {
    return (state: StateFrom<FormEditorPageMachine>) => {
      const taskTemplates = state.context.publishedTaskTemplates ?? [];
      const taskTemplate = taskTemplates.find(tt => tt.group.id === taskTemplateGroupId);
      return state.context.publishedWidgets?.filter(w => w.header.taskTemplate.id === taskTemplate?.id) ?? [];
    };
  },
  getTaskTemplateWithPosition(taskTemplateGroupId?: Muid) {
    return (state: StateFrom<FormEditorPageMachine>) => {
      const draftTaskTemplatesLookup =
        QueryActorSelectors.getQueryData(state.context.taskTemplatesByTemplateRevisionIdQuery) ??
        emptyTaskTemplatesLookupMap;

      const taskTemplates =
        draftTaskTemplatesLookup.all.length > 0
          ? draftTaskTemplatesLookup.all
          : state.context.publishedTaskTemplates ?? [];
      const index = taskTemplates.findIndex(tt => tt.group.id === taskTemplateGroupId);
      const taskTemplate = taskTemplates[index];

      return {
        taskTemplate,
        index,
        isFirst: index === 0,
        isLast: index === taskTemplates.length - 1,
      };
    };
  },
  getTaskTemplates(state: StateFrom<FormEditorPageMachine>) {
    const taskTemplatesLookup =
      QueryActorSelectors.getQueryData(state.context.taskTemplatesByTemplateRevisionIdQuery) ??
      emptyTaskTemplatesLookupMap;

    if (!taskTemplatesLookup.all.length) return state.context.publishedTaskTemplates ?? [];
    return taskTemplatesLookup.all ?? [];
  },
  getGeneratedTaskTemplates(state: StateFrom<FormEditorPageMachine>) {
    return QueryActorSelectors.getQueryData(state.context.generatedTaskTemplatesByTemplateRevisionIdQuery) ?? [];
  },
  getWidgets(state: StateFrom<FormEditorPageMachine>) {
    const widgetsLookup: WidgetsLookupMap =
      QueryActorSelectors.getQueryData(state.context.widgetsByTemplateRevisionIdQuery) ?? emptyWidgetsLookup;

    if (!widgetsLookup.all.length) return state.context.publishedWidgets ?? [];

    return widgetsLookup.all;
  },
  getWidgetsByTaskTemplateId(state: StateFrom<FormEditorPageMachine>) {
    const widgetsLookup: WidgetsLookupMap =
      QueryActorSelectors.getQueryData(state.context.widgetsByTemplateRevisionIdQuery) ?? emptyWidgetsLookup;
    return widgetsLookup.byTaskTemplateId;
  },
  getGroups(state: StateFrom<FormEditorPageMachine>) {
    const groups = QueryActorSelectors.getQueryData(state.context.groupsQuery) ?? [];
    return groups;
  },
  getPublishedTaskTemplates(state: StateFrom<FormEditorPageMachine>) {
    return state.context.publishedTaskTemplates ?? [];
  },
  getFirstTaskTemplate(state: StateFrom<FormEditorPageMachine>): TaskTemplate | undefined {
    return (
      QueryActorSelectors.getQueryData(state.context.taskTemplatesByTemplateRevisionIdQuery) ??
      emptyTaskTemplatesLookupMap
    ).all[0];
  },
  getFirstTaskTemplateGroupId(state: StateFrom<FormEditorPageMachine>) {
    return (
      QueryActorSelectors.getQueryData(state.context.taskTemplatesByTemplateRevisionIdQuery) ??
      emptyTaskTemplatesLookupMap
    ).all[0]?.group?.id;
  },
  getWidgetActorMap(state: StateFrom<FormEditorPageMachine>) {
    return state.context.widgetActorMap;
  },
  isPublishing(state: StateFrom<FormEditorPageMachine>) {
    return state.matches('publishing');
  },
  isLoading(state: StateFrom<FormEditorPageMachine>) {
    return state.matches('loading');
  },
  isDeleting(state: StateFrom<FormEditorPageMachine>) {
    return state.matches('editing.deletingTaskTemplate');
  },
  isPersistingReordering(state: StateFrom<FormEditorPageMachine>) {
    return state.matches('editing.reorderingTaskTemplates.persisting');
  },
  isReordering(state: StateFrom<FormEditorPageMachine>) {
    return (
      state.matches('editing.reorderingTaskTemplates.reordered') || state.matches('editing.reorderingWidgets.reordered')
    );
  },
  isCreatingTaskTemplate(state: StateFrom<FormEditorPageMachine>) {
    return state.matches('editing.creatingTaskTemplate');
  },
  getShouldShowDeleteTaskTemplatePrompt(state: StateFrom<FormEditorPageMachine>) {
    return state.matches('editing.deletingTaskTemplate.prompt');
  },
  getOrganizationMemberships(state: StateFrom<FormEditorPageMachine>) {
    return QueryActorSelectors.getQueryData(state.context.organizationMembershipsQuery) ?? [];
  },
  getTaskAssignments(state: StateFrom<FormEditorPageMachine>) {
    return QueryActorSelectors.getQueryData(state.context.templateTaskAssignmentsQuery) ?? [];
  },
  getTaskAssignmentRules(state: StateFrom<FormEditorPageMachine>) {
    return QueryActorSelectors.getQueryData(state.context.taskAssignmentRulesQuery) ?? [];
  },
  getRules(state: StateFrom<FormEditorPageMachine>): Array<ChecklistRuleDefinition> {
    return QueryActorSelectors.getQueryData(state.context.rulesQuery)?.definitions ?? [];
  },
  getApprovalRules(state: StateFrom<FormEditorPageMachine>): Array<ApprovalRuleSubject> {
    return QueryActorSelectors.getQueryData(state.context.approvalRulesQuery) ?? [];
  },
  getDueDateRules(state: StateFrom<FormEditorPageMachine>): Array<DueDateRuleDefinition> {
    return QueryActorSelectors.getQueryData(state.context.dueDateRulesQuery) ?? [];
  },
  getAiWorkflowGeneratorActorRef(state: StateFrom<FormEditorPageMachine>) {
    return state.context.aiWorkflowGenerationActorRef;
  },
  getUIActorRef(state: StateFrom<FormEditorPageMachine>) {
    return state.context.uiActorRef;
  },
  getTaskTemplateListActorRef(state: StateFrom<FormEditorPageMachine>) {
    return state.context.taskTemplateListActorRef;
  },
  deleteTasksNeedsConfirmation(state: StateFrom<FormEditorPageMachine>) {
    return state.context.taskTemplateListActorRef;
  },
  isReadOnly(state: StateFrom<FormEditorPageMachine>) {
    return state.context.isReadOnly;
  },
};

function getOrderTreesRelativeToWidget({
  orderTrees,
  ...event
}: CreateWidgetEvent & {
  orderTrees: OrderTree[];
}): OrderTree[] {
  return match(event)
    .with(
      { after: P.not(P.nullish) },
      ({
        after: {
          header: { orderTree },
        },
      }) => orderTreeService.after(orderTrees, orderTree!),
    )
    .with(
      { before: P.not(P.nullish) },
      ({
        before: {
          header: { orderTree },
        },
      }) => orderTreeService.before(orderTrees, orderTree!),
    )
    .otherwise(() => orderTreeService.before(orderTrees, null));
}

function makeWidgetRequest({
  event,
  allWidgets,
  taskTemplateId,
  defaultGroupId,
}: {
  event: CreateWidgetEvent;
  allWidgets: Widget[];
  taskTemplateId: Muid;
  defaultGroupId: Muid;
}) {
  const widgetsByTaskTemplate = _groupBy(allWidgets, w => w.header.taskTemplate.id);
  const taskTemplateWidgets = widgetsByTaskTemplate[taskTemplateId] ?? [];
  const orderTrees = taskTemplateWidgets.map(w => w.header.orderTree!);
  const [orderTree] = getOrderTreesRelativeToWidget({ ...event, orderTrees });

  const keys = FormFieldHelpers.toKeys(allWidgets);
  const config = match(event.payload)
    .with(
      { fieldType: P.union(FieldType.Select, FieldType.MultiChoice) },
      (): SelectFormFieldConfig => ({
        items: [
          { id: MuidUtils.randomMuid(), name: '' },
          { id: MuidUtils.randomMuid(), name: '' },
          { id: MuidUtils.randomMuid(), name: '' },
        ],
      }),
    )
    .with(
      { fieldType: P.union(FieldType.Table) },
      (): TableFormFieldConfig.Config => ({
        columnDefs: [
          { id: MuidUtils.randomMuid(), name: 'Name', columnType: 'Text' },
          { id: MuidUtils.randomMuid(), name: 'ID', columnType: 'Text' },
        ],
      }),
    )
    .with(
      { fieldType: P.union(FieldType.Members) },
      (): MembersFormFieldConfig => ({
        groupId: defaultGroupId,
      }),
    )
    .with(
      { fieldType: P.union(FieldType.SendRichEmail) },
      (): SendRichEmailFormFieldConfig => ({
        editor: 'RichEditor',
        emailFormat: 'RichTextOrHtml',
        to: [],
        cc: [],
        bcc: [],
        subject: '',
        plainTextBody: '',
        richEditorBody: '<p></p>',
        rawHTMLBody: '',
        editAllowed: true,
      }),
    )
    .with(
      { fieldType: P.union(FieldType.MultiSelect) },
      (): SelectFormFieldConfig => ({
        items: [
          { id: MuidUtils.randomMuid(), name: '' },
          { id: MuidUtils.randomMuid(), name: '' },
          { id: MuidUtils.randomMuid(), name: '' },
        ],
      }),
    )
    .with({ fieldType: P.not(P.nullish) }, () => ({}))
    .otherwise(() => undefined);

  const widgetRequest = {
    headerId: MuidUtils.randomMuid(),
    taskTemplateId,
    orderTree,
    config,
    ...('fieldType' in event.payload
      ? {
          type: WidgetType.FormField,
          key: FormFieldHelpers.generateUniqueKey(keys, FormFieldDefaultKey[event.payload.fieldType]),
          label: match(event.payload)
            .with({ fieldType: FieldType.Text }, () => 'Short Text')
            .with({ fieldType: FieldType.Textarea }, () => 'Long Text')
            .with({ fieldType: FieldType.Email }, () => 'Email')
            .with({ fieldType: FieldType.Url }, () => 'Website')
            .with({ fieldType: FieldType.Select }, () => 'Dropdown')
            .with({ fieldType: FieldType.MultiChoice }, () => 'Multi Choice')
            .with({ fieldType: FieldType.File }, () => 'File')
            .with({ fieldType: FieldType.Date }, () => 'Date')
            .with({ fieldType: FieldType.Number }, () => 'Number')
            .with({ fieldType: FieldType.Table }, () => 'Table')
            .with({ fieldType: FieldType.Hidden }, () => 'Hidden')
            .with({ fieldType: FieldType.Snippet }, () => 'Snippet')
            .with({ fieldType: FieldType.Members }, () => 'Members')
            .otherwise(() => undefined),
          ...event.payload,
        }
      : { type: event.payload.widgetType }),
  };
  return widgetRequest;
}

function createWidgetsLookup(widgets: Array<Widget>) {
  return widgets.reduce<WidgetsLookupMap>(
    (lookup, widget) => {
      lookup.byId[widget.id] = widget;
      lookup.byGroupId[widget.header.group.id] = widget.id;

      const existingWidgetsForTaskTemplateId = lookup.byTaskTemplateId[widget.header.taskTemplate.id] ?? [];

      lookup.byTaskTemplateId[widget.header.taskTemplate.id] = [...existingWidgetsForTaskTemplateId, widget.id];

      return lookup;
    },
    {
      byId: {},
      byGroupId: {},
      byTaskTemplateId: {},
      all: widgets,
    },
  );
}

function createTaskTemplatesLookup(taskTemplates: Array<TaskTemplate>) {
  return taskTemplates.reduce<TaskTemplatesLookupMap>(
    (lookup, taskTemplate) => {
      lookup.byId[taskTemplate.id] = taskTemplate;
      lookup.byGroupId[taskTemplate.group.id] = taskTemplate.id;

      return lookup;
    },
    {
      byId: {},
      byGroupId: {},
      all: taskTemplates,
    },
  );
}

function insertNewWidget({
  event,
  widgets,
  newWidget,
}: {
  event: CreateWidgetEvent;
  widgets: Widget[];
  newWidget: Widget;
}) {
  return match(event)
    .with(
      { after: P.not(P.nullish) },
      ({
        after: {
          header: { id },
        },
      }) => {
        const index = widgets.findIndex(w => w.header.id === id);
        return [...widgets.slice(0, index + 1), newWidget, ...widgets.slice(index + 1)];
      },
    )
    .with(
      { before: P.not(P.nullish) },
      ({
        before: {
          header: { id },
        },
      }) => {
        const index = widgets.findIndex(w => w.header.id === id);
        return [...widgets.slice(0, index), newWidget, ...widgets.slice(index)];
      },
    )
    .otherwise(() => [...widgets, newWidget]);
}

export const Utils = {
  makeWidgetRequest,
  insertNewWidget,
  createTaskTemplatesLookup,
  createWidgetsLookup,
};

const widgetComparer = getOrderableComparer(<W extends { header: { orderTree?: string } }>(widget: W) => widget.header);
