import React from 'react';
import { Template } from '@process-street/subgrade/process';
import { Button, Flex, Icon } from 'components/design/next';
import { DeleteCoverImageMutation } from 'features/cover-image/query-builder';

export const EditCoverLayer: React.FC<{
  onClickChange: () => void;
  templateId: Template['id'];
}> = ({ onClickChange, templateId }) => {
  const removeCoverMutation = DeleteCoverImageMutation.useMutationWithToast();
  const handleRemoveCover = () => {
    removeCoverMutation.mutate({ templateId });
  };

  return (
    <Flex
      width="full"
      height="full"
      position="absolute"
      top="0"
      bgColor="transparent"
      _hover={{ backgroundColor: 'rgba(0,0,0,0.6)', transition: 'all 0.4s' }}
      gridGap={2}
      alignItems="center"
      justifyContent="center"
    >
      <Button aria-label="change cover image" size="sm" variant="solid" onClick={onClickChange}>
        Change cover
      </Button>
      <Button
        aria-label="remove cover image"
        size="sm"
        variant="danger"
        leftIcon={<Icon size="4" variant="far" icon="trash" />}
        onClick={handleRemoveCover}
      >
        Remove
      </Button>
    </Flex>
  );
};
