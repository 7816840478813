import * as React from 'react';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Skeleton,
  Text,
  Textarea,
  Tooltip,
  useToast,
  VStack,
} from 'components/design/next';
import { Form, Formik, FormikHelpers } from 'formik';
import {
  TemplateSettingsNextPath,
  TemplateSettingsSchema,
  templateSettingsSchema,
} from 'pages/templates/_id/components/template-settings-modal/onboarding-steps/schema';
import {
  Box,
  Divider,
  FormErrorMessage,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
} from 'components/design/next';
import { GetTemplateQuery, useGetTemplateQuery, useUpdateTemplateMutation } from 'features/template/query-builder';
import {
  GetNewestTemplateRevisionsByTemplateIdQuery,
  TemplateRevisionQuery,
  useTemplateRevisionQuery,
  useUpdateTemplateRevisionRevisionMutation,
} from 'features/template-revisions/query-builder';
import { useQueryClient } from 'react-query';
import { CustomizeFirstWorkflowAlert } from 'pages/templates/_id/components/template-settings-modal/onboarding-steps/alerts';
import { ShowAtStartCheckbox } from 'pages/templates/_id/components/template-settings-modal/onboarding-steps/show-at-start-checkbox';
import { useTemplateSettingsModalContext } from '../template-settings-modal-context';
import { DefaultChecklistNameInput } from './default-checklist-name-input';
import { ChecklistShareLinks } from 'pages/templates/_id/components/template-settings-modal/onboarding-steps/checklist-share-links';
import { ChecklistComments } from 'pages/templates/_id/components/template-settings-modal/onboarding-steps/checklist-comments';
import { AddIconButton } from 'pages/templates/_id/components/template-settings-modal/onboarding-steps/add-icon-button';
import { AddCoverButton } from 'pages/templates/_id/components/template-settings-modal/onboarding-steps/add-cover-button';
import { useTemplateSettingsContext } from '../context';
import { match } from 'ts-pattern';
import { useFeatureFlag } from 'features/feature-flags';
import { DueDatePicker } from 'features/dynamic-due-dates';
import { ChecklistRequestApproval } from './checklist-request-approval';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { DesignSection } from './design-section';

export interface WorkflowSetupStepProps {
  onNext: () => void;
  onClose: () => void;
}

const FORM_ID = 'workflow-settings';
const ARROW_SIZE = 10;

export const WorkflowSetupStep: React.FC<React.PropsWithChildren<WorkflowSetupStepProps>> = ({ ...props }) => {
  const { templateId, templateRevisionId, variant, shouldAutofocusName, setShouldAutofocusName } =
    useTemplateSettingsModalContext();

  const isDynamicDueDatesWfrEnabled = useFeatureFlag('dynamicDueDatesWfr');
  const isWorkflowReviewEnabled = useFeatureFlag('workflowReview');
  const isReactWorkflowEditorEnabled = useFeatureFlag('reactWorkflowEditor');

  const isSetupScreen = variant === 'setupScreen';
  const isOnboardingWizard = variant === 'onboardingWizard';

  const templateQuery = useGetTemplateQuery({ templateId });
  const templateRevisionQuery = useTemplateRevisionQuery(
    { templateRevisionId: templateRevisionId! },
    { enabled: !!templateRevisionId },
  );

  const updateTemplateMutation = useUpdateTemplateMutation();
  const updateTemplateRevisionRevisionMutation = useUpdateTemplateRevisionRevisionMutation();

  const queryClient = useQueryClient();
  const toast = useToast();

  const submitPathRef = React.useRef<TemplateSettingsNextPath>(TemplateSettingsNextPath.Default);

  const { send } = useTemplateSettingsContext();

  const handleOnSubmit = async (
    values: TemplateSettingsSchema,
    { setSubmitting }: FormikHelpers<TemplateSettingsSchema>,
  ) => {
    setSubmitting(true);
    const templateUpdatePromise = updateTemplateMutation.mutateAsync({
      templateId,
      name: values.name,
      description: values.description,
      checklistCommentsEnabled: values.checklistCommentsEnabled,
      checklistShareLinkEnabled: values.checklistShareLinkEnabled,
    });

    const templateRevisionUpdatePromise = templateRevisionId
      ? updateTemplateRevisionRevisionMutation.mutateAsync({
          id: templateRevisionId!,
          defaultChecklistName: values.defaultChecklistName !== '' ? values.defaultChecklistName : undefined,
        })
      : Promise.resolve();

    await Promise.all([templateUpdatePromise, templateRevisionUpdatePromise]).then(
      async ([template, templateRevision]) => {
        toast({
          status: 'success',
          title: 'Workflow updated',
        });

        document.title = `${template.name} | Process Street`;

        queryClient.setQueryData(GetTemplateQuery.getKey({ templateId }), template);
        templateRevisionId &&
          queryClient.setQueryData(TemplateRevisionQuery.getKey({ templateRevisionId }), templateRevision);
        await queryClient.invalidateQueries(GetNewestTemplateRevisionsByTemplateIdQuery.getKey({ templateId }));
        setSubmitting(false);

        match(submitPathRef.current)
          .with(TemplateSettingsNextPath.AddTasks, () => send({ type: 'GO_TO_TASKS' }))
          .otherwise(() => props.onNext());
      },
    );
  };

  const isLoading = templateQuery.isLoading || templateRevisionQuery.isLoading;

  const checklistNameRef = React.useRef<HTMLInputElement>(null);
  const [autofocused, setAutofocused] = React.useState(false);
  React.useEffect(() => {
    if (!isLoading && (shouldAutofocusName || (variant === 'onboardingWizard' && !autofocused))) {
      checklistNameRef.current?.select();
      setShouldAutofocusName(false);
      setAutofocused(true);
    }
  }, [autofocused, isLoading, setShouldAutofocusName, shouldAutofocusName, variant]);

  const templateRevision = templateRevisionQuery.data;
  const isTemplateRevisionSettingsEnabled = variant !== 'dashboardSetupScreen';

  const content = (
    <>
      <ModalHeader>
        <HStack>
          <Icon icon="gear" variant="far" size="4" color="gray.400" />
          <Text fontSize={isWorkflowReviewEnabled ? 'lg' : 'inherit'} color="gray.600">
            Workflow Setup
          </Text>
        </HStack>
      </ModalHeader>
      <ModalCloseButton />
      <Divider />
      <Formik<TemplateSettingsSchema>
        enableReinitialize
        initialValues={{
          name: templateQuery.data?.name ?? '',
          description: templateQuery.data?.description ?? '',
          defaultChecklistName: templateRevisionQuery.data?.defaultChecklistName ?? '',
          checklistCommentsEnabled: templateQuery.data?.checklistCommentsEnabled ?? true,
          checklistShareLinkEnabled: templateQuery.data?.checklistShareLinkEnabled ?? false,
          checklistRequestApprovalEnabled: templateQuery.data?.checklistRequestApprovalEnabled ?? false,
        }}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={templateSettingsSchema}
        onSubmit={handleOnSubmit}
      >
        {({ handleSubmit, handleChange, handleBlur, values, isSubmitting, errors, touched, isValid }) => {
          return (
            <Form id={FORM_ID} onSubmit={handleSubmit}>
              <ModalBody>
                {isOnboardingWizard && <CustomizeFirstWorkflowAlert my={4} />}
                <VStack spacing="6" alignItems="flex-start">
                  {!isReactWorkflowEditorEnabled && (
                    <HStack spacing="8" w="100%" mt={3}>
                      <AddIconButton templateId={templateId} />
                      <AddCoverButton templateId={templateId} />
                    </HStack>
                  )}
                  <FormControl isInvalid={Boolean(errors.name) && touched.name}>
                    <FormLabel>
                      <HStack>
                        <Text variant="1">Workflow Name</Text>
                        <Tooltip
                          label="A workflow run is a single instance of the process in which you actually implement the work."
                          hasArrow
                          arrowSize={ARROW_SIZE}
                          shouldWrapChildren
                        >
                          <Icon icon="circle-info" variant="far" size="4" color="gray.500" />
                        </Tooltip>
                      </HStack>
                    </FormLabel>

                    <TemplateQuerySkeleton isLoading={isLoading}>
                      <Input
                        name="name"
                        aria-label="Workflow name"
                        placeholder="Workflow name"
                        value={values.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        ref={checklistNameRef}
                      />
                    </TemplateQuerySkeleton>
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>

                  {isTemplateRevisionSettingsEnabled && templateRevisionId && (
                    <FormControl isInvalid={Boolean(errors.defaultChecklistName) && touched.defaultChecklistName}>
                      <FormLabel>
                        <HStack>
                          <Text variant="1">Workflow Run Name</Text>
                          <Popover trigger="hover" arrowSize={ARROW_SIZE}>
                            <PopoverTrigger>
                              <Box>
                                <Icon icon="circle-info" variant="far" size="4" color="gray.500" />
                              </Box>
                            </PopoverTrigger>
                            <PopoverContent backgroundColor="black" borderColor="black" color="whiteAlpha.900">
                              <PopoverArrow backgroundColor="black" />
                              <PopoverBody>
                                <Text fontSize="xs">
                                  Create a default naming convention for your workflow run names. Learn more{' '}
                                  <Link
                                    href="https://www.process.st/help/docs/naming-workflow-runs/"
                                    target="_blank"
                                    textDecoration="underline"
                                  >
                                    here
                                  </Link>
                                </Text>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </HStack>
                      </FormLabel>

                      <TemplateQuerySkeleton isLoading={isLoading}>
                        <DefaultChecklistNameInput templateRevisionId={templateRevisionId} />
                      </TemplateQuerySkeleton>
                      <FormErrorMessage>{errors.defaultChecklistName}</FormErrorMessage>
                    </FormControl>
                  )}

                  {isDynamicDueDatesWfrEnabled && templateRevision && (
                    <FormControl>
                      <FormLabel>Workflow Run Default Due Date</FormLabel>
                      <DueDatePicker
                        selectedTaskTemplate={undefined}
                        selectedTaskTemplates={[]}
                        templateRevision={templateRevision}
                        disabled={false}
                        editable={true}
                        ruleType="Checklist"
                      />
                    </FormControl>
                  )}

                  <FormControl isInvalid={Boolean(errors.description) && touched.description}>
                    <FormLabel>Description</FormLabel>

                    <TemplateQuerySkeleton isLoading={isLoading}>
                      <Textarea
                        name="description"
                        aria-label="Workflow description"
                        placeholder="What will this workflow be used for?"
                        value={values.description}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </TemplateQuerySkeleton>
                    <FormErrorMessage>{errors.description}</FormErrorMessage>
                  </FormControl>

                  <FormControl>
                    <TemplateQuerySkeleton isLoading={isLoading}>
                      <ChecklistComments {...{ handleChange, values }} />
                    </TemplateQuerySkeleton>
                    <FormErrorMessage>{errors.description}</FormErrorMessage>
                  </FormControl>

                  <FormControl>
                    <TemplateQuerySkeleton isLoading={isLoading}>
                      <ChecklistShareLinks {...{ handleChange, values }} />
                    </TemplateQuerySkeleton>
                  </FormControl>

                  {isWorkflowReviewEnabled && (
                    <FormControl>
                      <TemplateQuerySkeleton isLoading={isLoading}>
                        <ChecklistRequestApproval {...{ handleChange, values }} />
                      </TemplateQuerySkeleton>
                    </FormControl>
                  )}
                  {isReactWorkflowEditorEnabled && <DesignSection />}
                </VStack>
              </ModalBody>

              <ModalFooter>
                {isOnboardingWizard && (
                  <>
                    <ShowAtStartCheckbox />
                    <Spacer />
                  </>
                )}
                <ButtonGroup>
                  {isSetupScreen && (
                    <Button variant="secondary" onClick={props.onClose}>
                      Cancel
                    </Button>
                  )}

                  {!isSetupScreen && (
                    <Button
                      variant="link"
                      color="brand.400"
                      mr={2}
                      form={FORM_ID}
                      type="submit"
                      onClick={() => {
                        submitPathRef.current = TemplateSettingsNextPath.AddTasks;
                      }}
                      isLoading={isSubmitting || isLoading}
                      isDisabled={!isValid}
                    >
                      Bulk Create Tasks
                    </Button>
                  )}
                  <Button
                    variant="primary"
                    form={FORM_ID}
                    type="submit"
                    isLoading={isSubmitting || isLoading}
                    isDisabled={!isValid}
                  >
                    {isSetupScreen ? 'Save' : 'Next'}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </>
  );
  return isReactWorkflowEditorEnabled ? <ThemeProvider2024>{content}</ThemeProvider2024> : <>{content}</>;
};

const TemplateQuerySkeleton: React.FC<React.PropsWithChildren<{ isLoading: boolean }>> = ({ isLoading, children }) => (
  <Skeleton isLoaded={!isLoading}>{children}</Skeleton>
);
