import * as React from 'react';
import { useField, useFormikContext } from 'formik';
import {
  FormControl,
  FormErrorMessage,
  Icon,
  InputGroup,
  InputProps,
  Textarea as ChakraTextarea,
} from 'components/design/next';
import { formikMetaToFormControlProps } from '../common/utils';
import { useDebouncedCallback } from 'use-debounce';
import Textarea from 'react-autosize-textarea';

const DEBOUNCE_DELAY = 500;

export const SnippetValueField: React.FC<React.PropsWithChildren<InputProps & { id: string }>> = ({
  placeholder,
  id,
  isDisabled,
}) => {
  const [field, meta] = useField<string>(DefaultValueFieldName);
  const { handleSubmit, setFieldValue } = useFormikContext();

  const debouncedSubmit = useDebouncedCallback(() => {
    handleSubmit();
  }, DEBOUNCE_DELAY);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFieldValue(DefaultValueFieldName, e.target.value);
    debouncedSubmit();
  };
  const handleBlur = () => debouncedSubmit.flush();

  return (
    <FormControl {...formikMetaToFormControlProps(meta)}>
      <InputGroup>
        <Icon icon="brackets-curly" size="4" color="gray.400" position="absolute" left={4} top="10px" />
        <ChakraTextarea
          as={Textarea}
          id={id}
          {...field}
          value={field.value ?? ''}
          aria-label="optional instructions about field requirements"
          placeholder={placeholder ? placeholder : 'Add reusable text (including variables)...'}
          border="dashed"
          borderWidth="1px"
          borderColor="gray.300"
          onChange={handleChange}
          onBlur={handleBlur}
          variant="outline"
          pl={10}
          isDisabled={isDisabled}
        />
      </InputGroup>

      {meta.error ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
    </FormControl>
  );
};

export const DefaultValueFieldName = 'config.value';
