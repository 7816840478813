import { EmbedWidget, WidgetUtils } from '@process-street/subgrade/process';
import { EmbedUtils } from '@process-street/subgrade/util';
import { SandboxedIframe } from 'components/widgets/embed/SandboxedIframe';
import React from 'react';
import {
  Text,
  Input,
  FormControl,
  FormErrorMessage,
  VStack,
  InputGroup,
  InputRightElement,
} from 'components/design/next';
import { match, P } from 'ts-pattern';
import { useDebouncedCallback } from 'use-debounce';
import { useMergeTaggableInput } from 'hooks/use-merge-taggable-input';
import { MergeTagsMenu, MergeTagsMenuButton } from 'features/merge-tags/components/merge-tags-menu';
import { useStateParam } from 'hooks/use-state-param';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { MergeTagTarget } from '@process-street/subgrade/form';
import { EmbedWidgetPlaceholder } from '../embed-widget-placeholder';
import { useWidgetUpdateOverrideListener } from 'hooks/use-widget-update-override-listener';
import { noop } from 'lodash';

export interface TemplateEmbedWidgetProps {
  widget: EmbedWidget;
  disabled: boolean;
  onUpdate: (widget: EmbedWidget) => void;
  onFocus?: () => void;
  isReadOnly?: boolean;
}

const isEmptyOrUrlHttps = (url: string) => url === '' || EmbedUtils.isUrlHttps(url);
const isEmptyUrlValid = (url: string) => url === '' || EmbedUtils.isUrlValid(url) || WidgetUtils.hasVariables(url);

export const TemplateEmbedWidget = React.forwardRef<HTMLDivElement, React.PropsWithChildren<TemplateEmbedWidgetProps>>(
  ({ onFocus = noop, isReadOnly, ...props }, ref) => {
    const [iframeUrl, setIframeUrl] = React.useState(props.widget.url || '');
    const [url, setUrl] = React.useState(props.widget.url || '');

    useWidgetUpdateOverrideListener(props.widget, ({ url }) => {
      setUrl(url || '');
      setIframeUrl(url || '');
    });

    const urlIsHttps = isEmptyOrUrlHttps(url || '');
    const urlIsValid = isEmptyUrlValid(url || '');

    /**
     * Updates the iframe URL after 500ms of inactivity, up to a maximum of 2s.
     */
    const updateIframeUrl = useDebouncedCallback(
      (url: string) => {
        setIframeUrl(url);
        props.onUpdate({ ...props.widget, url });
      },
      500,
      { maxWait: 2000 },
    );

    const handleChange = (eventOrString: React.ChangeEvent<HTMLInputElement> | string) => {
      const url = typeof eventOrString === 'string' ? eventOrString : EmbedUtils.extractUrl(eventOrString.target.value);
      setUrl(url);
      const isValid = isEmptyUrlValid(url || '');
      if (isValid) {
        updateIframeUrl(url);
      }
    };

    const { ref: inputRef, insertMergeTag } = useMergeTaggableInput({
      get: () => url,
      set: handleChange,
    });
    const templateId = useStateParam({ key: 'id' });
    const templateRevisionQuery = useNewestTemplateRevisionQuery({ templateId, editable: true });
    const templateRevisionId = templateRevisionQuery.data?.id;

    const hasVariables = WidgetUtils.hasVariables(url);

    return (
      <VStack alignItems="stretch" spacing="4" ref={ref}>
        {!props.disabled && (
          <FormControl isInvalid={!urlIsValid}>
            <InputGroup>
              {!isReadOnly && (
                <Input
                  ref={inputRef}
                  type="url"
                  data-focus-id={`widget-${props.widget.header.id}`}
                  value={url}
                  onChange={handleChange}
                  onFocus={onFocus}
                  placeholder="Type or paste website embed URL here"
                />
              )}

              {templateRevisionId && !isReadOnly ? (
                <InputRightElement>
                  <MergeTagsMenu
                    {...{
                      templateRevisionId,
                      onSelect: (key, _fieldId, fallback) => insertMergeTag(key, fallback),
                      mergeTagTarget: MergeTagTarget.GENERAL,
                      menuButton: <MergeTagsMenuButton size="sm" bg="white" />,
                    }}
                  />
                </InputRightElement>
              ) : null}
            </InputGroup>

            {match({ urlIsHttps, urlIsValid })
              .with({ urlIsHttps: false }, () => (
                <FormErrorMessage>
                  <Text variant="inherit">
                    Oops! The website URL must start with{' '}
                    <Text as="b" variant="inherit">
                      https://
                    </Text>
                    .
                  </Text>
                </FormErrorMessage>
              ))
              .with({ urlIsValid: false }, () => (
                <FormErrorMessage>Oops! That's not a valid website URL.</FormErrorMessage>
              ))
              .otherwise(() => null)}
          </FormControl>
        )}

        {match({ iframeUrl, hasVariables: WidgetUtils.hasVariables(iframeUrl) })
          .with({ hasVariables: false, iframeUrl: P.when(url => url.length > 0) }, () => (
            <SandboxedIframe url={iframeUrl} />
          ))
          .otherwise(() => (
            <EmbedWidgetPlaceholder>
              {hasVariables ? (
                <Text variant="-2" color="gray.500" px="4" textAlign="center">
                  URL preview depends on variables{props.disabled ? `: ${url}` : ``}
                </Text>
              ) : null}
            </EmbedWidgetPlaceholder>
          ))}
      </VStack>
    );
  },
);
