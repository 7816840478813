import * as React from 'react';
import { OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { Avatar, AvatarBadge, Button, Icon, Tooltip } from 'app/components/design/next';
import { TaskAssignmentRule, TaskAssignmentRuleSourceType } from '@process-street/subgrade/role-assignment';
import { getAvatar } from 'app/components/common/Avatar';
import { match, P } from 'ts-pattern';
import { FieldType, isFormFieldWidget, TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import { CustomIconName } from 'app/components/design/next/icon/icon-name';
import { ViewAssigneesMenu } from 'app/pages/templates/_id/components/assign-task-button/view-assignees-menu';
import { useTaskAssignments } from 'app/pages/templates/_id/components/assign-task-button/use-task-assignments';
import { Option } from 'space-monad';
import {
  FormEditorPageActorSelectors,
  FormEditorPageMachine,
  useFormEditorPageActorRef,
} from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { StateFrom } from 'xstate';
import { useSelector } from '@xstate/react';

export type TaskListItemAvatarProps = {
  organizationMemberships: Array<OrganizationMembershipWithUser>;
  taskAssignmentRules: Array<TaskAssignmentRule>;
  taskTemplate: TaskTemplate;
  templateRevision: TemplateRevision;
  isEditable?: boolean;
};

export const TaskListItemAvatar = ({
  organizationMemberships,
  taskAssignmentRules,
  taskTemplate,
  templateRevision,
  isEditable = true,
}: TaskListItemAvatarProps) => {
  const { assignees, uniqueRules } = useTaskAssignments({ taskTemplate, templateRevision });
  const [firstOm] = organizationMemberships;
  const [firstTaskAssignmentRule] = taskAssignmentRules;

  const editorActor = useFormEditorPageActorRef();
  const sourceFormFieldWidgetSelector = React.useCallback(
    (state: StateFrom<FormEditorPageMachine>) => {
      return Option(firstTaskAssignmentRule?.sourceFormFieldWidgetGroup?.id)
        .map(groupId => FormEditorPageActorSelectors.getWidgetByGroupId(groupId)(state))
        .filter(isFormFieldWidget)
        .get();
    },
    [firstTaskAssignmentRule],
  );

  const sourceFormFieldWidget = useSelector(editorActor, sourceFormFieldWidgetSelector);

  if (!firstOm && !firstTaskAssignmentRule) return null;

  const iconProps = match<
    { taskAssignmentRule?: TaskAssignmentRule; organizationMembership?: OrganizationMembershipWithUser },
    { icon: CustomIconName; label: string; src?: string; initials?: string } | null
  >({ organizationMembership: firstOm, taskAssignmentRule: firstTaskAssignmentRule })
    .with({ organizationMembership: P.not(P.nullish) }, ({ organizationMembership }) => {
      const { url, initials, title } = getAvatar(organizationMembership.user);
      return {
        src: url,
        initials,
        label: title,
        icon: 'user',
      };
    })
    .with({ taskAssignmentRule: P.not(P.nullish) }, ({ taskAssignmentRule }) => {
      const { icon, label } = match<TaskAssignmentRule, { icon: CustomIconName; label: string }>(taskAssignmentRule)
        .with({ sourceType: TaskAssignmentRuleSourceType.ChecklistInitiator }, () => ({
          label: 'Workflow Runner',
          icon: 'user',
        }))
        .with(
          { sourceType: TaskAssignmentRuleSourceType.FormField, sourceFormFieldWidgetGroup: { id: P.string } },
          () => {
            if (!sourceFormFieldWidget) {
              return { label: '', icon: 'user' };
            }

            if (sourceFormFieldWidget.fieldType === FieldType.Email) {
              return { label: sourceFormFieldWidget.label ?? 'Email', icon: 'envelope' };
            }

            if (sourceFormFieldWidget.fieldType === FieldType.Members) {
              return { label: sourceFormFieldWidget.label ?? 'Members', icon: 'users' };
            }

            throw new Error(`Unsupported form field widget type: ${sourceFormFieldWidget.fieldType}`);
          },
        )
        .otherwise(() => {
          throw new Error('Unsupported task assignment rule');
        });
      return {
        icon,
        label,
      };
    })
    .otherwise(() => null);

  if (!iconProps) return null;

  const { src, initials, icon, label } = iconProps;
  const count = taskAssignmentRules.length + organizationMemberships.length - 1;
  const tooltipLabel =
    count > 0 ? `Assigned to ${label} and ${count} ${count > 1 ? 'others' : 'other'}` : `Assigned to ${label}`;

  return (
    <ViewAssigneesMenu
      templateRevision={templateRevision}
      taskTemplate={taskTemplate}
      rules={uniqueRules}
      assignees={assignees}
      isEditable={isEditable}
    >
      <Button variant="unstyled">
        <Tooltip label={tooltipLabel}>
          <Avatar
            src={src}
            name={firstOm ? label : undefined}
            getInitials={initials ? () => initials : undefined}
            icon={<Icon icon={icon} size="3" />}
            size="sm"
            aria-label={label}
          >
            {count > 0 && (
              <AvatarBadge borderColor="white" borderWidth="thin" bg="gray.500" boxSize="1.25em" fontSize="xs">
                {count + 1}
              </AvatarBadge>
            )}
          </Avatar>
        </Tooltip>
      </Button>
    </ViewAssigneesMenu>
  );
};
