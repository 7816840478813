import { TaskTemplate, CrossLinkWidget } from '@process-street/subgrade/process';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import {
  DeleteWidgetByHeaderIdMutation,
  DeleteWidgetByHeaderIdMutationResponse,
  UpdateFormFieldValueMutationResponse,
  UpdateWidgetMutation,
  UpdateWidgetMutationResponse,
  WidgetsByTemplateRevisionIdQuery,
} from 'features/widgets/query-builder';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { ActorRefFrom, assign, createMachine, send, sendParent } from 'xstate';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { WidgetEvent } from '../../../types';
import { SharedContext } from 'pages/forms/_id/shared';
import { GetNewestTemplateRevisionsByTemplateIdQuery } from 'features/template/query-builder';
import { match } from 'ts-pattern';
import { Muid } from '@process-street/subgrade/core';

type Context = {
  widget: CrossLinkWidget;
  initialWidget?: CrossLinkWidget;
  key?: string;
  recentlyMovedFrom?: TaskTemplate;
  templateId?: Muid;
  isReadOnly?: boolean;
};

type Event =
  | WidgetEvent<CrossLinkWidget>
  | { type: 'CHANGE'; templateId: Muid }
  | { type: 'FOCUS' }
  | { type: 'BLUR' }

  // Internal type
  | { type: 'done.invoke.updateWidget'; data: CrossLinkWidget };

export type PageContentMachine = ReturnType<typeof makePageContentMachine>;
export type PageContentActorRef = ActorRefFrom<PageContentMachine>;

export const makePageContentMachine = ({
  widget,
  sharedContext,
  isReadOnly = false,
}: {
  widget: CrossLinkWidget;
  sharedContext: SharedContext;
  isReadOnly?: boolean;
}) => {
  const { queryClient, templateId } = sharedContext;

  const cacheSetter = WidgetsByTemplateRevisionIdQuery.makeCacheSetter({
    queryClient,
    templateRevisionId: (widget.header.taskTemplate as TaskTemplate).templateRevision.id,
  });

  const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
    queryClient,
    templateId,
  });

  return createMachine(
    {
      id: `page-content-widget:${widget.id}`,
      initial: 'idle',
      predictableActionArguments: true,
      schema: {
        events: {} as Event,
        context: {} as Context,
        services: {} as {
          updateFormFieldValue: {
            data: UpdateFormFieldValueMutationResponse;
          };
          deleteWidgetMutation: {
            data: DeleteWidgetByHeaderIdMutationResponse;
          };
          updateWidgetMutation: {
            data: UpdateWidgetMutationResponse;
          };
        },
      },
      tsTypes: {} as import('./page-content-machine.typegen').Typegen0,
      context: {
        widget,
        initialWidget: widget,
        recentlyMovedFrom: undefined,
        templateId: undefined,
        isReadOnly,
      } as Context,
      type: 'parallel',
      states: {
        input: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                MOVE_UP: {
                  actions: ['sendMoveUp'],
                },
                MOVE_DOWN: {
                  actions: ['sendMoveDown'],
                },
                DUPLICATE: {
                  actions: ['sendDuplicate'],
                },
                FOCUS: {
                  target: 'active',
                },
                MOVE_TO_STEP: { actions: ['sendMoveToStep'] },
                MOVED_FROM_STEP: { actions: ['assignRecentlyMovedFrom'] },
                UPDATE_WIDGET_HEADER: { actions: ['assignHeader'] },
              },
            },
            active: {
              on: {
                BLUR: {
                  target: 'idle',
                },
                CHANGE: {
                  target: 'idle',
                  actions: ['assignTemplateId', 'sendUpdateWidgetMutationEvent'],
                },
              },
            },
            error: {
              on: {
                FOCUS: {
                  target: 'active',
                },
                CHANGE: {
                  target: 'idle',
                  actions: ['assignTemplateId', 'sendUpdateWidgetMutationEvent'],
                },
              },
            },
          },
        },
        mutation: {
          initial: 'idle',
          states: {
            idle: {
              id: 'idle',
              on: {
                DELETE_WIDGET: {
                  target: 'deleting',
                },
                UPDATE_WIDGET: {
                  target: 'updatingWidget',
                },
              },
            },
            deleting: {
              invoke: {
                src: 'deleteWidgetMutation',
                onDone: {
                  target: 'idle',
                },
                onError: {
                  target: 'idle',
                },
              },
            },
            updatingWidget: {
              invoke: {
                src: 'updateWidgetMutation',
                onDone: {
                  target: 'idle',
                },
                onError: {
                  target: 'idle',
                },
              },
            },
          },
        },
      },
    },
    {
      actions: {
        assignTemplateId: assign({
          widget: (context, e) => {
            if (!context.widget) {
              throw new Error('Widget is undefined');
            }

            return {
              ...(context.widget as CrossLinkWidget),
              templateId: e.templateId,
            };
          },
        }),
        sendMoveUp: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'up',
        })),
        sendMoveDown: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'down',
        })),
        sendDuplicate: sendParent(ctx => ({
          type: 'DUPLICATE_WIDGET',
          widget: ctx.widget,
        })),
        sendUpdateWidgetMutationEvent: send('UPDATE_WIDGET'),
        sendMoveToStep: sendParent((ctx, e) => ({
          type: 'MOVE_WIDGET_TO_STEP',
          widget: ctx.widget,
          from: e.from,
          to: e.to,
        })),
        assignHeader: assign({
          widget: (ctx, e) =>
            match(e)
              .with({ type: 'UPDATE_WIDGET_HEADER' }, ({ header }) => ({
                ...ctx.widget,
                header: {
                  ...ctx.widget?.header,
                  ...{ ...(header as CrossLinkWidget['header']) },
                },
              }))
              .otherwise(() => ctx.widget),
        }),
        assignRecentlyMovedFrom: assign({
          recentlyMovedFrom: (_ctx, e) =>
            match(e)
              .with({ type: 'MOVED_FROM_STEP' }, ({ from }) => ({
                ...from,
              }))
              .otherwise(() => undefined),
        }),
      },
      services: {
        updateWidgetMutation: async (context, evt) => {
          const widget = evt.widget ?? (context.widget as CrossLinkWidget);
          return makeMutation(queryClient, {
            mutationKey: UpdateWidgetMutation.getKey(),
            mutationFn: () => UpdateWidgetMutation.mutationFn<CrossLinkWidget>(widget!),
            onSuccess: widget => {
              cacheSetter.update(widget);
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
          }).execute();
        },
        deleteWidgetMutation: async context => {
          return makeMutation(queryClient, {
            mutationKey: DeleteWidgetByHeaderIdMutation.getKey(),
            mutationFn: () => DeleteWidgetByHeaderIdMutation.mutationFn(context.widget!.header.id),
            onSuccess: () => {
              cacheSetter.delete(context.widget!);
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
            onError: () => {
              ToastServiceImpl.openToast({
                status: 'error',
                title: `We're having problems deleting the widget`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            },
          }).execute();
        },
      },
    },
  );
};
