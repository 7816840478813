import { OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { isGroupUser } from '@process-street/subgrade/util/user-type-utils';
import { getAvatar } from 'components/common/Avatar/avatar-helper';
import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  ButtonGroup,
  HStack,
  Icon,
  IconButton,
  Input,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Text,
  VStack,
} from 'components/design/next';
import { useCurrentUser } from 'hooks/use-current-user';
import { LimitedList } from 'app/pages/templates/_id/components/assignment-picker/limited-list';
import * as React from 'react';
import { match, P } from 'ts-pattern';
import { MembershipItem } from './membership-item';

export type AssignmentPickerProps = {
  onSelect: (om: OrganizationMembershipWithUser) => void;
  onRemove: (om: OrganizationMembershipWithUser) => void;
  availableMemberships: OrganizationMembershipWithUser[];
  assignedMemberships: OrganizationMembershipWithUser[];
};

export const AssignmentPicker: React.FC<React.PropsWithChildren<AssignmentPickerProps>> = ({
  onSelect,
  onRemove,
  availableMemberships,
  assignedMemberships,
}) => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const currentUser = useCurrentUser();

  const totalAssigned = assignedMemberships.length;

  const lastUser = assignedMemberships[assignedMemberships.length - 1];
  const filteredMemberships = React.useMemo(() => {
    const pattern = searchQuery.trim().toLowerCase();

    if (pattern === '') return availableMemberships;

    return availableMemberships.filter(({ user }) => {
      return user.username.toLowerCase().includes(pattern) || user.email.toLowerCase().includes(pattern);
    });
  }, [searchQuery, availableMemberships]);

  const handleSelectAssignee = (om: OrganizationMembershipWithUser) => {
    onSelect(om);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <ButtonGroup
      bgColor="white"
      isAttached
      sx={{
        '> span:first-of-type:not(:nth-last-child(2)) > button': {
          borderEndRadius: 0,
        },
        '> button:not(:only-child)': { borderStartRadius: 0, ml: '-px', borderLeftRadius: '3px' },
      }}
    >
      <Popover>
        <PopoverTrigger>
          <Button
            display="flex"
            aria-label="One-off task assignment picker"
            color="gray.500"
            size="sm"
            iconSpacing="0"
            variant="outline"
            borderWidth="px"
            borderColor="gray.300"
            leftIcon={<Icon icon="user" variant="far" size="4"></Icon>}
          />
        </PopoverTrigger>
        <PopoverContent py="3" w="sm" zIndex="popover" textAlign="left">
          <PopoverHeader p="0" mb="1" mx="3">
            <Text color="gray.700" fontWeight="medium">
              Assign to Task
            </Text>
            <PopoverCloseButton />
          </PopoverHeader>
          <PopoverBody>
            <>
              <Box>
                <Input
                  placeholder="Enter name or email"
                  border="1px solid"
                  borderColor="gray.300"
                  backgroundColor="white"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </Box>
              <LimitedList
                items={filteredMemberships}
                onSelect={handleSelectAssignee}
                Item={MembershipItem}
                title="Users and Groups"
                boxProps={{ pt: '4' }}
              />
              {filteredMemberships.length === 0 && (
                <Text px="4" color="gray.600">
                  No users match that text.
                </Text>
              )}
            </>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Popover>
        <PopoverTrigger>
          <IconButton
            aria-label="Assignees"
            icon={
              <Avatar
                size="xs"
                bg="gray.300"
                {...match(lastUser?.user)
                  .with({ avatarUrl: P.string }, { avatarFile: { id: P.string } }, { username: P.string }, user => {
                    const avatar = getAvatar(user);
                    return {
                      src: avatar.url,
                      name: user.username,
                    };
                  })
                  .otherwise(() => ({}))}
              >
                {totalAssigned > 1 && (
                  <AvatarBadge fontSize="8px" h="1em" minW="1em" px="0.5" bg="gray.400" border="none">
                    {totalAssigned}
                  </AvatarBadge>
                )}
              </Avatar>
            }
            {...{
              iconSpacing: '0',
              variant: 'outline',
              size: 'sm',
              borderWidth: 'px',
              borderColor: 'gray.300',
              px: '6px',
              justifyContent: 'center',
            }}
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader>
            <Text color="gray.700" fontWeight="medium">
              Assignees
            </Text>

            <PopoverCloseButton color="gray.500" />
          </PopoverHeader>
          <PopoverArrow />
          <PopoverBody>
            <VStack as={List} alignItems="flex-start" w="full" mb="0" spacing="4" p="0" minH="11">
              {assignedMemberships.map(assignee => {
                const isDisabled =
                  Boolean(currentUser) &&
                  assignedMemberships.length === 1 &&
                  assignedMemberships[0].user.id === currentUser?.id;
                return (
                  <AssignmentItem
                    isDisabled={isDisabled}
                    organizationMembership={assignee}
                    onRemoveAssignment={onRemove}
                    key={assignee.id}
                  />
                );
              })}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </ButtonGroup>
  );
};

export type AssignmentItemProps = {
  isDisabled: boolean;
  organizationMembership: OrganizationMembershipWithUser;
  onRemoveAssignment: (om: OrganizationMembershipWithUser) => void;
};

export const AssignmentItem: React.FC<React.PropsWithChildren<AssignmentItemProps>> = ({
  isDisabled,
  organizationMembership,
  onRemoveAssignment,
}) => {
  const { user } = organizationMembership;
  const avatar = getAvatar(organizationMembership.user);

  const handleOnRemoveUser = () => {
    onRemoveAssignment(organizationMembership);
  };
  return (
    <HStack as={ListItem} w="full" p="0">
      <Avatar src={avatar.url} name={user.username} />
      <VStack alignItems="start" w="full">
        <Text fontWeight="medium" fontSize="md">
          {user.username}
        </Text>
        <Text fontSize="sm" color="gray.400">
          {isGroupUser(user) ? 'Group' : user.email}
        </Text>
      </VStack>
      <Spacer />
      <IconButton
        isDisabled={isDisabled}
        aria-label="Remove assignment"
        icon={<Icon icon="user-xmark" size="4" />}
        onClick={handleOnRemoveUser}
        variant="ghost"
        size="sm"
        color="gray.300"
        colorScheme="gray"
      />
    </HStack>
  );
};
