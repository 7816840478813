import { Box, Center, Divider, MotionWrapper, Text, VStack } from 'components/design/next';
import { WidgetsList } from 'app/pages/forms/_id/edit/components';
import { AnimatePresence } from 'framer-motion';
import * as React from 'react';
import { ApprovalTask } from '../approval-task';
import { AiTaskTemplateForm } from 'app/pages/templates/_id/components/ai-task-template-form';
import { TaskTemplate, TaskTemplateTaskType, Template, TemplateRevision } from '@process-street/subgrade/process';
import { Muid } from '@process-street/subgrade/core';
import { match } from 'ts-pattern';
import { TaskActionsBar } from '../task-actions-bar';
import { TaskNameInput } from '../task-name-input';
import {
  FormEditorPageActorSelectors,
  useFormEditorPageActorRef,
} from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { useSelector } from '@xstate/react';

export type TaskContentRendererProps = {
  taskTemplate: TaskTemplate;
  taskTemplateGroupId: Muid;
  template: Template;
  templateRevision: TemplateRevision;
};

const motionWrapperProps = {
  initial: { y: -10, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: 10, opacity: 0 },
  transition: { duration: 0.3 },
};

export const TaskContentRenderer = ({
  taskTemplate,
  template,
  templateRevision,
  taskTemplateGroupId,
}: TaskContentRendererProps) => {
  const actor = useFormEditorPageActorRef();
  const isReadOnly = useSelector(actor, FormEditorPageActorSelectors.isReadOnly);

  return (
    <AnimatePresence>
      <Center w="full" pb="8">
        <MotionWrapper key={taskTemplateGroupId} {...motionWrapperProps}>
          <Box
            bgColor="white"
            borderColor="gray.200"
            borderStyle="solid"
            borderWidth="1px"
            borderRadius={{ base: '0', lg: 'lg' }}
            pt={9}
            px={{ base: '10', lg: '11' }}
            w="full"
            maxW={{ base: 'full', lg: '788px' }}
            sx={{
              '.blvd-select__ellipsis': { fontSize: 'var(--ps-fontSizes-md)' },
              '[data-component="MaskedInput"]': { fontSize: 'var(--ps-fontSizes-md)' },
            }}
          >
            {!isReadOnly && (
              <TaskActionsBar taskTemplate={taskTemplate} templateRevision={templateRevision} template={template} />
            )}

            <VStack spacing="6" w="full" h="auto" mt={6}>
              <VStack w="full" alignItems="flex-start" spacing={2}>
                {isReadOnly ? (
                  <Text
                    fontWeight="medium"
                    fontSize="xl"
                    color="gray.700"
                    minH="6"
                    noOfLines={1}
                    _hover={{
                      cursor: 'text',
                    }}
                  >
                    {taskTemplate.name}
                  </Text>
                ) : (
                  <TaskNameInput />
                )}

                <Divider />
              </VStack>
            </VStack>
            {taskTemplate &&
              match(taskTemplate.taskType)
                .with(TaskTemplateTaskType.Approval, () => <ApprovalTask />)
                .with(TaskTemplateTaskType.Standard, () => <WidgetsList py={6} />)
                .with(TaskTemplateTaskType.AI, () => <AiTaskTemplateForm showHeader={false} />)
                // Unlikely scenario but default to WidgetsList if taskType is not recognized
                .otherwise(() => <WidgetsList py={6} />)}
          </Box>
        </MotionWrapper>
      </Center>
    </AnimatePresence>
  );
};
