import { Muid } from '@process-street/subgrade/core';
import { RawParams, StateDeclaration } from '@uirouter/angularjs';
import { useInjector } from 'components/injection-provider';
import { GetChecklistQuery } from 'features/checklists/query-builder';
import { useOnStateChangeSuccess } from 'hooks/use-on-state-change-success';
import * as React from 'react';
import { useMount, usePromise, useUnmount } from 'react-use';
import { useLazyQuery } from 'utils/query-builder';
import { AppState } from 'app/app.states.typegen';

const STATES_WITH_TEMPLATE_ID: string[] = [
  'template',
  'templateV2',
  'templateView',
  'templateViewV2',
  'form',
  'formSettings',
  'templateDashboard',
] satisfies AppState[];
const useGetTemplateIdFromState = () => {
  const getChecklistLazy = useLazyQuery(GetChecklistQuery);

  return React.useCallback(
    async (state: StateDeclaration, params: RawParams) => {
      const stateName = state.name?.split('.')?.[0] ?? '';
      if (STATES_WITH_TEMPLATE_ID.includes(stateName) && params.id) {
        return params.id;
      } else if (['checklist'].includes(stateName) && params.id) {
        try {
          const checklistId = params.id as Muid;
          const checklist = await getChecklistLazy({ checklistId });
          return checklist.template.id;
        } catch {
          // If it failed for whatever reason, return undefined
          return undefined;
        }
      } else {
        return undefined;
      }
    },
    [getChecklistLazy],
  );
};

export const useTemplateId = (): Muid | undefined => {
  const { $stateParams, $state } = useInjector('$stateParams', '$state');
  const getTemplateIdFromState = useGetTemplateIdFromState();

  const [templateId, setTemplateId] = React.useState<Muid | undefined>();
  const isMountedRef = React.useRef(true);
  useMount(() => {
    isMountedRef.current = true;
    getTemplateIdFromState($state.current, $stateParams).then(
      templateId => isMountedRef.current && setTemplateId(templateId),
    );
  });
  useUnmount(() => {
    isMountedRef.current = false;
  });

  const isMountedPromise = usePromise();

  useOnStateChangeSuccess(({ toState, toParams }) => {
    isMountedPromise(getTemplateIdFromState(toState, toParams)).then(templateId => {
      return isMountedRef.current && setTemplateId(templateId);
    });
  });

  return templateId;
};
