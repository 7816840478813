import { Actor, assign, setup } from 'xstate5';

export type Context = {
  isFromTemplateGallery: boolean;
  needsToReload: boolean;
};

export type TemplateSettingsState = {
  value: 'createFirstWorkflow' | 'workflowSetup' | 'preselectTrigger' | 'addTasks' | 'finished';
  context: Context;
};

export type TemplateSettingsEvent =
  | {
      type: 'NEXT';
    }
  | {
      type: 'GO_TO_TRIGGERS';
    }
  | {
      type: 'GO_TO_TASKS';
    }
  | {
      type: 'SET_CONTEXT';
      context: Partial<Context>;
    }
  | {
      type: 'FINISH';
    };

/** Side effects implemented in React */
export enum TemplateSettingsActions {
  ON_FINISHED = 'onFinished',
  MUTATE_FIRST_WORKFLOW_SETTING = 'mutateFirstWorkflowSetting',
  SET_MODAL_SIZE_2XL = 'setModalSizeXXL',
  RESET_MODAL_SIZE = 'resetModalSize',
}

/**
 * Visualize the machine by pasting this file into [the XState visualizer](https://stately.ai/viz)
 * */
export const templateSettingsMachine = setup({
  types: {} as {
    context: Context;
    events: TemplateSettingsEvent;
    guards: { isFromTemplateGallery: (context: Context) => boolean };
  },
  actions: {
    [TemplateSettingsActions.MUTATE_FIRST_WORKFLOW_SETTING]: () => {},
    [TemplateSettingsActions.ON_FINISHED]: () => {},
  },
  actors: {},
}).createMachine({
  id: 'templateSettingsModal',
  initial: 'createFirstWorkflow',
  context: {
    isFromTemplateGallery: false,
    needsToReload: false,
  },
  on: {
    SET_CONTEXT: {
      actions: assign(({ context, event }) => ({ ...context, ...event.context })),
    },
  },
  states: {
    createFirstWorkflow: {
      on: {
        NEXT: 'workflowSetup',
      },
    },
    workflowSetup: {
      entry: TemplateSettingsActions.MUTATE_FIRST_WORKFLOW_SETTING,
      on: {
        GO_TO_TRIGGERS: 'preselectTrigger',
        GO_TO_TASKS: 'addTasks',
      },
    },
    preselectTrigger: {
      on: {
        NEXT: 'finished',
        FINISH: 'finished',
      },
    },
    addTasks: {
      always: [
        {
          target: 'preselectTrigger',
          guard: ({ context }) => context.isFromTemplateGallery,
        },
      ],
      on: {
        NEXT: {
          target: 'preselectTrigger',
        },
        FINISH: 'finished',
      },
    },
    finished: {
      entry: TemplateSettingsActions.ON_FINISHED,
      type: 'final',
    },
  },
});

export type TemplateSettingsMachineActor = Actor<typeof templateSettingsMachine>;
