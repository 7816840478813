import { ImageWidget, TaskTemplate } from '@process-street/subgrade/process';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import {
  DeleteWidgetByHeaderIdMutation,
  DeleteWidgetByHeaderIdMutationResponse,
  UpdateFormFieldValueMutationResponse,
  UpdateWidgetMutation,
  UpdateWidgetMutationResponse,
  UploadToS3Mutation,
  UploadToS3MutationResponse,
  WidgetsByTemplateRevisionIdQuery,
} from 'features/widgets/query-builder';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { ActorRefFrom, assign, createMachine, send, sendParent } from 'xstate';
import { makeMutation } from 'utils/query-builder/make-mutation';
import {
  CreateWidgetUploadUrlMutation,
  CreateWidgetUploadUrlMutationResponse,
} from 'features/widgets/query-builder/create-widget-upload-url';
import {
  FinishWidgetUploadMutation,
  FinishWidgetUploadMutationResponse,
} from 'features/widgets/query-builder/finish-widget-upload';
import { readFile } from 'features/comments/components/common/attachment/use-upload-attachment-comment';
import { KeyboardEvent } from 'react';
import { WidgetEvent } from '../../../types';
import { SharedContext } from 'pages/forms/_id/shared';
import { GetNewestTemplateRevisionsByTemplateIdQuery } from 'features/template/query-builder';
import { match } from 'ts-pattern';

type Context = {
  widget?: ImageWidget;
  initialWidget?: ImageWidget;
  file?: File;
  key?: string;
  uploadUrl?: string;
  isImageLoaded: boolean;
  recentlyMovedFrom?: TaskTemplate;
  inputNode: HTMLElement | null;
  isReadOnly?: boolean;
};

type Event =
  | WidgetEvent<ImageWidget>
  | { type: 'CHANGE'; value: File }
  | { type: 'FOCUS' }
  | { type: 'BLUR' }
  | { type: 'CAPTION_FOCUS' }
  | { type: 'CAPTION_BLUR' }
  | { type: 'CAPTION_CHANGE'; value: string }
  | { type: 'CAPTION_KEY_DOWN'; event: KeyboardEvent<HTMLInputElement> }
  | { type: 'TOGGLE_CAPTION_EDIT' }
  | { type: 'UPLOAD' }
  | { type: 'IMAGE_LOADED' }
  // Internal type
  | { type: 'done.invoke.updateWidget'; data: ImageWidget | undefined };

export type ImageContentMachine = ReturnType<typeof makeImageContentMachine>;
export type ImageContentActorRef = ActorRefFrom<ImageContentMachine>;

export const makeImageContentMachine = ({
  widget,
  sharedContext,
  isReadOnly = false,
}: {
  widget: ImageWidget;
  sharedContext: SharedContext;
  isReadOnly?: boolean;
}) => {
  const { queryClient, templateId } = sharedContext;

  const cacheSetter = WidgetsByTemplateRevisionIdQuery.makeCacheSetter({
    queryClient,
    templateRevisionId: (widget.header.taskTemplate as TaskTemplate).templateRevision.id,
  });

  const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
    queryClient,
    templateId,
  });

  return createMachine(
    {
      id: `image-content-widget:${widget.id}`,
      initial: 'idle',
      predictableActionArguments: true,
      schema: {
        events: {} as Event,
        context: {} as Context,
        services: {} as {
          updateFormFieldValue: {
            data: UpdateFormFieldValueMutationResponse;
          };
          createWidgetUploadUrlMutation: {
            data: CreateWidgetUploadUrlMutationResponse;
          };
          uploadToS3Mutation: {
            data: UploadToS3MutationResponse;
          };
          finishUploadMutation: {
            data: FinishWidgetUploadMutationResponse<ImageWidget>;
          };
          deleteWidgetMutation: {
            data: DeleteWidgetByHeaderIdMutationResponse;
          };
          updateWidgetMutation: {
            data: UpdateWidgetMutationResponse;
          };
        },
      },
      tsTypes: {} as import('./image-content-machine.typegen').Typegen0,
      context: {
        widget,
        initialWidget: widget,
        isImageLoaded: false,
        recentlyMovedFrom: undefined,
        isReadOnly,
      } as Context,
      type: 'parallel',
      states: {
        input: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                MOVE_UP: { actions: ['sendMoveUp'] },
                MOVE_DOWN: { actions: ['sendMoveDown'] },
                DUPLICATE: { actions: ['sendDuplicate'] },
                FOCUS: { target: 'active' },
                IMAGE_LOADED: { actions: ['assignIsImageLoadedTrue'] },
                MOVE_TO_STEP: { actions: ['sendMoveToStep'] },
                MOVED_FROM_STEP: { actions: ['assignRecentlyMovedFrom'] },
                UPDATE_WIDGET_HEADER: { actions: ['assignHeader'] },
                SET_NODE: { actions: ['assignNode'] },
                SCROLL_INTO_VIEW: { actions: ['scrollIntoView'] },
              },
            },
            active: {
              on: {
                BLUR: {
                  target: 'idle',
                },
                CHANGE: {
                  target: 'idle',
                  actions: ['assignFile', 'sendStartUploadMutationEvent', 'assignIsImageLoadedFalse'],
                },
              },
            },
          },
        },
        caption: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                TOGGLE_CAPTION_EDIT: {
                  target: 'editing',
                },
              },
            },
            editing: {},
            debouncing: {
              after: {
                DEBOUNCE_DELAY: {
                  actions: ['sendUpdateWidgetMutationEvent'],
                  target: 'editing',
                },
              },
            },
          },
          on: {
            CAPTION_CHANGE: {
              target: '.debouncing',
              actions: ['assignCaption'],
            },
            CAPTION_BLUR: {
              target: '.idle',
              actions: ['sendUpdateWidgetMutationEvent'],
            },
            CAPTION_KEY_DOWN: {
              target: '.idle',
              cond: 'pressedEnter',
              actions: ['sendUpdateWidgetMutationEvent'],
            },
          },
        },
        mutation: {
          initial: 'idle',
          states: {
            idle: {
              id: 'idle',
              on: {
                UPLOAD: {
                  target: 'uploading.creatingUploadUrl',
                  cond: 'hasFile',
                },
                DELETE_WIDGET: {
                  target: 'deleting',
                },
                UPDATE_WIDGET: {
                  target: 'updatingWidget',
                },
              },
            },
            uploading: {
              initial: 'creatingUploadUrl',
              states: {
                creatingUploadUrl: {
                  invoke: {
                    src: 'createWidgetUploadUrlMutation',
                    onDone: {
                      target: 'uploadingToS3',
                      actions: ['assignUploadUrl', 'assignKey'],
                      cond: 'hasUploadUrl',
                    },
                    onError: {
                      target: '#idle',
                      actions: ['showUploadErrorToast'],
                    },
                  },
                },
                uploadingToS3: {
                  invoke: {
                    src: 'uploadToS3Mutation',
                    onDone: {
                      target: 'finishingUpload',
                      cond: 'hasKey',
                    },
                    onError: {
                      target: '#idle',
                      actions: ['showUploadErrorToast'],
                    },
                  },
                },
                finishingUpload: {
                  invoke: {
                    src: 'finishUploadMutation',
                    onDone: {
                      target: 'completed',
                      actions: ['assignWidget', 'updateCache'],
                    },
                    onError: {
                      target: '#idle',
                      actions: ['showUploadErrorToast'],
                    },
                  },
                },
                completed: {
                  after: {
                    UPLOAD_SUCCESS_DELAY: '#idle',
                  },
                },
              },
            },
            deleting: {
              invoke: {
                src: 'deleteWidgetMutation',
                onDone: {
                  target: 'idle',
                },
                onError: {
                  target: 'idle',
                },
              },
            },
            updatingWidget: {
              invoke: {
                src: 'updateWidgetMutation',
                onDone: {
                  target: 'idle',
                },
                onError: {
                  target: 'idle',
                },
              },
            },
          },
        },
      },
    },
    {
      guards: {
        hasFile: ctx => Boolean(ctx.file),
        hasUploadUrl: (ctx, e) => Boolean(ctx.uploadUrl) || Boolean(e.data.url),
        hasKey: ctx => Boolean(ctx.key),
        pressedEnter: (_, e) => e.event.key === 'Enter',
      },
      actions: {
        sendMoveUp: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'up',
        })),
        sendMoveDown: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'down',
        })),
        sendDuplicate: sendParent(ctx => ({
          type: 'DUPLICATE_WIDGET',
          widget: ctx.widget,
        })),
        assignUploadUrl: assign({
          uploadUrl: (_, e) => e.data.url,
        }),
        assignKey: assign({
          key: (_, e) => e.data.key,
        }),
        assignFile: assign({
          file: (_, e) => e.value,
        }),
        assignWidget: assign({
          widget: (_, e) => e.data,
        }),
        sendStartUploadMutationEvent: send('UPLOAD'),
        showUploadErrorToast: () => {
          ToastServiceImpl.openToast({
            status: 'error',
            title: "We're having problems uploading the file",
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        },
        updateCache: (_, e) => {
          cacheSetter.update(e.data);
        },
        assignIsImageLoadedTrue: assign({
          isImageLoaded: () => true,
        }),
        assignIsImageLoadedFalse: assign({
          isImageLoaded: () => false,
        }),
        assignCaption: assign({
          widget: (context, e) => {
            if (!context.widget) return;

            return {
              ...context.widget,
              caption: e.value,
            };
          },
        }),
        sendUpdateWidgetMutationEvent: send('UPDATE_WIDGET'),
        sendMoveToStep: sendParent((ctx, e) => ({
          type: 'MOVE_WIDGET_TO_STEP',
          widget: ctx.widget,
          from: e.from,
          to: e.to,
        })),
        assignHeader: assign({
          // @ts-expect-error -- TODO
          widget: (ctx, e) =>
            match(e)
              .with({ type: 'UPDATE_WIDGET_HEADER' }, ({ header }) => ({
                ...ctx.widget,
                header: {
                  ...ctx.widget?.header,
                  ...{ ...(header as ImageWidget['header']) },
                },
              }))
              .otherwise(() => ctx.widget),
        }),
        assignNode: assign({ inputNode: (_, evt) => evt.node }),
        scrollIntoView: ctx => {
          ctx.inputNode?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        },
        assignRecentlyMovedFrom: assign({
          recentlyMovedFrom: (_ctx, e) =>
            match(e)
              .with({ type: 'MOVED_FROM_STEP' }, ({ from }) => ({
                ...from,
              }))
              .otherwise(() => undefined),
        }),
      },
      services: {
        updateWidgetMutation: async (context, evt) => {
          const widget = evt.widget ?? context.widget;
          return makeMutation(queryClient, {
            mutationKey: UpdateWidgetMutation.getKey(),
            mutationFn: () => UpdateWidgetMutation.mutationFn<ImageWidget>(widget!),
            onSuccess: widget => {
              cacheSetter.update(widget);
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
          }).execute();
        },
        createWidgetUploadUrlMutation: async (ctx, _) => {
          return makeMutation(queryClient, {
            mutationKey: CreateWidgetUploadUrlMutation.key,
            mutationFn: () => {
              if (!ctx.file) throw new Error('file is missing');
              if (!ctx.widget) throw new Error('widget is missing');

              return CreateWidgetUploadUrlMutation.mutationFn({
                fileName: ctx.file.name,
                mimeType: ctx.file.type,
                headerId: ctx.widget.header.id,
              });
            },
          }).execute();
        },
        uploadToS3Mutation: async (ctx, _) => {
          const fileBuffer = await readFile(ctx.file!);

          return makeMutation(queryClient, {
            mutationKey: UploadToS3Mutation.key,
            mutationFn: () => {
              if (!ctx.uploadUrl) throw new Error('uploadUrl is missing');
              if (!ctx.file) throw new Error('file is missing');

              return UploadToS3Mutation.mutationFn({
                url: ctx.uploadUrl,
                file: ctx.file,
                data: fileBuffer,
              });
            },
          }).execute();
        },
        finishUploadMutation: async (ctx, _) => {
          return makeMutation(queryClient, {
            mutationKey: FinishWidgetUploadMutation.key,
            mutationFn: () => {
              if (!ctx.key) throw new Error('key is missing');
              if (!ctx.file) throw new Error('file is missing');
              if (!ctx.widget) throw new Error('widget is missing');

              return FinishWidgetUploadMutation.mutationFn<ImageWidget>({
                key: ctx.key,
                contentType: ctx.file.type,
                headerId: ctx.widget.header.id,
                originalFilename: ctx.file.name,
              });
            },
            onSuccess: () => {
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
          }).execute();
        },
        deleteWidgetMutation: async context => {
          return makeMutation(queryClient, {
            mutationKey: DeleteWidgetByHeaderIdMutation.getKey(),
            mutationFn: () => DeleteWidgetByHeaderIdMutation.mutationFn(context.widget!.header.id),
            onSuccess: () => {
              cacheSetter.delete(context.widget!);
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
            onError: () => {
              ToastServiceImpl.openToast({
                status: 'error',
                title: `We're having problems deleting the widget`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            },
          }).execute();
        },
      },
      delays: {
        DEBOUNCE_DELAY: 500,
        UPLOAD_SUCCESS_DELAY: 1000,
      },
    },
  );
};
