import * as React from 'react';
import { useField, useFormikContext } from 'formik';
import {
  FormControl,
  FormErrorMessage,
  Icon,
  InputGroup,
  Textarea as ChakraTextarea,
  TextareaProps,
  FormHelperText,
  InputRightElement,
} from 'components/design/next';
import { formikMetaToFormControlProps } from '../common/utils';
import Textarea from 'react-autosize-textarea';
import { FormFieldWidget, TemplateRevision, WidgetUtils } from '@process-street/subgrade/process';
import { useMergeTaggableInput } from 'app/hooks/use-merge-taggable-input';
import { MergeTagsMenu, MergeTagsMenuButton } from 'app/features/merge-tags/components/merge-tags-menu';
import { MergeTagsConstants } from '@process-street/subgrade/form';
import { TextareaWFSettingsSchema } from 'app/components/widgets/form-field/settings/textarea-settings/textarea-workflow-settings-schema';
import { useDebouncedCallback } from 'use-debounce';

export const DEBOUNCE_DELAY = 500;

export type InlineDefaultValueProps = {
  templateRevisionId: TemplateRevision['id'];
  label?: string;
  widget: FormFieldWidget;
} & TextareaProps;

export const InlineDefaultValueField: React.FC<InlineDefaultValueProps> = ({
  label = 'Default answer',
  placeholder,
  widget,
  templateRevisionId,
  isDisabled,
  ...props
}) => {
  const [field, meta] = useField<string>(InlineDefaultValueFieldName);
  const { values, setValues, handleSubmit } = useFormikContext<TextareaWFSettingsSchema>();

  const debouncedSubmit = useDebouncedCallback(() => {
    handleSubmit();
  }, DEBOUNCE_DELAY);

  const { ref: defaultValueRef, insertMergeTag: insertVariable } = useMergeTaggableInput<HTMLTextAreaElement>({
    get: () => field.value ?? '',
    set: value => {
      setValues({
        ...values,
        config: {
          ...values.config,
          defaultValue: value,
        },
        hasVariables: WidgetUtils.hasVariables(value),
      });
    },
  });
  const hasVariables = WidgetUtils.hasVariables(field.value);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setValues({
      ...values,
      config: {
        ...values.config,
        defaultValue: value,
      },
      hasVariables,
    });
    props.onChange?.(e);
    debouncedSubmit();
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    field.onBlur(e);
    debouncedSubmit.flush();
  };

  return (
    <FormControl {...formikMetaToFormControlProps(meta)}>
      <InputGroup>
        <Icon icon="eye-slash" size="4" color="gray.400" position="absolute" left={4} top="10px" />
        <ChakraTextarea
          as={Textarea}
          {...field}
          ref={defaultValueRef}
          value={field.value ?? ''}
          aria-label="optional instructions about field requirements"
          placeholder={placeholder ? placeholder : 'Type default answer here'}
          variant="outline"
          onChange={handleChange}
          onBlur={handleOnBlur}
          borderStyle="dashed"
          borderWidth="1px"
          borderColor="gray.300"
          pl={10}
          isDisabled={isDisabled}
        />
        <InputRightElement _focusWithin={{ zIndex: 3 }}>
          {!isDisabled && (
            <MergeTagsMenu
              {...{
                templateRevisionId,
                onSelect: (key, _fieldId, fallback) => insertVariable(key, fallback),
                mergeTagTarget: MergeTagsConstants.Target.GENERAL,
                menuButton: <MergeTagsMenuButton size="sm" bg="white" />,
              }}
            />
          )}
        </InputRightElement>
      </InputGroup>
      {hasVariables ? <FormHelperText>Validation disabled due to {'{{ variables }}'}</FormHelperText> : null}
      {meta.error ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
    </FormControl>
  );
};

export const InlineDefaultValueFieldName = 'config.defaultValue';
