import * as React from 'react';
import { TaskTemplate, TemplateRevision, Template } from '@process-street/subgrade/process';
import {
  SolutionInstancesTooltip,
  TaskSolutionInstanceItem,
} from 'app/features/automations/components/task-automation-indicator';
import { useSolutionTypeTagInstances } from 'app/pages/templates/_id/automation/components/selector/use-solution-type-tag-instances';
import { Center, HStack } from '@chakra-ui/react';

export type TaskListItemAutomationIndicatorProps = {
  taskTemplate: TaskTemplate;
  templateRevision: TemplateRevision;
  templateId: Template['id'];
  editable?: boolean;
  isActive: boolean;
  isDisabled?: boolean;
};

export const TaskListItemAutomationIndicator = ({
  templateId,
  taskTemplate,
  templateRevision,
  editable = true,
}: TaskListItemAutomationIndicatorProps) => {
  const {
    instances: { ps_when_task_checked_then },
  } = useSolutionTypeTagInstances({ templateId });

  const allTaskSolutionInstances = ps_when_task_checked_then.filter(
    i => i.taskTemplateGroupId === taskTemplate.group.id && i.configured && i.enabled,
  );

  const taskSolutionInstancesToShow = allTaskSolutionInstances?.slice(0, 2);

  if (!taskSolutionInstancesToShow.length) return null;

  return (
    <SolutionInstancesTooltip
      templateRevision={templateRevision}
      taskTemplate={taskTemplate}
      notVisibleCount={allTaskSolutionInstances.length}
    >
      <HStack spacing={0}>
        {taskSolutionInstancesToShow.map((templateSolutionInstance, index, array) => {
          return (
            <Center
              key={templateSolutionInstance.id}
              borderRadius="full"
              bgColor="white"
              borderWidth="thin"
              borderColor="gray.200"
              borderStyle="solid"
              minW="7.5"
              minH="7.5"
              _notFirst={{ ml: -5 }}
              zIndex={array.length - index - 1}
            >
              <TaskSolutionInstanceItem
                templateRevision={templateRevision}
                taskTemplate={taskTemplate}
                isDisabled={false}
                editable={editable}
                isLast={false}
                isSelected={false}
                notVisibleCount={0}
                templateSolutionInstance={templateSolutionInstance}
                isTooltipDisabled
                w="30px"
                h="30px"
              />
            </Center>
          );
        })}
      </HStack>
    </SolutionInstancesTooltip>
  );
};
