import * as React from 'react';
import { useActor } from '@xstate/react';
import { MembersFormFieldActor } from './members-form-field-machine';
import { InputGroup, VStack } from 'components/design/next';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { FormFieldLabel } from '../common/form-field-label';
import { SettingsModalFields } from 'pages/forms/_id/edit/components/form-fields/common/settings/fields';
import { WidgetActorProvider } from '../../../../shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { FormFieldRecentlyMovedIndicator } from '../common/form-field-recently-moved-indicator';
import { InlineSettings } from '../common/settings/inline-settings';
import { membersSettingsSchema } from './members-form-field-schema';

export type MembersFormFieldWidgetProps = {
  isFirst: boolean;
  isLast: boolean;
  actor: MembersFormFieldActor;
};

export const MembersFormFieldWidget = React.memo(({ actor, isFirst, isLast }: MembersFormFieldWidgetProps) => {
  const [state, send] = useActor(actor);
  const { widget, labelActor, recentlyMovedFrom, isReadOnly } = state.context;
  const ref = React.useRef<HTMLDivElement | null>(null);

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer>
        <VStack alignItems="flex-start" flex="1">
          {recentlyMovedFrom && <FormFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
          {labelActor && <FormFieldLabel isReadOnly={isReadOnly} actor={labelActor} />}
          <InputGroup
            w="full"
            ref={node => {
              ref.current = node;
              if (node && !state.context.inputNode) {
                send({ type: 'SET_NODE', node });
              }
            }}
          >
            <InlineSettings widget={widget} schema={membersSettingsSchema}>
              <SettingsModalFields.Members isDisabled={isReadOnly} id={`form-field-widget-${widget.id}`} />
            </InlineSettings>
            {!isReadOnly && (
              <>
                <WidgetListItemDragIcon />
                <FormsWidgetMenu>
                  <FormsWidgetMenuItems.Required widget={widget} />
                  <FormsWidgetMenuItems.ConditionalLogic widget={widget} />
                  <FormsWidgetMenuItems.Divider />
                  <FormsWidgetMenuItems.Duplicate />
                  <FormsWidgetMenuItems.MoveToStep widget={widget} />
                  <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
                  <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
                  <FormsWidgetMenuItems.Delete />
                </FormsWidgetMenu>
              </>
            )}
          </InputGroup>
        </VStack>
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
});
