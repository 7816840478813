import * as React from 'react';
import { EmailContentActorRef } from './email-content-machine';
import { useActor } from '@xstate/react';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { ContentFieldRecentlyMovedIndicator } from '../common/content-field-recently-moved-indicator';
import { SendRichEmailTemplateWidget } from 'app/features/widgets/components/send-rich-email';
import { emailContentSettingsSchema } from './email-content-schema';
import { SettingsModalHeader } from '../../form-fields/common/settings/settings-modal-content';
import { StringUtils } from '@process-street/subgrade/util';
import { SettingsModalFields } from '../../form-fields/common/settings/fields';
import { Box } from 'components/design/next';

export interface EmailContentProps {
  actor: EmailContentActorRef;
  isFirst?: boolean;
  isLast?: boolean;
}

const DEFAULT_EMAIL_CONTENT_LABEL = 'Untitled send email field';

export const EmailContent: React.FC<React.PropsWithChildren<EmailContentProps>> = ({
  actor,
  isFirst = false,
  isLast = false,
}) => {
  const [current, send] = useActor(actor);
  const { widget, recentlyMovedFrom, templateRevision, isReadOnly } = current.context;
  const ref = React.useRef<HTMLDivElement | null>(null);

  if (!templateRevision) return null;
  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer alignItems="flex-start">
        {recentlyMovedFrom && <ContentFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
        <Box
          w="full"
          mt={2}
          ref={node => {
            ref.current = node;
            if (node && !current.context.inputNode) {
              send({ type: 'SET_NODE', node });
            }
          }}
        >
          <SendRichEmailTemplateWidget
            widget={widget}
            isDisabled={isReadOnly ?? false}
            onUpdate={({ widget }) => send({ type: 'UPDATE_WIDGET', widget })}
            templateRevision={templateRevision}
          />
        </Box>
        {!isReadOnly && (
          <>
            <WidgetListItemDragIcon />
            <FormsWidgetMenu>
              <FormsWidgetMenuItems.Settings widget={widget} schema={emailContentSettingsSchema}>
                <SettingsModalHeader>
                  {StringUtils.getNonEmpty(widget?.label, DEFAULT_EMAIL_CONTENT_LABEL)}
                </SettingsModalHeader>
                <SettingsModalFields.EmailSettings widget={widget} />
              </FormsWidgetMenuItems.Settings>
              <FormsWidgetMenuItems.Required widget={widget} />
              <FormsWidgetMenuItems.Duplicate />
              <FormsWidgetMenuItems.MoveToStep widget={widget} />
              <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
              <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
              <FormsWidgetMenuItems.Delete />
            </FormsWidgetMenu>
          </>
        )}
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};
