import { useDisclosure, useOutsideClick } from '@chakra-ui/react';
import { TinyMCEEditor } from 'app/features/rich-text';
import { useRef } from 'react';

export type UseToolbarDisclosureParams = {
  editor?: TinyMCEEditor;
  popoverContentRef: React.MutableRefObject<HTMLDivElement | null>;
};

/*
 * Custom hook to manage the disclosure of the text content widget toolbar component.
 * It uses Chakra UI's `useOutsideClick` to close the toolbar when clicking outside of specified areas:
 * - If the click was inside the editor content area.
 * - If the click was on a block inside the editor.
 * - If the click was inside the toolbar popover.
 */
export const useToolbarDisclosure = ({ editor, popoverContentRef }: UseToolbarDisclosureParams) => {
  const toolbarDisclosure = useDisclosure();
  const ghostElementRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick({
    ref: ghostElementRef,
    handler: function toggleToolbarDisclosureBasedOnTargetElement(e) {
      if (e.target) {
        const clickedTextArea = Boolean(editor?.contentAreaContainer?.contains(e.target as HTMLElement));
        const clickedBlockInsideTextArea = Boolean((e.target as HTMLElement)?.closest('.mce-content-body'));
        // Keep the toolbar open when the user is interacting with it.
        const clickedToolbar = Boolean(popoverContentRef.current?.contains(e.target as HTMLElement));

        if (clickedToolbar || clickedTextArea || clickedBlockInsideTextArea) {
          toolbarDisclosure.onOpen();
          return;
        }
      }

      toolbarDisclosure.onClose();
      return;
    },
  });

  return toolbarDisclosure;
};
