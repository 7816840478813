import * as React from 'react';
import { Grid, GridItem, useBreakpointValue, VStack } from 'components/design/next';
import { useSelector } from '@xstate/react';
import { FormEditorPageActorSelectors, FormEditorPageMachine } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { TOP_BAR_HEIGHT_CSS_VAR } from 'app/pages/forms/_id/shared';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-context';
import { WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT } from 'app/components/focus-bar/workflow/focus-bar-hstack';
import { useStateParam } from 'app/hooks/use-state-param';
import { Template, TemplateRevision } from '@process-street/subgrade/process';
import { Option } from 'space-monad';
import { StateFrom } from 'xstate';
import { useRedirectToFirstTaskOnLoad, useRedirectToTaskOnLoad } from '../edit-v2/hooks';
import { Editor } from '../edit-v2/components';
import { BackgroundImage } from '../edit-v2/components/background-image/background-image';

export const WorkflowViewPage = ({
  template,
  templateRevision,
}: {
  template: Template;
  templateRevision: TemplateRevision;
}) => {
  const actor = useFormEditorPageActorRef();
  const uiActorRef = useSelector(actor, FormEditorPageActorSelectors.getUIActorRef);

  const taskTemplateGroupId = useStateParam({ key: 'groupId' });

  const currentTaskTemplateSelector = React.useCallback(
    (state: StateFrom<FormEditorPageMachine>) => {
      return Option(taskTemplateGroupId)
        .map(groupId => FormEditorPageActorSelectors.getTaskTemplateByGroupId(groupId)(state))
        .get();
    },
    [taskTemplateGroupId],
  );

  const currentTaskTemplate = useSelector(actor, currentTaskTemplateSelector);
  const isLoading = useSelector(actor, FormEditorPageActorSelectors.isLoading);

  useRedirectToTaskOnLoad({ isLoading, templateId: template.id, taskTemplateGroupId });
  useRedirectToFirstTaskOnLoad({ isLoading, templateId: template.id, taskTemplateGroupId });

  const isMobile = useBreakpointValue({ base: true, lg: false }) ?? false;
  React.useEffect(
    function notifyScreenSizeToUiMachine() {
      uiActorRef.send({ type: 'SET_IS_MOBILE', isMobile });
    },
    [isMobile, uiActorRef],
  );

  React.useEffect(
    function updatePageTitle() {
      if (template.name) {
        document.title = `${template.name} | Process Street`;
      }
    },
    [template.name],
  );

  return (
    <>
      <VStack w="full" alignItems="stretch" justifyContent="stretch" spacing={0} bgColor="brand.50">
        <BackgroundImage templateId={template.id} />

        <Grid
          gridTemplateColumns={{ base: '0 1fr 0', lg: '476px 1fr 0' }}
          h={`calc(100vh - ${TOP_BAR_HEIGHT_CSS_VAR})`}
          position="relative"
          pt={WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT}
          background="transparent"
        >
          <GridItem h="full">{<Editor.TaskListSection template={template} />}</GridItem>

          <GridItem
            pt={{ base: 8, md: 32, lg: 10 }}
            px={{ base: 2, md: 0 }}
            overflowY="auto"
            h="full"
            position="relative"
            display="flex"
            flexDirection="column"
            w="full"
          >
            {currentTaskTemplate && (
              <Editor.WidgetListSection
                currentTaskTemplate={currentTaskTemplate}
                template={template}
                templateRevision={templateRevision}
              />
            )}
          </GridItem>
        </Grid>

        <Editor.Modal />
      </VStack>
    </>
  );
};
