import angular from 'angular';
import templateUrl from './data-set-link-details.component.html';
import './data-set-link-details.scss';
import { DataSetUtils } from 'app/pages/reports/data-sets/components/data-set-page/data-set-utils';

angular.module('frontStreetApp.directives').component('psDataSetLinkDetails', {
  bindings: { widget: '<' },
  templateUrl,
  controller(FeatureFlagService) {
    const ctrl = this;

    ctrl.isDataSetsV2Enabled = FeatureFlagService.getFeatureFlags().dataSetsV2;

    ctrl.previewValues = [];

    ctrl.$onInit = function () {
      ctrl.previewValues = ctrl.getPreviewValues();
    };

    ctrl.getPreviewValues = () => {
      const linkedColumnData = ctrl.widget.config.linkedColumnData || [];
      const processedData = [];
      for (const item of linkedColumnData) {
        if (processedData.length >= 3) break;
        const parsedValue = DataSetUtils.parseDataSetValue(item);
        if (parsedValue !== null) {
          processedData.push(parsedValue);
        }
      }

      return processedData;
    };
  },
});
