import angular from 'angular';

import { AutomationsStatusIndicatorModule } from 'pages/checklists/_id/components/automations-status-indicator/index.module';
import { EditPageIdModule } from 'pages/pages/_id/edit/page/index.module';
import { ManageUsersModule } from 'pages/organizations/manage/users/index.module';
import { BillingTabModule } from 'pages/organizations/manage/billing/index.module';
import { LibraryPageModule } from './library/library.module';
import { MicrosoftTeamsConnectModule } from './microsoft-teams/connect/connect.module';
import { MicrosoftTeamsInboxModule } from './microsoft-teams/inbox/inbox.module';
import { SignUpModule } from './sign-up/index.module';
import { DataSetPageModule } from 'pages/reports/data-sets/components/data-set-page/data-set-page-module';
import { GroupsPageModule } from 'pages/organizations/manage/groups/components/groups-page/groups-page.module';
import { TemplatePageModule } from './templates/_id/template-page.module';
import { FormsPageModule } from './forms/_id/forms-page.module';
import { AiGeneratePageModule } from './templates/ai-generate/index.module';
import { WidgetMenuModule } from './templates/_id/components/widget-menu/widget-menu.module';
import { OrganizationActivityPageModule } from './organizations/manage/activity/activity.module';
import { WorkflowRunPageModule } from './runs/_id/workflow-run-page.module';
import { TasksModule } from './tasks/tasks.module';
import { CommentsPageModule } from 'pages/comments/comments-page.module';
import { WorkflowsPageModule } from 'pages/workflows/workflows-page.module';
import { WorkflowsEditorV2PageModule } from './workflows/_id/edit-v2/editor.module';
import { StartHereModule } from 'pages/sign-up/components/start-here/start-here.module';
import { TemplateViewerV2Module } from './workflows/_id/view-v2/view.module';

export const RootPagesModule = angular.module('app.pages', [
  AutomationsStatusIndicatorModule,
  BillingTabModule,
  DataSetPageModule,
  EditPageIdModule,
  GroupsPageModule,
  ManageUsersModule,
  LibraryPageModule,
  MicrosoftTeamsConnectModule,
  MicrosoftTeamsInboxModule,
  SignUpModule,
  StartHereModule,
  TemplatePageModule,
  FormsPageModule,
  WorkflowsPageModule,
  AiGeneratePageModule,
  OrganizationActivityPageModule,
  WidgetMenuModule,
  WorkflowRunPageModule,
  TasksModule,
  CommentsPageModule,
  WorkflowsEditorV2PageModule,
  TemplateViewerV2Module,
]).name;
